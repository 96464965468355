import validation from './validation';
import moment from 'moment';

function FormValidationException(input) {
    this.message = 'The validation "' + input + '" is invalid';
    this.name = 'UserException';
}

export default {
    validateOld: function (string, type) {
        // returns false if there are non alphanumeric characters
        // " " is considered invalid
        try {
            if (type.toLowerCase() === "alphanumeric") {
                var regex = new RegExp(/[^\w\d]+/);
                return !regex.test(string)
            }
            throw new FormValidationException(type)
        } catch (ex) {
            console.log(ex.message);
            // default return true if type is unknown
            return true;
        }
    },
    // abstract validation to another file for reusability
    validate: validation.validate,
    tableSortDate: (a, b, desc, momentFormat) => {
        const momentA = moment(a, momentFormat || "DD/MM/YYYY").unix();
        const momentB = moment(b, momentFormat || "DD/MM/YYYY").unix();
        if (momentA > momentB) {
            return -1
        }
        if (momentA < momentB) {
            return 1
        }
        return 0
    },
    normalizeReceiptFields: (receiptFields) => {
        let normalizedReceiptFields = Object.assign({}, receiptFields);
        const amount = receiptFields.type === "Receipt" ? (receiptFields.amount / 100).toFixed(2) : receiptFields.amount / 100;
        normalizedReceiptFields.amount = amount;
        normalizedReceiptFields.expensedate = moment(receiptFields.expensedate).format("DD/MM/YYYY");
        normalizedReceiptFields.reimbursable = receiptFields.reimbursable === "Y" ? true : false;
        normalizedReceiptFields.roundTrip = receiptFields.roundTrip === "Y" ? true : false;
        return normalizedReceiptFields;
    },
    fakeExpenseData: [{
        "receiptno": "recipt123",
        "categoryobj": {
            "code": "code1",
            "approvalvotescount": "2",
            "approvers": ["ivan.pashigin@gmail.com", "ivan.pashigin@cyder.com.sg"],
            "code_lower": "code1",
            "createddt": 1518075542280,
            "rate": "0.20",
            "appId": "djarvis_trial",
            "name": "bike20",
            "name_lower": "bike20",
            "currency": "SGD",
            "id": "8a07b908-d975-4f6b-9d6f-1245f5a845be",
            "rateunit": "km",
            "status": "Active"
        },
        "currency": "SGD",
        "paymentmode": "code1",
        "paymentmodeobj": {
            "createddt": 1518075520491,
            "code": "code1",
            "appId": "djarvis_trial",
            "name": "Cash Payment",
            "name_lower": "cash payment",
            "id": "491f7b4c-8015-460d-a5a7-a5f021844e25",
            "code_lower": "code1",
            "status": "Active"
        },
        "projectobj": {
            "createddt": 1518506886169,
            "code": "131313",
            "appId": "djarvis_trial",
            "approvalvotescount": "2",
            "name": "asfsf13123",
            "groups": [],
            "name_lower": "asfsf13123",
            "approvers": ["ivan.pashigin@cyder.com.sg", "Harry@yahoo.com"],
            "id": "ec50d21c-1e36-4113-904f-f317ec3aa13f",
            "users": ["abc1@gmail.com", "Harry@yahoo.com"],
            "code_lower": "131313",
            "status": "Active"
        },
        "status": "Saved",
        "currentvotescount": 0,
        "project": "131313",
        "receiptId": "QVBA3MXOrG",
        "createddate": 1519206753679,
        "votescountrequired": "2",
        "expensedate": "01/02/2018",
        "userId": "fkarnagi@gmail.com",
        "category": "code1",
        "amount": 10,
        "appId": "djarvis_trial",
        "id": "3bd280f9-a879-45c1-b8f0-bf09ddd48c22",
        "remarks": "remarks123",
        "approvers": ["ivan.pashigin@cyder.com.sg", "Harry@yahoo.com"],
        "type": "Mileage",
        "title": "title1"
    }, {
        "categoryobj": {
            "code": "code1",
            "approvalvotescount": "2",
            "approvers": ["ivan.pashigin@gmail.com", "ivan.pashigin@cyder.com.sg"],
            "code_lower": "code1",
            "createddt": 1518075542280,
            "rate": "0.20",
            "appId": "djarvis_trial",
            "name": "bike20",
            "name_lower": "bike20",
            "currency": "SGD",
            "id": "8a07b908-d975-4f6b-9d6f-1245f5a845be",
            "rateunit": "km",
            "status": "Active"
        },
        "currency": "SGD",
        "paymentmodeobj": {
            "createddt": 1518075520491,
            "code": "code1",
            "appId": "djarvis_trial",
            "name": "Cash Payment",
            "name_lower": "cash payment",
            "id": "491f7b4c-8015-460d-a5a7-a5f021844e25",
            "code_lower": "code1",
            "status": "Active"
        },
        "projectobj": {
            "createddt": 1518506886169,
            "code": "131313",
            "appId": "djarvis_trial",
            "approvalvotescount": "2",
            "name": "asfsf13123",
            "groups": [],
            "name_lower": "asfsf13123",
            "approvers": ["ivan.pashigin@cyder.com.sg", "Harry@yahoo.com"],
            "id": "ec50d21c-1e36-4113-904f-f317ec3aa13f",
            "users": ["abc1@gmail.com", "Harry@yahoo.com"],
            "code_lower": "131313",
            "status": "Active"
        },
        "status": "Saved",
        "project": "131313",
        "updateddate": 1519207923279,
        "from": "loc1",
        "userId": "fkarnagi@gmail.com",
        "id": "d66426e3-6a5a-49aa-9637-bd255c3b77b5",
        "receiptno": "recipt123",
        "fromlat": 1,
        "tolng": 4,
        "paymentmode": "code1",
        "tolat": 3,
        "currentvotescount": 0,
        "receiptId": "ac8dMFvMjT",
        "fromlng": 2,
        "votescountrequired": "2",
        "expensedate": "01/02/2018",
        "category": "code1",
        "amount": 20,
        "appId": "djarvis_trial",
        "to": "loc2",
        "remarks": "remarks123123456",
        "approvers": ["ivan.pashigin@cyder.com.sg", "Harry@yahoo.com"],
        "type": "Mileage",
        "title": "title1"
    }, {
        "receiptno": "recipt123",
        "categoryobj": {
            "createddt": 1518668099632,
            "code": "001",
            "appId": "djarvis_trial",
            "approvalvotescount": "2",
            "name": "Entertainment",
            "name_lower": "entertainment",
            "approvers": ["ivan.pashigin@gmail.com", "ivan.pashigin@cyder.com.sg"],
            "id": "f1c3ff5d-57c2-4c77-bf5c-1283f2670e1f",
            "code_lower": "001",
            "status": "Active"
        },
        "currency": "SGD",
        "paymentmode": "code1",
        "paymentmodeobj": {
            "createddt": 1518075520491,
            "code": "code1",
            "appId": "djarvis_trial",
            "name": "Cash Payment",
            "name_lower": "cash payment",
            "id": "491f7b4c-8015-460d-a5a7-a5f021844e25",
            "code_lower": "code1",
            "status": "Active"
        },
        "projectobj": {
            "createddt": 1518506886169,
            "code": "131313",
            "appId": "djarvis_trial",
            "approvalvotescount": "2",
            "name": "asfsf13123",
            "groups": [],
            "name_lower": "asfsf13123",
            "approvers": ["ivan.pashigin@cyder.com.sg", "Harry@yahoo.com"],
            "id": "ec50d21c-1e36-4113-904f-f317ec3aa13f",
            "users": ["abc1@gmail.com", "Harry@yahoo.com"],
            "code_lower": "131313",
            "status": "Active"
        },
        "status": "Saved",
        "currentvotescount": 0,
        "project": "131313",
        "expensedate": "01/02/2018",
        "updateddate": 1519208171840,
        "receiptId": "pb7SzU3lpM",
        "votescountrequired": "2",
        "userId": "fkarnagi@gmail.com",
        "category": "001",
        "amount": 1000,
        "merchantname": "merchant123",
        "appId": "djarvis_trial",
        "id": "92d28811-96ee-44c6-a9f1-e6ef39da718b",
        "remarks": "remarks123",
        "approvers": ["ivan.pashigin@cyder.com.sg", "Harry@yahoo.com"],
        "type": "Receipt",
        "title": "title1"
    }, {
        "receiptno": "recipt123",
        "categoryobj": {
            "createddt": 1518668099632,
            "code": "001",
            "appId": "djarvis_trial",
            "approvalvotescount": "2",
            "name": "Entertainment",
            "name_lower": "entertainment",
            "approvers": ["ivan.pashigin@gmail.com", "ivan.pashigin@cyder.com.sg"],
            "id": "f1c3ff5d-57c2-4c77-bf5c-1283f2670e1f",
            "code_lower": "001",
            "status": "Active"
        },
        "currency": "SGD",
        "paymentmode": "code1",
        "paymentmodeobj": {
            "createddt": 1518075520491,
            "code": "code1",
            "appId": "djarvis_trial",
            "name": "Cash Payment",
            "name_lower": "cash payment",
            "id": "491f7b4c-8015-460d-a5a7-a5f021844e25",
            "code_lower": "code1",
            "status": "Active"
        },
        "projectobj": {
            "createddt": 1518506886169,
            "code": "131313",
            "appId": "djarvis_trial",
            "approvalvotescount": "2",
            "name": "asfsf13123",
            "groups": [],
            "name_lower": "asfsf13123",
            "approvers": ["ivan.pashigin@cyder.com.sg", "Harry@yahoo.com"],
            "id": "ec50d21c-1e36-4113-904f-f317ec3aa13f",
            "users": ["abc1@gmail.com", "Harry@yahoo.com"],
            "code_lower": "131313",
            "status": "Active"
        },
        "status": "Saved",
        "currentvotescount": 0,
        "project": "131313",
        "expensedate": "01/02/2018",
        "receiptId": "v0veGUrtu3",
        "createddate": 1519208356177,
        "votescountrequired": "2",
        "userId": "fkarnagi@gmail.com",
        "category": "001",
        "amount": 10,
        "merchantname": "merchant123",
        "appId": "djarvis_trial",
        "id": "13aee1ca-a741-483a-bc8b-f10d56d884d1",
        "remarks": "remarks123",
        "approvers": ["ivan.pashigin@cyder.com.sg", "Harry@yahoo.com"],
        "type": "Receipt",
        "title": "title1"
    }],
    fakeReportData: [
        {
            "id": "2584ec32-05b8-4536-a930-fe01645684e9",
            "appId": "djarvis_cydersg",
            "title": "Expense Report - 1526983933578",
            "remarks": "testremarks",
            "receipts": "\"9b093aa8-0ccf-4a02-af83-ff42063f3077\",\"c5d6cf7c-d437-4b0f-af17-4244882f0759\"",
            "reportId": "1526983933578",
            "userId": "test",
            "totalamount": 0,
            "status": "Submitted",
            "createddt": "22-05-2018",
            "createdby": "test",
            "updateddt": null,
            "updatedby": null
        },
    ],
    filterIgnoreCase: (filter, row) => {
        return (row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) !== -1);
    },
    filterCurrencyCent: (filter, row) => {
        const moddedAmount = "S$" + (row.amount / 100).toFixed(2);
        return (moddedAmount.indexOf(filter.value) !== -1);
    },
    filterDateText: (filter, row, format) => {
        return (moment(row[filter.id]).format(format).indexOf(filter.value.toLowerCase()) !== -1);
    },
    formatCurrencyCent: (centAmount) => {
        return "S$" + (centAmount / 100).toFixed(2);
    },
    formatDistanceMeters: (meters) => {
        return meters / 1000 + "km";
    },
};
