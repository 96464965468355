import {
    PAGE_SET_JUMBOTRON_TEXT,
} from '../actions/constants/actionTypes' 

export function setJumbotronTitle(jumbotronText) {
    return {
        type: PAGE_SET_JUMBOTRON_TEXT,
        jumbotronText,
    }
}

