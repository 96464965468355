import StoredLayout from '../../layouts/StoredLayout'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {
    Input,
    Button,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
} from "reactstrap";
import LoadingSpinner from '../../cyderComponents/loadingSpinner/LoadingSpinner';
import CyderDatePicker from '../../cyderComponents/forms/CyderDatePicker';
import {
    changeFieldValueAction,
    profileSaveAction,
    cyderProfileModalToggle,
    cyderDevRecheckProfile,
} from "../../actions/profile/cyderProfileAction";
import DialogModal from '../../cyderComponents/modals/DialogModal';
//import { translate, Trans } from 'react-i18next';
// import Widget from '../../elements/DashboardWidget'
// import HomeTableWidget from '../../table-widgets/HomeTableWidget'
//import history from '../../history'
// import NotificationsSystem from 'reapop'

class ProfilePage extends Component {
    componentDidMount() {
        this.props.refreshProfile()
    }

    handleDOBChangeWithEvent(momentOrStringVal) {
        const synthEvent = {
            target: {
                id: 'dateofbirth',
                value: momentOrStringVal.format ? momentOrStringVal.format('DD/MM/YYYY') : momentOrStringVal,
            }
        }
        this.props.handleChange(synthEvent);
    }

    render() {
        return ([
            <Container key={0}>
                <Row>
                    <Col xs={12} md={12}>
                        {this.props.loading ? <LoadingSpinner key={0}/> :
                            <Form>
                                <FormGroup row>
                                    <Label sm={3}>Username</Label>
                                    <Col sm={9}>
                                        <Input readOnly disabled
                                            value={this.props.profile.username}
                                            type="text"
                                            id="username"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Secondary Email</Label>
                                    <Col sm={9}>
                                        <Input
                                            value={this.props.profile.email2}
                                            type="email"
                                            id="email2"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>First Name</Label>
                                    <Col sm={9}>
                                        <Input
                                            value={this.props.profile.firstname}
                                            id="firstname"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Last Name</Label>
                                    <Col sm={9}>
                                        <Input
                                            value={this.props.profile.lastname}
                                            id="lastname"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Mobile Number</Label>
                                    <Col sm={9}>
                                        <Input
                                            value={this.props.profile.mobilenumber}
                                            type="mobilenumber"
                                            id="mobilenumber"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Office Number</Label>
                                    <Col sm={9}>
                                        <Input
                                            value={this.props.profile.officenumber}
                                            id="officenumber"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Home Number</Label>
                                    <Col sm={9}>
                                        <Input
                                            value={this.props.profile.homenumber}
                                            id="homenumber"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Company Name</Label>
                                    <Col sm={9}>
                                        <Input
                                            value={this.props.profile.companyname}
                                            id="companyname"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Company Address</Label>
                                    <Col sm={9}>
                                        <Input
                                            value={this.props.profile.companyaddress}
                                            id="companyaddress"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Home Address</Label>
                                    <Col sm={9}>
                                        <Input
                                            value={this.props.profile.homeaddress}
                                            id="homeaddress"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Date of Birth</Label>
                                    <Col sm={9}>
                                        <CyderDatePicker
                                            value={this.props.profile.dateofbirth}
                                            placeholder="DD/MM/YYYY"
                                            onChange={(event) => this.handleDOBChangeWithEvent(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Identification Type</Label>
                                    <Col sm={9}>
                                        <Input
                                            value={this.props.profile.idtype}
                                            type="select"
                                            id="idtype"
                                            onChange={(event) => this.props.handleChange(event)}
                                        >
                                            <option value="NRIC">NRIC</option>
                                            <option value="FIN">FIN</option>
                                            <option value="Passport No.">Passport No.</option>
                                        </Input>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Identification No.</Label>
                                    <Col sm={9}>
                                        <Input
                                            value={this.props.profile.idno}
                                            id="idno"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Gender</Label>
                                    <Col sm={9}>
                                        <Input readOnly
                                            value={this.props.profile.gender}
                                            type="select"
                                            id="gender"
                                            onChange={(event) => this.props.handleChange(event)}
                                        >
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </Input>
                                    </Col>
                                </FormGroup>
                            </Form>
                        }
                        <Row className="mb-2">
                            <Col>
                                <Button
                                    className="mr-2"
                                    color="success"
                                    onClick={() => this.props.toggleModal()}
                                    tabIndex="-1"
                                >
                                    Update
                                </Button>
                                <Link
                                    to="/home"
                                    className="btn btn-danger"
                                    tabIndex="-1"
                                >
                                    Cancel
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>,
            <DialogModal
                key="modal"
                modal={this.props.modalOpen}
                toggle={() => this.props.toggleModal(this.props.saved)}
                customHeader="Confirmation"
                customModalBody={this.props.saving ?
                    <LoadingSpinner/> : this.props.modalMessage}
                positiveButtonAction={!this.props.saved ? () => this.props.handleSave() : () => this.props.toggleModal(this.props.saved)}
                positiveButtonText={this.props.saved ? "Ok" : "Save"}
                onlyOneButton={this.props.saved}
                buttonDisabler={this.props.savingSysparam}
            />
        ]
        )
    }
}


const mapStateToProps = (state) => {
    return {
        profile: state.cyderProfileReducer.profile,
        modalOpen: state.cyderProfileReducer.modalOpen,
        modalMessage: state.cyderProfileReducer.modalMessage,
        saving: state.cyderProfileReducer.saving,
        saved: state.cyderProfileReducer.saved,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        handleChange: (event) => {
            const key = event.target.id;
            const value = event.target.value;
            // console.log(approve.value(value, rules));
            dispatch(changeFieldValueAction(key, value));
        },
        handleSave: () => {
            dispatch(profileSaveAction({}));
        },
        toggleModal: (save) => {
            dispatch(cyderProfileModalToggle(save));
        },
        refreshProfile: () => {
            dispatch(cyderDevRecheckProfile());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(StoredLayout(ProfilePage))
