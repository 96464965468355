/**
 * Action Types
 * 
 * Author: Fernando
 */

// Security
export const CYDER_LOGIN_REQUEST = 'CYDER_LOGIN_REQUEST';
export const CYDER_LOGIN_SUCCESS = 'CYDER_LOGIN_SUCCESS';
export const CYDER_LOGIN_SUCCESS_SET_PROFILE = 'CYDER_LOGIN_SUCCESS_SET_PROFILE';
export const CYDER_LOGIN_FAILURE = 'CYDER_LOGIN_FAILURE';
export const CYDER_LOGIN_PAGE_CLEAR_ERROR_MESSAGE = 'CYDER_LOGIN_PAGE_CLEAR_ERROR_MESSAGE';
export const CYDER_LOGIN_PAGE_SET_ERROR_MESSAGE = 'CYDER_LOGIN_PAGE_SET_ERROR_MESSAGE';
export const CYDER_LOGIN_PAGE_RESET_STATE = 'CYDER_LOGIN_PAGE_RESET_STATE';
export const CYDER_LOGOUT = 'CYDER_LOGOUT';
export const CYDER_LOGOUT_SUCCESS_REMOVE_PROFILE = 'CYDER_LOGOUT_SUCCESS_REMOVE_PROFILE';
export const CYDER_LOGOUT_SUCCESS_REMOVE_LOGGED_IN_FLAG = 'CYDER_LOGOUT_SUCCESS_REMOVE_LOGGED_IN_FLAG';

// Change password
export const CYDER_CHANGE_PASSWORD_REQUEST = 'CYDER_CHANGE_PASSWORD_REQUEST';
export const CYDER_CHANGE_PASSWORD_SUCCESS = 'CYDER_CHANGE_PASSWORD_SUCCESS';
export const CYDER_CHANGE_PASSWORD_FAILURE = 'CYDER_CHANGE_PASSWORD_FAILURE';
export const CYDER_CHANGE_PASSWORD_PAGE_CLEAR_MESSAGE = 'CYDER_CHANGE_PASSWORD_PAGE_CLEAR_MESSAGE';
export const CYDER_CHANGE_PASSWORD_PAGE_SET_MESSAGE = 'CYDER_CHANGE_PASSWORD_PAGE_SET_MESSAGE';
export const CYDER_CHANGE_PASSWORD_GET_INFO_REQUEST = 'CYDER_CHANGE_PASSWORD_GET_INFO_REQUEST';
export const CYDER_CHANGE_PASSWORD_GET_INFO_SUCCESS = 'CYDER_CHANGE_PASSWORD_GET_INFO_SUCCESS';
export const CYDER_CHANGE_PASSWORD_GET_INFO_FAILURE = 'CYDER_CHANGE_PASSWORD_GET_INFO_FAILURE';

export const CYDER_FORGOT_PASSWORD_REQUEST = 'CYDER_FORGOT_PASSWORD_REQUEST';
export const CYDER_FORGOT_PASSWORD_SUCCESS = 'CYDER_FORGOT_PASSWORD_SUCCESS';
export const CYDER_FORGOT_PASSWORD_FAILURE = 'CYDER_FORGOT_PASSWORD_FAILURE';
export const CYDER_FORGOT_PASSWORD_PAGE_SET_MESSAGE = 'CYDER_FORGOT_PASSWORD_PAGE_SET_MESSAGE';

// Sysparams
export const CYDER_SYSPARAMS_GET = 'CYDER_SYSPARAMS_GET';
export const CYDER_SYSPARAMS_GET_SUCCESS = 'CYDER_SYSPARAMS_GET_SUCCESS';

export const CYDER_SYSPARAMS_PAGE_MODAL_TOGGLE = 'CYDER_SYSPARAMS_PAGE_MODAL_TOGGLE';
export const CYDER_SYSPARAMS_PAGE_MODAL_OPEN = 'CYDER_SYSPARAMS_PAGE_MODAL_OPEN';
export const CYDER_SYSPARAMS_PAGE_MODAL_CLOSE = 'CYDER_SYSPARAMS_PAGE_MODAL_CLOSE';
export const CYDER_SYSPARAMS_PAGE_MODAL_CHANGE_VALUE = 'CYDER_SYSPARAMS_PAGE_MODAL_CHANGE_VALUE';
export const CYDER_SYSPARAMS_PAGE_LOADING = 'CYDER_SYSPARAMS_PAGE_LOADING';
export const CYDER_SYSPARAMS_PAGE_MODAL_SET_SYSPARAM = 'CYDER_SYSPARAMS_PAGE_MODAL_SET_SYSPARAM';

export const CYDER_SYSPARAMS_SAVE_VALUE = 'CYDER_SYSPARAMS_SAVE_VALUE';
export const CYDER_SYSPARAMS_SAVE_VALUE_SUCCESS = 'CYDER_SYSPARAMS_SAVE_VALUE_SUCCESS';
export const CYDER_SYSPARAMS_SAVE_VALUE_SAVING = 'CYDER_SYSPARAMS_SAVE_VALUE_SAVING';

// Accounts List
export const CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_REQUEST = 'CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_REQUEST';
export const CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_SUCCESS = 'CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_SUCCESS';
export const CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_FAILURE = 'CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_FAILURE';

export const CYDER_ACCOUNTS_SEARCH_REQUEST = 'CYDER_ACCOUNTS_SEARCH_REQUEST';
export const CYDER_ACCOUNTS_SEARCH_SUCCESS = 'CYDER_ACCOUNTS_SEARCH_SUCCESS';
export const CYDER_ACCOUNTS_SEARCH_FAILURE = 'CYDER_ACCOUNTS_SEARCH_FAILURE';
export const CYDER_ACCOUNTS_SEARCH_SET_FORCE_REFRESH_FLAG = 'CYDER_ACCOUNTS_SEARCH_SET_FORCE_REFRESH_FLAG';

// Accounts Detail Page
export const CYDER_ACCOUNTS_DETAIL_REQUEST = 'CYDER_ACCOUNTS_DETAIL_REQUEST';
export const CYDER_ACCOUNTS_DETAIL_SUCCESS = 'CYDER_ACCOUNTS_DETAIL_SUCCESS';
export const CYDER_ACCOUNTS_DETAIL_FAILURE = 'CYDER_ACCOUNTS_DETAIL_FAILURE';
export const CYDER_ACCOUNTS_DETAIL_CHANGE_FIELD = 'CYDER_ACCOUNTS_DETAIL_CHANGE_FIELD';
export const CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_REQUEST = 'CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_REQUEST';
export const CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_SUCCESS = 'CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_SUCCESS';
export const CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_FAILURE = 'CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_FAILURE';
export const CYDER_ACCOUNTS_DETAIL_MODAL_TOGGLE = 'CYDER_ACCOUNTS_DETAIL_MODAL_TOGGLE';
export const CYDER_ACCOUNTS_DETAIL_APPROVE_REQUEST = 'CYDER_ACCOUNTS_DETAIL_APPROVE_REQUEST';
export const CYDER_ACCOUNTS_DETAIL_APPROVE_SUCCESS = 'CYDER_ACCOUNTS_DETAIL_APPROVE_SUCCESS';
export const CYDER_ACCOUNTS_DETAIL_APPROVE_FAILURE = 'CYDER_ACCOUNTS_DETAIL_APPROVE_FAILURE';
export const CYDER_ACCOUNTS_DETAIL_REJECT_REQUEST = 'CYDER_ACCOUNTS_DETAIL_REJECT_REQUEST';
export const CYDER_ACCOUNTS_DETAIL_REJECT_SUCCESS = 'CYDER_ACCOUNTS_DETAIL_REJECT_SUCCESS';
export const CYDER_ACCOUNTS_DETAIL_REJECT_FAILURE = 'CYDER_ACCOUNTS_DETAIL_REJECT_FAILURE';
export const CYDER_ACCOUNTS_DETAIL_DELETE_REQUEST = 'CYDER_ACCOUNTS_DETAIL_DELETE_REQUEST';
export const CYDER_ACCOUNTS_DETAIL_DELETE_SUCCESS = 'CYDER_ACCOUNTS_DETAIL_DELETE_SUCCESS';
export const CYDER_ACCOUNTS_DETAIL_DELETE_FAILURE = 'CYDER_ACCOUNTS_DETAIL_DELETE_FAILURE';
export const CYDER_ACCOUNTS_DETAIL_RESET_PASSWORD_REQUEST = 'CYDER_ACCOUNTS_DETAIL_RESET_PASSWORD_REQUEST';
export const CYDER_ACCOUNTS_DETAIL_RESET_PASSWORD_SUCCESS = 'CYDER_ACCOUNTS_DETAIL_RESET_PASSWORD_SUCCESS';
export const CYDER_ACCOUNTS_DETAIL_RESET_PASSWORD_FAILURE = 'CYDER_ACCOUNTS_DETAIL_RESET_PASSWORD_FAILURE';
export const CYDER_ACCOUNTS_DETAIL_ARCHIVE_REQUEST = 'CYDER_ACCOUNTS_DETAIL_ARCHIVE_REQUEST';
export const CYDER_ACCOUNTS_DETAIL_ARCHIVE_SUCCESS = 'CYDER_ACCOUNTS_DETAIL_ARCHIVE_SUCCESS';
export const CYDER_ACCOUNTS_DETAIL_ARCHIVE_FAILURE = 'CYDER_ACCOUNTS_DETAIL_ARCHIVE_FAILURE';
export const CYDER_ACCOUNTS_DETAIL_SUSPEND_REQUEST = 'CYDER_ACCOUNTS_DETAIL_SUSPEND_REQUEST';
export const CYDER_ACCOUNTS_DETAIL_SUSPEND_SUCCESS = 'CYDER_ACCOUNTS_DETAIL_SUSPEND_SUCCESS';
export const CYDER_ACCOUNTS_DETAIL_SUSPEND_FAILURE = 'CYDER_ACCOUNTS_DETAIL_SUSPEND_FAILURE';
export const CYDER_ACCOUNTS_DETAIL_ACTIVATE_REQUEST = 'CYDER_ACCOUNTS_DETAIL_ACTIVATE_REQUEST';
export const CYDER_ACCOUNTS_DETAIL_ACTIVATE_SUCCESS = 'CYDER_ACCOUNTS_DETAIL_ACTIVATE_SUCCESS';
export const CYDER_ACCOUNTS_DETAIL_ACTIVATE_FAILURE = 'CYDER_ACCOUNTS_DETAIL_ACTIVATE_FAILURE';
export const CYDER_ACCOUNTS_DETAIL_CHANGE_CC_REQUEST = 'CYDER_ACCOUNTS_DETAIL_CHANGE_CC_REQUEST';
export const CYDER_ACCOUNTS_DETAIL_CHANGE_CC_SUCCESS = 'CYDER_ACCOUNTS_DETAIL_CHANGE_CC_SUCCESS';
export const CYDER_ACCOUNTS_DETAIL_CHANGE_CC_FAILURE = 'CYDER_ACCOUNTS_DETAIL_CHANGE_CC_FAILURE';
export const CYDER_ACCOUNTS_DETAIL_REMOVE_CC_REQUEST = 'CYDER_ACCOUNTS_DETAIL_REMOVE_CC_REQUEST';
export const CYDER_ACCOUNTS_DETAIL_REMOVE_CC_SUCCESS = 'CYDER_ACCOUNTS_DETAIL_REMOVE_CC_SUCCESS';
export const CYDER_ACCOUNTS_DETAIL_REMOVE_CC_FAILURE = 'CYDER_ACCOUNTS_DETAIL_REMOVE_CC_FAILURE';

// Users List
export const CYDER_USERS_SEARCH_REQUEST = 'CYDER_USERS_SEARCH_REQUEST';
export const CYDER_USERS_SEARCH_SUCCESS = 'CYDER_USERS_SEARCH_SUCCESS';
export const CYDER_USERS_SEARCH_FAILURE = 'CYDER_USERS_SEARCH_FAILURE';
export const CYDER_USERS_SEARCH_SET_FORCE_REFRESH_FLAG = 'CYDER_USERS_SEARCH_SET_FORCE_REFRESH_FLAG';

// Users Detail Page
export const CYDER_USERS_DETAIL_REQUEST = 'CYDER_USERS_DETAIL_REQUEST';
export const CYDER_USERS_DETAIL_SUCCESS = 'CYDER_USERS_DETAIL_SUCCESS';
export const CYDER_USERS_DETAIL_FAILURE = 'CYDER_USERS_DETAIL_FAILURE';
export const CYDER_USERS_DETAIL_CHANGE_FIELD = 'CYDER_USERS_DETAIL_CHANGE_FIELD';
export const CYDER_USERS_DETAIL_SAVE_CHANGES_REQUEST = 'CYDER_USERS_DETAIL_SAVE_CHANGES_REQUEST';
export const CYDER_USERS_DETAIL_SAVE_CHANGES_SUCCESS = 'CYDER_USERS_DETAIL_SAVE_CHANGES_SUCCESS';
export const CYDER_USERS_DETAIL_SAVE_CHANGES_FAILURE = 'CYDER_USERS_DETAIL_SAVE_CHANGES_FAILURE';
export const CYDER_USERS_DETAIL_DELETE_REQUEST = 'CYDER_USERS_DETAIL_DELETE_REQUEST';
export const CYDER_USERS_DETAIL_DELETE_SUCCESS = 'CYDER_USERS_DETAIL_DELETE_SUCCESS';
export const CYDER_USERS_DETAIL_DELETE_FAILURE = 'CYDER_USERS_DETAIL_DELETE_FAILURE';
export const CYDER_USERS_DETAIL_MODAL_TOGGLE = 'CYDER_USERS_DETAIL_MODAL_TOGGLE';
export const CYDER_USERS_DETAIL_FORCE_VALIDATE = 'CYDER_USERS_DETAIL_FORCE_VALIDATE';

// Users Add
export const CYDER_USERS_ADD_CHANGE_FIELD = 'CYDER_USERS_ADD_CHANGE_FIELD';
export const CYDER_USERS_ADD_MESSAGE_MODAL_TOGGLE = 'CYDER_USERS_ADD_MESSAGE_MODAL_TOGGLE';
export const CYDER_USERS_ADD_MESSAGE_MODAL_OPEN = 'CYDER_USERS_ADD_MESSAGE_MODAL_OPEN';
export const CYDER_USERS_ADD_MESSAGE_MODAL_CLOSE = 'CYDER_USERS_ADD_MESSAGE_MODAL_CLOSE';
export const CYDER_USERS_ADD_ADD_USER_FORCE_VALIDATE = 'CYDER_USERS_ADD_ADD_USER_FORCE_VALIDATE';
export const CYDER_USERS_ADD_ADD_USER_REQUEST = 'CYDER_USERS_ADD_ADD_USER_REQUEST';
export const CYDER_USERS_ADD_ADD_USER_SUCCESS = 'CYDER_USERS_ADD_ADD_USER_SUCCESS';
export const CYDER_USERS_ADD_ADD_USER_ERROR = 'CYDER_USERS_ADD_ADD_USER_ERROR';
export const CYDER_USERS_ADD_CLEAR_FIELDS = 'CYDER_USERS_ADD_CLEAR_FIELDS';

// Groups List
export const CYDER_GROUPS_SEARCH_REQUEST = 'CYDER_GROUPS_SEARCH_REQUEST';
export const CYDER_GROUPS_SEARCH_SUCCESS = 'CYDER_GROUPS_SEARCH_SUCCESS';
export const CYDER_GROUPS_SEARCH_FAILURE = 'CYDER_GROUPS_SEARCH_FAILURE';

export const CYDER_GROUPS_SEARCH_PAGE_RESET_STATE = 'CYDER_GROUPS_SEARCH_PAGE_RESET_STATE';

// Groups Detail Page
export const CYDER_GROUPS_DETAIL_REQUEST = 'CYDER_GROUPS_DETAIL_REQUEST';
export const CYDER_GROUPS_DETAIL_SUCCESS = 'CYDER_GROUPS_DETAIL_SUCCESS';
export const CYDER_GROUPS_DETAIL_FAILURE = 'CYDER_GROUPS_DETAIL_FAILURE';
export const CYDER_GROUPS_DETAIL_REMOVE_USER_LIST_ADD = 'CYDER_GROUPS_DETAIL_REMOVE_USER_LIST_ADD';
export const CYDER_GROUPS_DETAIL_REMOVE_USER_LIST_REMOVE = 'CYDER_GROUPS_DETAIL_REMOVE_USER_LIST_REMOVE';
export const CYDER_GROUPS_DETAIL_ADD_USER_LIST_ADD = 'CYDER_GROUPS_DETAIL_ADD_USER_LIST_ADD';
export const CYDER_GROUPS_DETAIL_ADD_USER_LIST_REMOVE = 'CYDER_GROUPS_DETAIL_ADD_USER_LIST_REMOVE';
export const CYDER_GROUPS_DETAIL_CHANGE_FIELD = 'CYDER_GROUPS_DETAIL_CHANGE_FIELD';

export const CYDER_GROUPS_DETAIL_MODAL_TOGGLE = 'CYDER_GROUPS_DETAIL_MODAL_TOGGLE';

export const CYDER_GROUPS_DETAIL_SAVE_CHANGES_REQUEST = 'CYDER_GROUPS_DETAIL_SAVE_CHANGES_REQUEST';
export const CYDER_GROUPS_DETAIL_SAVE_CHANGES_SUCCESS = 'CYDER_GROUPS_DETAIL_SAVE_CHANGES_SUCCESS';
export const CYDER_GROUPS_DETAIL_SAVE_CHANGES_FAILURE = 'CYDER_GROUPS_DETAIL_SAVE_CHANGES_FAILURE';
export const CYDER_GROUPS_DETAIL_DELETE_REQUEST = 'CYDER_GROUPS_DETAIL_DELETE_REQUEST';
export const CYDER_GROUPS_DETAIL_DELETE_SUCCESS = 'CYDER_GROUPS_DETAIL_DELETE_SUCCESS';
export const CYDER_GROUPS_DETAIL_DELETE_FAILURE = 'CYDER_GROUPS_DETAIL_DELETE_FAILURE';

// Groups Add Page
export const CYDER_GROUPS_ADD_CHANGE_FIELD = 'CYDER_GROUPS_ADD_CHANGE_FIELD';
export const CYDER_GROUPS_ADD_CLEAR_FIELDS = 'CYDER_GROUPS_ADD_CLEAR_FIELDS';
export const CYDER_GROUPS_ADD_ADD_USER = 'CYDER_GROUPS_ADD_ADD_USER';
export const CYDER_GROUPS_ADD_REMOVE_USER = 'CYDER_GROUPS_ADD_REMOVE_USER';
export const CYDER_GROUPS_ADD_ADD_GROUP_FORCE_VALIDATE = "CYDER_GROUPS_ADD_ADD_GROUP_FORCE_VALIDATE";
export const CYDER_GROUPS_ADD_ADD_USER_SEARCH_REQUEST = 'CYDER_GROUPS_ADD_ADD_USER_SEARCH_REQUEST';
export const CYDER_GROUPS_ADD_ADD_USER_SEARCH_SUCCESS = 'CYDER_GROUPS_ADD_ADD_USER_SEARCH_SUCCESS';
export const CYDER_GROUPS_ADD_ADD_USER_SEARCH_FAILURE = 'CYDER_GROUPS_ADD_ADD_USER_SEARCH_FAILURE';
export const CYDER_GROUPS_ADD_ADD_GROUP_REQUEST = 'CYDER_GROUPS_ADD_ADD_GROUP_REQUEST';
export const CYDER_GROUPS_ADD_ADD_GROUP_SUCCESS = 'CYDER_GROUPS_ADD_ADD_GROUP_SUCCESS';
export const CYDER_GROUPS_ADD_ADD_GROUP_FAILURE = 'CYDER_GROUPS_ADD_ADD_GROUP_FAILURE';
export const CYDER_GROUPS_ADD_MODAL_TOGGLE = 'CYDER_GROUPS_ADD_MODAL_TOGGLE';

// Expenses
export const CYDER_EXPENSE_SELECT_CATEGORY = 'CYDER_EXPENSE_SELECT_CATEGORY';
export const CYDER_EXPENSE_START = 'CYDER_EXPENSE_START';
export const CYDER_EXPENSE_CANCEL = 'CYDER_EXPENSE_CANCEL';
export const CYDER_EXPENSE_SELECT_PROJECT = 'CYDER_EXPENSE_SELECT_PROJECT';
export const CYDER_EXPENSE_SELECT_EXPENSE_DATE = 'CYDER_EXPENSE_SELECT_EXPENSE_DATE';
export const CYDER_EXPENSE_SELECT_TRAVEL_METHOD = 'CYDER_EXPENSE_SELECT_TRAVEL_METHOD';

// Expenses (new)
export const CYDER_EXPENSE_MYRECEIPTS_REQUEST = 'CYDER_EXPENSE_MYRECEIPTS_REQUEST';
export const CYDER_EXPENSE_MYRECEIPTS_SUCCESS = 'CYDER_EXPENSE_MYRECEIPTS_SUCCESS';
export const CYDER_EXPENSE_MYRECEIPTS_FAILURE = 'CYDER_EXPENSE_MYRECEIPTS_FAILURE';

export const CYDER_EXPENSE_MYRECEIPTS_TOGGLE_CHECKBOX = 'CYDER_EXPENSE_MYRECEIPTS_TOGGLE_CHECKBOX';
export const CYDER_EXPENSE_MYRECEIPTS_TOGGLE_CHECKBOX_ALL = 'CYDER_EXPENSE_MYRECEIPTS_TOGGLE_CHECKBOX_ALL';

// Page
export const SET_NAVIGATION = 'SET_NAVIGATION';
export const PAGE_SET_JUMBOTRON_TEXT = 'PAGE_SET_JUMBOTRON_TEXT';

// Profile
export const CYDER_PROFILE_GET = 'CYDER_PROFILE_GET';
export const CYDER_PROFILE_SAVE = 'CYDER_PROFILE_SAVE';

export const CYDER_PROFILE_MODAL_TOGGLE = 'CYDER_PROFILE_MODAL_TOGGLE';

export const CYDER_PROFILE_SAVE_CHANGES_REQUEST = 'CYDER_PROFILE_SAVE_CHANGES_REQUEST';
export const CYDER_PROFILE_SAVE_CHANGES_SUCCESS = 'CYDER_PROFILE_SAVE_CHANGES_SUCCESS';
export const CYDER_PROFILE_SAVE_CHANGES_FAILURE = 'CYDER_PROFILE_SAVE_CHANGES_FAILURE';

// Cyder Dev
export const CYDER_DEV_CACHE_CURRENT_PROFILE = 'CYDER_DEV_CACHE_CURRENT_PROFILE';
export const CYDER_DEV_REMOVE_CACHED_PROFILE = 'CYDER_DEV_REMOVE_CACHED_PROFILE';
export const CYDER_DEV_CACHE_FAKE_PROFILE = 'CYDER_DEV_CACHE_FAKE_PROFILE';
export const CYDER_DEV_RECHECK_PROFILE = 'CYDER_DEV_RECHECK_PROFILE';

// Expense Travel
export const CYDER_EXPENSE_NEW_TRAVEL_LOCATION_START_SET = 'CYDER_EXPENSE_NEW_TRAVEL_LOCATION_START_SET';
export const CYDER_EXPENSE_NEW_TRAVEL_LOCATION_DESTINATION_SET = 'CYDER_EXPENSE_NEW_TRAVEL_LOCATION_DESTINATION_SET';
export const CYDER_EXPENSE_NEW_TRAVEL_LOCATION_REMOVE_MARKERS = 'CYDER_EXPENSE_NEW_TRAVEL_LOCATION_REMOVE_MARKERS';
export const CYDER_EXPENSE_NEW_TRAVEL_DIRECTIONS_SET_CURRENT_RESULT = 'CYDER_EXPENSE_NEW_TRAVEL_DIRECTIONS_SET_CURRENT_RESULT';

