/**
 * Multi environment confirmation
 *
 * Author: Fernando Karnagi
 * Reference: https://daveceddia.com/multiple-environments-with-react/
 */

let cyderAPIHost;
let djarvisAPIHost;

let cyderAPIKey;
let djarvisAPIKey;

let djarvisExpenseAPIHost;
let djarvisReportAPIHost;
let djarvisCommonAPIHost;

let cyderCommonAPIHost;

let djarvisWebsite;

const hostname = window && window.location && window.location.hostname;

if (hostname === 'app.djarv.is') {
    djarvisWebsite = 'http://www.djarv.is/';
    cyderAPIHost = 'https://api.cyder.com.sg';
    djarvisAPIHost = 'https://api.djarv.is';
    cyderAPIKey = 'OX0CR7p8Zd6ayqyqGI8UJ7qr8XaSDsSf6WKovUp6';
    djarvisAPIKey = 'SvM6LHsK7P4sZvzFgDUZN6qUdqmWjLUy1S2OSc4j';
    djarvisReportAPIHost = 'https://report.api.prod.djarvis.cyder.com.sg';
    djarvisExpenseAPIHost = 'https://expense.api.prod.djarvis.cyder.com.sg';
    djarvisCommonAPIHost = 'https://common.api.prod.djarvis.cyder.com.sg';
    cyderCommonAPIHost = 'https://common.api.prod.cyder.com.sg';
} else if (hostname === 'app-dev.djarv.is') {
    djarvisWebsite = 'http://www-dev.djarv.is/';
    cyderAPIHost = 'https://api-dev.cyder.com.sg';
    djarvisAPIHost = 'https://api-dev.djarv.is';
    cyderAPIKey = 'yKVymmIP8IaBumZkBTc4R2iHLq6Ovmqu2MQwwit0';
    djarvisAPIKey = '4Qy0OvHlvX61bzqPSfu2r8FUBIKmZj4y7qAgdqrS';
    djarvisReportAPIHost = 'https://report.api.djarvis.cyder.com.sg';
    djarvisExpenseAPIHost = 'https://expense.api.djarvis.cyder.com.sg';
    djarvisCommonAPIHost = 'https://common.api.djarvis.cyder.com.sg';
    cyderCommonAPIHost = 'https://common.api.cyder.com.sg';
} else {
    djarvisWebsite = 'http://www-dev.djarv.is/';
    cyderAPIHost = 'https://api-dev.cyder.com.sg';
    djarvisAPIHost = 'https://api-dev.djarv.is';
    cyderAPIKey = 'yKVymmIP8IaBumZkBTc4R2iHLq6Ovmqu2MQwwit0';
    djarvisAPIKey = '4Qy0OvHlvX61bzqPSfu2r8FUBIKmZj4y7qAgdqrS';
    // djarvisReportAPIHost = 'https://report.api.djarvis.cyder.com.sg';
    // djarvisReportAPIHost = 'http://localhost:3000';
    // djarvisExpenseAPIHost = 'https://expense.api.djarvis.cyder.com.sg';
    // djarvisExpenseAPIHost = 'http://localhost:3000';
    djarvisReportAPIHost = 'https://report.api.prod.djarvis.cyder.com.sg';
    djarvisExpenseAPIHost = 'https://expense.api.prod.djarvis.cyder.com.sg';
    // djarvisCommonAPIHost = 'http://localhost:3000';
    djarvisCommonAPIHost = 'https://common.api.djarvis.cyder.com.sg';
    cyderCommonAPIHost = 'https://common.api.cyder.com.sg';
    // cyderCommonAPIHost = 'http://localhost:3000';
}

export const CYDER_API_ROOT = `${cyderAPIHost}`;
export const DJARVIS_API_ROOT = `${djarvisAPIHost}`;
export const CYDER_API_KEY = `${cyderAPIKey}`;
export const DJARVIS_API_KEY = `${djarvisAPIKey}`;
export const DJARVIS_WEBSITE = `${djarvisWebsite}`;
export const DJARVIS_REPORT_API_ROOT = `${djarvisReportAPIHost}`;
export const DJARVIS_EXPENSE_API_ROOT = `${djarvisExpenseAPIHost}`;
export const DJARVIS_COMMON_API_ROOT = `${djarvisCommonAPIHost}`;
export const CYDER_COMMON_API_ROOT = `${cyderCommonAPIHost}`;
