import {
    CYDER_ACCOUNTS_DETAIL_REQUEST,
    CYDER_ACCOUNTS_DETAIL_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_FAILURE,
    CYDER_ACCOUNTS_DETAIL_CHANGE_FIELD,
    CYDER_ACCOUNTS_DETAIL_MODAL_TOGGLE,
    CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_REQUEST,
    CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_FAILURE,
    CYDER_ACCOUNTS_DETAIL_APPROVE_REQUEST,
    CYDER_ACCOUNTS_DETAIL_APPROVE_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_APPROVE_FAILURE,
    CYDER_ACCOUNTS_DETAIL_REJECT_REQUEST,
    CYDER_ACCOUNTS_DETAIL_REJECT_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_REJECT_FAILURE,
    CYDER_ACCOUNTS_DETAIL_DELETE_REQUEST,
    CYDER_ACCOUNTS_DETAIL_DELETE_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_DELETE_FAILURE,
    CYDER_ACCOUNTS_DETAIL_RESET_PASSWORD_REQUEST,
    CYDER_ACCOUNTS_DETAIL_RESET_PASSWORD_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_RESET_PASSWORD_FAILURE,
    CYDER_ACCOUNTS_DETAIL_ARCHIVE_REQUEST,
    CYDER_ACCOUNTS_DETAIL_ARCHIVE_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_ARCHIVE_FAILURE,
    CYDER_ACCOUNTS_DETAIL_SUSPEND_REQUEST,
    CYDER_ACCOUNTS_DETAIL_SUSPEND_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_SUSPEND_FAILURE,
    CYDER_ACCOUNTS_DETAIL_ACTIVATE_REQUEST,
    CYDER_ACCOUNTS_DETAIL_ACTIVATE_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_ACTIVATE_FAILURE,
    CYDER_ACCOUNTS_SEARCH_SET_FORCE_REFRESH_FLAG,
    CYDER_ACCOUNTS_DETAIL_CHANGE_CC_REQUEST,
    CYDER_ACCOUNTS_DETAIL_CHANGE_CC_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_CHANGE_CC_FAILURE,
    CYDER_ACCOUNTS_DETAIL_REMOVE_CC_REQUEST,
    CYDER_ACCOUNTS_DETAIL_REMOVE_CC_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_REMOVE_CC_FAILURE,
} from "../actionTypes";

import { CYDER_API_ROOT, DJARVIS_API_ROOT } from '../../config';

import withQuery from 'with-query';

export function cyderAccountsDetailChangeFieldAction(key, value) {
    return {
        type: CYDER_ACCOUNTS_DETAIL_CHANGE_FIELD,
        key,
        value,
    }
}
export function cyderAccountsDetailGetAccountDetailsAction(accountid) {
    const fakedata = {
        "orgAppId": "djarvis_1515946046193",
        "package": {
            "limit": {
                "maxuser": 100
            },
            "code": "5",
            "system": "djarvis",
            "price": {
                "amount": 5,
                "period": "month",
                "currency": "SGD",
                "user": 1
            },
            "modules": ["leaves"]
        },
        "createddt": 1515946046193,
        "phonenumber": "0007",
        "status": "Pending",
        "companyname": "Cyder Company",
        "address": "a",
        "emailaddress": "ivan@cyder.com.sg",
        "firstname_lower": "alex",
        "lastname_lower": "ivanov",
        "lastname": "Ivanov",
        "system": "djarvis",
        "companyname_lower": "cyder company",
        "firstname": "Alex",
        "id": "81905e59-89d7-4680-a6e5-ccfc765cda63"
    }
    
    return (dispatch, getState) => {
        dispatch({ type: CYDER_ACCOUNTS_DETAIL_REQUEST })

        const url = withQuery(CYDER_API_ROOT + '/account_v1/account', {
            appid: getState().cyderProfileReducer.profile.appId,
            id: accountid,
            action: "findbyid",
        });
        const options = {
            headers: {
                "x-api-key": getState().config.apiKeyCyder,
                "Content-Type": "application/json",
                "Authorization":  getState().cyderProfileReducer.profile.authorizationToken,
            }
        };
        return fetch(url, options).then((response) => {
            if (!response.ok) {
                return response.json().then((error) => {
                    dispatch({ type: CYDER_ACCOUNTS_DETAIL_FAILURE, error })
                    throw error;
                })
            } else {
                return response.json().then((response) => {
                    dispatch({ type: CYDER_ACCOUNTS_DETAIL_SUCCESS, response })
                    return response;
                })
            }
        }).catch((error) => {
            dispatch({ type: CYDER_ACCOUNTS_DETAIL_FAILURE, error })
            return error;
        });
    }
}
export function cyderAccountsDetailApproveAction() {
    console.log('approve');
    return (dispatch, getState) => {
        dispatch({ type: CYDER_ACCOUNTS_DETAIL_APPROVE_REQUEST })

        const url = withQuery(CYDER_API_ROOT + '/account_v1/account', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: "approve",
        });
        const accountDetails = getState().cyderAccountsDetailReducer.account;
        const body = {
            emailaddress: accountDetails.emailaddress,
            orgAppId: accountDetails.orgAppId,
            id: accountDetails.id,
        }
        const options = {
            headers: {
                "x-api-key": getState().config.apiKeyCyder,
                "Content-Type": "application/json",
                "Authorization":  getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify(body),
            method: "POST",
        };
        return fetch(url, options).then((response) => {
            if (!response.ok) {
                response.json().then((error) => {
                    dispatch({ type: CYDER_ACCOUNTS_DETAIL_APPROVE_FAILURE, error })
                    throw error;
                })
            } else {
                response.json().then((response) => {
                    dispatch({ type: CYDER_ACCOUNTS_DETAIL_APPROVE_SUCCESS, response })
                    dispatch({ type: CYDER_ACCOUNTS_SEARCH_SET_FORCE_REFRESH_FLAG, val: true })
                    return response;
                })
            }
        }).catch((error) => {
            dispatch({ type: CYDER_ACCOUNTS_DETAIL_APPROVE_FAILURE, error })
            return error;
        });
    }
}
export function cyderAccountsDetailRejectAction() {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_ACCOUNTS_DETAIL_REJECT_REQUEST })

        const url = withQuery(CYDER_API_ROOT + '/account_v1/account', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: "reject",
        });
        const accountDetails = getState().cyderAccountsDetailReducer.account;
        const body = {
            emailaddress: accountDetails.emailaddress,
            orgAppId: accountDetails.orgAppId,
            id: accountDetails.id,
        }
        const options = {
            headers: {
                "x-api-key": getState().config.apiKeyCyder,
                "Content-Type": "application/json",
                "Authorization":  getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify(body),
            method: "POST",
        };
        return fetch(url, options).then((response) => {
            if (!response.ok) {
                response.json().then((error) => {
                    dispatch({ type: CYDER_ACCOUNTS_DETAIL_REJECT_FAILURE, error })
                    throw error;
                })
            } else {
                response.json().then((response) => {
                    dispatch({ type: CYDER_ACCOUNTS_DETAIL_REJECT_SUCCESS, response })
                    dispatch({ type: CYDER_ACCOUNTS_SEARCH_SET_FORCE_REFRESH_FLAG, val: true })

                    return response;
                })
            }
        }).catch((error) => {
            dispatch({ type: CYDER_ACCOUNTS_DETAIL_REJECT_FAILURE, error })
            return error;
        });
    }
}
export function cyderAccountsDetailDeleteAction() {
    return (dispatch, getState) => {
        const setSavingStateAction = {
            type: CYDER_ACCOUNTS_DETAIL_DELETE_REQUEST
        }
        dispatch(setSavingStateAction)
        const url = withQuery(CYDER_API_ROOT + '/account_v1/account', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: "deletecustomer",
        });
        const options = {
            headers: {
                "x-api-key": getState().config.apiKeyCyder,
                "Content-Type": "application/json",
                "Authorization":  getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify({id: getState().cyderAccountsDetailReducer.account.id}),
            method: "POST",
        };
        return fetch(url, options).then((response) => {
            if (!response.ok) {
                return response.json().then((error) => {
                    dispatch({ type: CYDER_ACCOUNTS_DETAIL_DELETE_FAILURE, error })
                    return error;
                })
            } else {
                return response.json().then((response) => {
                    dispatch({ type: CYDER_ACCOUNTS_DETAIL_DELETE_SUCCESS, response })
                    return response;
                })
            }
        }).catch((error) => {
            dispatch({ type: CYDER_ACCOUNTS_DETAIL_DELETE_FAILURE, error })
            return error;
        });
    }
}
export function cyderAccountsDetailResetPasswordAction() {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_ACCOUNTS_DETAIL_RESET_PASSWORD_REQUEST })
        // dispatch({ type: CYDER_ACCOUNTS_SEARCH_SET_FORCE_REFRESH_FLAG, val: true })
        console.log('reset password');
        return;
    }
}
export function cyderAccountsDetailArchiveAction() {
    return (dispatch, getState) => {
        const setSavingStateAction = {
            type: CYDER_ACCOUNTS_DETAIL_ARCHIVE_REQUEST
        }
        dispatch(setSavingStateAction)
        const url = withQuery(CYDER_API_ROOT + '/account_v1/account', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: "archiveaccount",
        });
        const options = {
            headers: {
                "x-api-key": getState().config.apiKeyCyder,
                "Content-Type": "application/json",
                "Authorization":  getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify({id: getState().cyderAccountsDetailReducer.account.id}),
            method: "POST",
        };
        return fetch(url, options).then((response) => {
            if (!response.ok) {
                return response.json().then((error) => {
                    dispatch({ type: CYDER_ACCOUNTS_DETAIL_ARCHIVE_FAILURE, error })
                    return error;
                })
            } else {
                return response.json().then((response) => {
                    dispatch({ type: CYDER_ACCOUNTS_DETAIL_ARCHIVE_SUCCESS, response })
                    return response;
                })
            }
        }).catch((error) => {
            dispatch({ type: CYDER_ACCOUNTS_DETAIL_ARCHIVE_FAILURE, error })
            return error;
        });
    }
}
export function cyderAccountsDetailSuspendAction() {
    return (dispatch, getState) => {
        const setSavingStateAction = {
            type: CYDER_ACCOUNTS_DETAIL_SUSPEND_REQUEST
        }
        dispatch(setSavingStateAction)
        const url = withQuery(CYDER_API_ROOT + '/account_v1/account', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: "suspendaccount",
        });
        const options = {
            headers: {
                "x-api-key": getState().config.apiKeyCyder,
                "Content-Type": "application/json",
                "Authorization":  getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify({id: getState().cyderAccountsDetailReducer.account.id}),
            method: "POST",
        };
        return fetch(url, options).then((response) => {
            if (!response.ok) {
                return response.json().then((error) => {
                    dispatch({ type: CYDER_ACCOUNTS_DETAIL_SUSPEND_FAILURE, error })
                    return error;
                })
            } else {
                return response.json().then((response) => {
                    dispatch({ type: CYDER_ACCOUNTS_DETAIL_SUSPEND_SUCCESS, response })
                    return response;
                })
            }
        }).catch((error) => {
            dispatch({ type: CYDER_ACCOUNTS_DETAIL_SUSPEND_FAILURE, error })
            return error;
        });
    }
}
export function cyderAccountsDetailActivateAction() {
    return (dispatch, getState) => {
        const setSavingStateAction = {
            type: CYDER_ACCOUNTS_DETAIL_ACTIVATE_REQUEST
        }
        dispatch(setSavingStateAction)
        const url = withQuery(CYDER_API_ROOT + '/account_v1/account', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: "activateaccount",
        });
        const options = {
            headers: {
                "x-api-key": getState().config.apiKeyCyder,
                "Content-Type": "application/json",
                "Authorization":  getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify({id: getState().cyderAccountsDetailReducer.account.id}),
            method: "POST",
        };
        return fetch(url, options).then((response) => {
            if (!response.ok) {
                return response.json().then((error) => {
                    dispatch({ type: CYDER_ACCOUNTS_DETAIL_ACTIVATE_FAILURE, error })
                    return error;
                })
            } else {
                return response.json().then((response) => {
                    dispatch({ type: CYDER_ACCOUNTS_DETAIL_ACTIVATE_SUCCESS, response })
                    return response;
                })
            }
        }).catch((error) => {
            dispatch({ type: CYDER_ACCOUNTS_DETAIL_ACTIVATE_FAILURE, error })
            return error;
        });
    }
}
export function cyderAccountsDetailChangeCCAction() {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_ACCOUNTS_DETAIL_CHANGE_CC_REQUEST })
        dispatch({ type: CYDER_ACCOUNTS_SEARCH_SET_FORCE_REFRESH_FLAG, val: true })
        console.log('changecc');
        return;
    }
}
export function cyderAccountsDetailRemoveCCAction() {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_ACCOUNTS_DETAIL_REMOVE_CC_REQUEST })
        dispatch({ type: CYDER_ACCOUNTS_SEARCH_SET_FORCE_REFRESH_FLAG, val: true })
        console.log('changecc');
        return;
    }
}
export function cyderAccountsDetailSaveChangesAction() {
    console.log('cyderAccountsDetailSaveChangesAction');
    return (dispatch, getState) => {
        const setSavingStateAction = {
            type: CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_REQUEST
        }
        dispatch(setSavingStateAction)
        const url = withQuery(CYDER_API_ROOT + '/account_v1/account', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: "updatebasicdetails",
        });
        let account = getState().cyderAccountsDetailReducer.account;

        var postBody = {
            firstname: account.firstname,
            lastname: account.lastname,
            phonenumber: account.phonenumber,
            address: account.address,
            companyname: account.companyname,
            idno: account.idno,
            idtype: account.idtype,
            orgAppId: account.orgAppId,
            emailaddress: account.emailaddress,
        }
        delete(postBody.companyname_lower);
        delete(postBody.firstname_lower);
        delete(postBody.lastname_lower);
        const options = {
            headers: {
                "x-api-key": getState().config.apiKeyCyder,
                "Content-Type": "application/json",
                "Authorization":  getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify(postBody),
            method: "POST",
        };
        return fetch(url, options).then((response) => {
            if (!response.ok) {
                response.json().then((error) => {
                    dispatch({ type: CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_FAILURE, error })
                })
            } else {
                response.json().then((response) => {
                    dispatch({ type: CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_SUCCESS, response })
                    return response;
                })
            }
        }).catch((error) => {
            dispatch({ type: CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_FAILURE, error })
            return error;
        });
    }
}
export function cyderAccountsDetailModalToggleAction(modalAction) {
    return {
        type: CYDER_ACCOUNTS_DETAIL_MODAL_TOGGLE,
        modalAction,
    }
}

