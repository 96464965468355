import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Alert, Button } from 'reactstrap';
import {
    cyderLoginAction,
    cyderLoginSetErrorMessageAction,
    cyderLoginClearErrorMessageAction,
    cyderLoginResetState,
    cyderResetProfileAction,
} from '../../actions/security/cyderSecurityAction';
import CyderForm from '../CyderForm';
import CyderInputGroup from '../CyderInputGroup';
import history from '../../history';
import sha512 from 'js-sha512';
import LoadingSpinner from '../../cyderComponents/loadingSpinner/LoadingSpinner';

class Login extends React.Component {
    componentDidMount() {
        this.props.resetLoginPageState();
    }
    render() {
        const style = this.props.loginError ? null : { display: 'none' };
        return (
            <CyderForm title="Login" description="Please enter email address and password to login">
                <div className="form-group">
                    <Alert style={style} color="danger">
                        {this.props.errorMessage ? this.props.errorMessage : null}
                    </Alert>
                    {this.props.requesting ? (
                        <LoadingSpinner />
                    ) : (
                        <div>
                            <CyderInputGroup field={this.props.fields[0]} />
                            <CyderInputGroup field={this.props.fields[1]} />
                        </div>
                    )}
                </div>
                <Button
                    disabled={this.props.requesting}
                    className="btn btn-primary"
                    type="submit"
                    onClick={() => this.validateThenSubmit()}
                >
                    Submit
                </Button>
                <Link className="float-right" to="/forgotpassword">
                    Forgot Password
                </Link>
            </CyderForm>
        );
    }

    validateThenSubmit() {
        this.props.clearErrorMessage();

        const username = document.getElementById(this.props.fields[0].id).value;
        const password = document.getElementById(this.props.fields[1].id).value;

        if (username === '' || password === '') {
            this.props.setErroMessage('Please enter both username and password');
            return false;
        }
        this.props.loginSubmit(username, password);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        fields: state.cyderLoginReducer.fields,
        loginError: state.cyderLoginReducer.loginError,
        errorMessage: state.cyderLoginReducer.errorMessage,
        requesting: state.cyderLoginReducer.requesting,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        clearErrorMessage: () => {
            dispatch(cyderLoginClearErrorMessageAction());
        },
        setErroMessage: errorMessage => {
            dispatch(cyderLoginSetErrorMessageAction(errorMessage));
        },
        loginSubmit: (username, password) => {
            const hashedpw = sha512(password);

            const data = {
                username,
                password: hashedpw,
                system: 'CLOUDADMIN',
            };

            var navigateChangePassword = () => {
                var navigate = '/changepassword';
                history.replace(navigate);
            };
            var navigateHome = () => {
                var navigate = '/home';
                history.replace(navigate);
            };

            dispatch(cyderLoginAction(data)).then(res => {
                if (res.errcode) {
                    // error
                } else {
                    if (res.needchangepassword === 'Y' || res.firstlogin === 'Y') {
                        navigateChangePassword();
                    } else {
                        navigateHome();
                    }
                }
            });
        },
        resetLoginPageState: () => {
            dispatch(cyderLoginResetState());
            dispatch(cyderResetProfileAction());
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Login);
