/**
 * Cyder Expenses Reducer
 * 
 * Author: Fernando
 * 
 */
import { CYDER_EXPENSE_SELECT_CATEGORY, CYDER_EXPENSE_START, CYDER_EXPENSE_CANCEL, CYDER_EXPENSE_SELECT_PROJECT,
  CYDER_EXPENSE_SELECT_EXPENSE_DATE, CYDER_EXPENSE_SELECT_TRAVEL_METHOD } 
from '../../actions/constants/actionTypes' 

var cyderExpense = {
  inprogress: false,
  projectValue: {
    disabled: false,
    searchable: true,
    value: '',
    clearable: true
  },
  travelMethodValue: {
    disabled: false,
    searchable: true,
    value: '',
    clearable: true
  },
  expenseDateValue: '',
  categories: {
    options: [
      {
        value: 'travel',
        label: 'Travel Expense',
        className: 'State-ACT'
      },
      {
        value: 'entertainment',
        label: 'Entertainment Expense',
        className: 'State-ACT'
      },
      {
        value: 'other',
        label: 'Other Expense',
        className: 'State-ACT'
      }
    ],
    itemValue: {
      disabled: false,
      searchable: true,
      value: '',
      clearable: true
    }
  },
  title: {
    title: '',
    description: ''
  },
  entertainmentExpense: {
    show: false,
    fields: []
  },
  otherExpense: {
    show: false,
    fields: []
  },
  travelExpense: {
    show: false,
    fields: [
      {
        id: 'email',
        value: '',
        name: 'email',
        icon: 'account_circle',
        type: 'text',
        errors: [],
        rules: {
          title: 'Your email',
          required: true
        }
      },
      {
        id: 'password',
        value: '',
        name: 'password',
        icon: 'lock_outline',
        type: 'password',
        errors: [],
        rules: {
          title: 'Your password',
          required: true
        }
      }
    ]
  }
}

/**
 * Select Category
 * 
 * @param {*} state 
 * @param {*} action 
 */
export function cyderExpenseReducer(state = cyderExpense, action) {
  state.categories.itemValue.selectValue = action.newCategory;

  switch (action.type) {
    case CYDER_EXPENSE_SELECT_CATEGORY:
      console.log("Select category: ", action.newCategory);  

      // Cloned the title object first, Object.assign(state) below does not do Object(state) deep copy
      // Damn-it!!!, why we need to deep copy nested fields??? Is Redux not ready?
      state.title = Object.assign({}, state.title);
      state.categories = Object.assign({}, state.categories);
      state.categories.itemValue = Object.assign({}, state.categories.itemValue);

      switch (action.newCategory) {
        case "travel":
          state.travelExpense = Object.assign({}, state.travelExpense);
          state.travelExpense.show = true;
          state.entertainmentExpense.show = false;
          state.otherExpense.show = false;
          state.title.title = "Travel Expense";
          state.title.description = "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium";
          break;
        case "entertainment":
          state.entertainmentExpense = Object.assign({}, state.entertainmentExpense);
          state.entertainmentExpense.show = true;
          state.travelExpense.show = false;
          state.otherExpense.show = false;
          state.title.title = "Entertainment Expense";
          state.title.description = "Et harum quidem rerum facilis est et expedita distinctio";
          break;
        case "other":
          state.otherExpense = Object.assign({}, state.otherExpense);
          state.otherExpense.show = true;
          state.travelExpense.show = false;
          state.entertainmentExpense.show = false;
          state.title.title = "Other Expense";
          state.title.description = "Itaque earum rerum hic tenetur a sapiente delectus";
          break;
        default:
      } 

      state.categories.itemValue.value = action.newCategory;

      // Clone state as another object
      return Object.assign({}, state);

    case CYDER_EXPENSE_START:
      console.log("Start expense process");  
      
      state.inprogress = true;

      console.log(state);
      // Clone state as another object
      return Object.assign({}, state);

    case CYDER_EXPENSE_CANCEL:
      console.log("Cancel expense process");  
      
      state.inprogress = false;
      // state.travelExpense.show = false;
      // state.entertainmentExpense.show = false;
      // state.otherExpense.show = false;

      // Clone state as another object
      return Object.assign({}, state);
      
    case CYDER_EXPENSE_SELECT_EXPENSE_DATE:
      console.log("Select expense date ", action.expenseDate); 

      state.expenseDateValue = action.expenseDate;  

      // Clone state as another object
      return Object.assign({}, state);
      
    case CYDER_EXPENSE_SELECT_PROJECT:
      console.log("Select project ", action.newProject); 

      state.projectValue = Object.assign({}, state.projectValue);
      state.projectValue.value = action.newProject;  

      // Clone state as another object
      return Object.assign({}, state);
      
    case CYDER_EXPENSE_SELECT_TRAVEL_METHOD:
      console.log("Select travel method ", action.newTravelMethod); 

      state.travelMethodValue = Object.assign({}, state.travelMethodValue);
      state.travelMethodValue.value = action.newTravelMethod;  

      // Clone state as another object
      return Object.assign({}, state);
      
    default:
      return state
  }
}
