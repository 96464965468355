import StoredLayout from '../../../layouts/StoredLayout';
import LoadingSpinner from '../../../cyderComponents/loadingSpinner/LoadingSpinner';
import DialogModal from '../../../cyderComponents/modals/DialogModal';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../../history';
import withQuery from 'with-query';
import moment from 'moment';
import { Nav, NavItem, Container, Button, FormGroup, Label, Input, Form, Badge, Row, Col } from 'reactstrap';

import {
    toggleCheckboxes,
    toggleCheckboxesAll,
    myReceiptsGetReceipts,
    myReceiptsDeleteReceipt,
    expenseMyReceiptsToggleModal,
    myReceiptsCopyReceipt,
} from '../../../actions/expenses/cyderExpenseMyReceiptsAction.js';
import { setJumbotronTitle } from '../../../actions/pagedata';

import cyderlib from '../../../js/cyderlib.js';
import reactTableFilter from '../../../js/reactTableFilter';
import '../../../css/forms/default-forms.css';
import ReactTable from 'react-table';

import ExpenseNewMileagePage from './new/ExpenseNewMileagePage';
import ExpenseNewReceiptPage from './new/ExpenseNewReceiptPage';

const tab0Url = '/expense/myreceipts';
const newExpenseTravelUrl = '/expense/myreceipts/new/mileage';
const newExpenseStandardUrl = '/expense/myreceipts/new/receipt';
const chooseNewExpenseTabUrl = '/expense/myreceipts/new';

class MyReceiptsPage extends React.Component {
    constructor(props) {
        super(props);
        this.onFilteredChange = this.onFilteredChange.bind(this);
        this.filterAllFunc = this.filterAllFunc.bind(this);
        this.state = {
            filterAll: '',
        };
    }
    componentDidMount() {
        this.props.getReceipts();
    }
    tabNav(index, url) {
        history.replace(url);
    }
    handleStartNewClaim(event) {
        event.preventDefault();
        const type = document.getElementById('claimType').value;
        if (type === 'mileage') {
            history.replace(newExpenseTravelUrl);
        } else if (type === 'standard') {
            history.replace(newExpenseStandardUrl);
        }
    }
    onFilteredChange(filtered) {
        this.setState(reactTableFilter.onFilteredChange(filtered));
    }
    filterAllFunc(e) {
        this.setState(reactTableFilter.filterAllFunc(e));
    }
    render() {
        const routeProps = {
            match: this.props.match,
            location: this.props.location,
            history: this.props.history,
        };
        const newExpenseTabActive =
            this.props.location.pathname === newExpenseTravelUrl ||
            this.props.location.pathname === newExpenseStandardUrl ||
            this.props.location.pathname === chooseNewExpenseTabUrl
                ? true
                : false;
        return (
            <Container>
                <Container className="mt-2">
                    <CurrentExpenses
                        filtered={this.state.filtered}
                        onFilteredChange={this.onFilteredChange}
                        key={1}
                        getReceipts={this.props.getReceipts}
                        modalAction={this.props.modalAction}
                        modalOpen={this.props.modalOpen}
                        modalHeader={this.props.modalHeader}
                        modalMessage={this.props.modalMessage}
                        saved={this.props.saved}
                        saving={this.props.saving}
                        toggleModal={this.props.toggleModal}
                        handleDelete={this.props.handleDelete}
                        selectedItemsCount={this.props.selectedItemsCount}
                        loading={this.props.loading}
                        receipts={this.props.receipts}
                        selectedItems={this.props.selectedItems}
                        handleCheckbox={this.props.handleCheckbox}
                        handleCheckboxAll={this.props.handleCheckboxAll}
                    />
                    {this.props.location.pathname === chooseNewExpenseTabUrl ? (
                        <NewExpenses handleStartNewClaim={this.handleStartNewClaim} setJumbotronTitle={this.props.setJumbotronTitle} />
                    ) : null}
                    {/* persist this because google map */}
                    {this.props.location.pathname === newExpenseTravelUrl ? (
                        <ExpenseNewMileagePage {...routeProps} refreshList={this.props.getReceipts} />
                    ) : null}
                    {this.props.location.pathname === newExpenseStandardUrl ? (
                        <ExpenseNewReceiptPage {...routeProps} refreshList={this.props.getReceipts} />
                    ) : null}
                </Container>
            </Container>
        );
    }
}

class CurrentExpenses extends React.Component {
    constructor(props) {
        super(props);
        this.handleToggleModal = this.handleToggleModal.bind(this);
        this.toggleAction = this.toggleAction.bind(this);
    }
    toggleAction() {
        this.handleToggleModal();
        if (this.props.modalAction === 'refresh') {
            this.props.getReceipts();
        } else if (this.props.modalAction === 'redirect') {
            history.push('/expense/myreceipts');
        }
    }
    calcCurrentPositiveButtonText() {
        if (this.props.modalAction === 'save') {
            return 'Save';
        } else if (this.props.modalAction === 'delete') {
            return 'Delete';
        } else if (this.props.modalAction === 'redirect') {
            return 'Ok';
        } else if (this.props.modalAction === 'close') {
            return 'Ok';
        } else {
            return 'Ok';
        }
    }
    calcCurrentPositiveButtonAction() {
        if (this.props.modalAction === 'save') {
            return () => {
                let selectedDefaultFixes = {
                    category: document.getElementById('category').value,
                    paymentmode: document.getElementById('paymentmode').value,
                    project: document.getElementById('project').value,
                    currency: this.state.selectedCurrency,
                };
                this.props.handleSave(selectedDefaultFixes);
            };
        } else if (this.props.modalAction === 'delete') {
            return this.props.handleDelete;
        } else if (this.props.modalAction === 'close') {
            return this.handleToggleModal;
        } else if (this.props.modalAction === 'refresh') {
            return () => {
                this.props.getReceipts();
                this.handleToggleModal();
            };
        } else if (this.props.modalAction === 'redirect') {
            return () => {
                history.push('/expense/myreceipts');
                this.handleToggleModal();
            };
        } else {
            return this.handleToggleModal;
        }
    }
    handleToggleModal(modalAction, data) {
        this.props.toggleModal(modalAction, data);
    }
    render() {
        const data = this.props.receipts;

        const statusBadgeColor = status => {
            switch (status) {
                case 'Submitted':
                    return 'primary';
                case 'Returned':
                    return 'danger';
                case 'Approved':
                    return 'success';
                default:
                    return 'info';
            }
        };
        const columns = [
            {
                Header: 'Date',
                accessor: 'expensedate',
                width: 100,
                filterable: false,
                Cell: props => moment(props.value).format('DD-MM-YYYY'),
                sortMethod: (a, b, desc) => cyderlib.tableSortDate(a, b, desc, 'DD-MM-YYYY'),
                // }, {
                //     Header: 'Receipt No.',
                //     accessor: 'receiptno',
            },
            {
                Header: 'Category',
                width: 100,
                accessor: 'category',
                filterMethod: cyderlib.filterIgnoreCase,
                // }, {
                //     Header: 'Project',
                //     accessor: 'project',
            },
            {
                Header: 'Purpose',
                accessor: 'remarks',
                filterMethod: cyderlib.filterIgnoreCase,
            },
            {
                className: 'text-right',
                headerClassName: 'text-right',
                Header: 'Amount',
                width: 100,
                filterMethod: cyderlib.filterCurrencyCent,
                accessor: 'amount',
                Cell: props => 'S$' + (props.value / 100).toFixed(2),
            },
            {
                Header: 'Status',
                width: 150,
                filterable: false,
                filterMethod: cyderlib.filterIgnoreCase,
                accessor: 'status',
                Cell: props => {
                    return <Badge color={statusBadgeColor(props.value)}>{props.value}</Badge>;
                },
            },
            {
                Header: '',
                headerClassName: 'rt-no-sort-ind',
                accessor: 'id',
                sortable: false,
                filterable: false,
                width: 150,
                Cell: props => {
                    let editUrl = withQuery('/autorec/edit', {
                        receiptid: props.value,
                        index: props.row._index,
                    });
                    return (
                        <div className="">
                            <Link to={editUrl}>
                                <Button color="link" size="sm">
                                    <i className="align-middle material-icons">edit</i>
                                </Button>
                            </Link>
                            {props.row.status === 'Saved' ? (
                                <Button
                                    color="link"
                                    className="ml-1"
                                    size="sm"
                                    onClick={() => this.handleToggleModal('delete', { receiptId: props.row.receiptId })}
                                >
                                    <i className="text-danger align-middle material-icons">delete</i>
                                </Button>
                            ) : null}
                        </div>
                    );
                },
            },
            reactTableFilter.generateAllColumnFilter(['category', 'purpose', 'amount', 'status']),
        ];

        let selectedItemsCount = 0;
        for (let key in this.props.selectedItems) {
            if (this.props.selectedItems[key] === true) selectedItemsCount += 1;
        }
        const CustomNoData = () => <div style={{ display: 'none' }} />;
        return [
            this.props.loading ? (
                <LoadingSpinner key="loadingSpinner" />
            ) : (
                <div key="rt-table">
                    <ReactTable
                        filtered={this.props.filtered}
                        onFilteredChange={this.onFilteredChange}
                        filterable={false}
                        defaultFilterMethod={reactTableFilter.defaultFilterMethod}
                        className="-highlight mb-2"
                        data={data}
                        columns={columns}
                        minRows={1}
                        NoDataComponent={CustomNoData}
                        showPagination={false}
                    />
                </div>
            ),
            <DialogModal
                key="modal"
                modal={this.props.modalOpen}
                toggle={this.toggleAction}
                customHeader={this.props.modalHeader || 'Confirmation'}
                customModalBody={this.props.saving ? <LoadingSpinner /> : this.props.modalMessage}
                positiveButtonAction={this.calcCurrentPositiveButtonAction()}
                positiveButtonText={this.calcCurrentPositiveButtonText()}
                onlyOneButton={this.props.saved}
                buttonDisabler={this.props.saving}
            />,
        ];
    }
}
const NewExpenses = ({ handleStartNewClaim, setJumbotronTitle }) => {
    setJumbotronTitle('New Expense');
    return (
        <div>
            <Form>
                <FormGroup>
                    <Label for="exampleSelect">Please select expense category</Label>
                    <Input type="select" id="claimType">
                        <option value="standard">New Receipt</option>
                        <option value="mileage">New Mileage Claim</option>
                    </Input>
                </FormGroup>
                <Button onClick={handleStartNewClaim}>Start</Button>
            </Form>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...state.cyderExpenseMyReceiptsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (modalAction, data) => {
            dispatch(expenseMyReceiptsToggleModal(modalAction ? modalAction : null, data));
        },
        handleDelete: () => {
            dispatch(myReceiptsDeleteReceipt());
        },
        handleCheckbox: id => {
            dispatch(toggleCheckboxes(id));
        },
        handleCheckboxAll: () => {
            dispatch(toggleCheckboxesAll());
        },
        copyItem: index => dispatch(myReceiptsCopyReceipt(index)),
        setJumbotronTitle: title => dispatch(setJumbotronTitle(title)),
        getReceipts: () => {
            const status = document.getElementById('status') ? document.getElementById('status').value : 'Saved';
            dispatch(myReceiptsGetReceipts(status));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StoredLayout(MyReceiptsPage));
