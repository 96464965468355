/**
 * User menu
 * @param {*} state 
 * @param {*} action 
 */

let menuItems = [
  {
    icon: 'sli-settings',
    iconColor: 'default',
    name: 'Profile',
    badge: false,
    badgeText: false,
    badgeClass: false,
    action: 'profile'
  },
  {
    icon: 'sli-power',
    iconColor: 'default',
    name: 'Logout',
    badge: false,
    badgeText: false,
    badgeClass: false,
    action: 'logout'
  }
]

/**
 * No changing the state now
 * @param {*} state 
 * @param {*} action 
 */
export function usermenu(state = menuItems, action) {
  return state;
}
