import {
    CYDER_EXPENSE_NEW_RECEIPT_MODAL_TOGGLE,
    CYDER_EXPENSE_NEW_RECEIPT_CHANGE_FIELD,
    CYDER_EXPENSE_NEW_RECEIPT_SAVE_REQUEST,
    CYDER_EXPENSE_NEW_RECEIPT_SAVE_SUCCESS,
    CYDER_EXPENSE_NEW_RECEIPT_SAVE_FAILURE,
    CYDER_EXPENSE_NEW_RECEIPT_SET_FIELDS,
    CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_REQUEST,
    CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_SUCCESS,
    CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_FAILURE,
    CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_REQUEST,
    CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_SUCCESS,
    CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_FAILURE,
    CYDER_EXPENSE_NEW_RECEIPT_FORCE_LOADING_STATE,
    // CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_HISTORY_SUCCESS,
} from '../../actions/constants/actionTypes' 
import moment from 'moment';

// import cyderlib from '../../js/cyderlib';

var defaultState = {
    loading: false,
    saving: false,
    saved: false,
    errorMessage: null,
    modalOpen: false,
    modalAction: null,
    modalMessage: null,
    modalHeader: null,
    receiptFields: {
        expensedate: moment().format('DD/MM/YYYY'),
        amount: "",
        project: null,
        receiptno: "",
        paymentmode: "",
        category: "",
        remarks: "",
        reimbursable: true,
    },
    workflowHistory: null,
}

export function cyderExpenseNewReceiptClaimReducer(state = defaultState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        // case CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_HISTORY_SUCCESS:
        // newState.workflowHistory = action.response;
            // return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            if (action.modalAction === "save") {
                newState.modalMessage = "Are you sure you want to save this receipt?";
                newState.saved = false;
            } else if (action.modalAction === "delete") {
                newState.modalMessage = "Are you sure you want to delete this receipt?";
                newState.saved = false;
            }
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_CHANGE_FIELD:
            newState.receiptFields = Object.assign({}, state.receiptFields);
            newState.receiptFields[action.key] = action.value;
            // newState.validation = Object.assign({}, state.validation);
            // newState.validation[action.key] = cyderlib.validate(action.key, action.value);
            // newState.changesMade = true;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_SAVE_REQUEST:
            newState.saving = true;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_SAVE_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Receipt Successfully saved";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "redirect";
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_SAVE_FAILURE:
            newState.modalMessage = "Failed to save receipt: " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_SET_FIELDS:
            if (action.receiptFields !== null && action.receiptFields !== undefined) {
                newState.receiptFields = Object.assign({}, action.receiptFields);
            } else if (action.resetFields === true) {
                newState.receiptFields = Object.assign({}, defaultState.receiptFields);
            }
            newState.workflowHistory = null;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_SUCCESS:
            newState.loading = false;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_FAILURE:
            newState.loading = false;
            newState.modalOpen = true;
            newState.modalHeader = "Error";
            newState.modalAction = "close";
            newState.errorMessage = action.error.message;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_REQUEST:
            newState.saving = true;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_SUCCESS:
            newState.modalMessage = action.response.message ? action.response.message : "Receipt successfully deleted";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "redirect";
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_FAILURE:
            newState.modalMessage = "Failed to delete receipt : " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            newState.modalOpen = true;
            return newState;
        case CYDER_EXPENSE_NEW_RECEIPT_FORCE_LOADING_STATE:
            newState.loading = action.loading;
            return newState;
        default:
            return state;
    }
}
