import {
    CYDER_SYSPARAMS_GET_SUCCESS,
    CYDER_SYSPARAMS_SAVE_VALUE_SUCCESS,
    CYDER_SYSPARAMS_GET,
    CYDER_SYSPARAMS_PAGE_MODAL_TOGGLE,
    CYDER_SYSPARAMS_PAGE_MODAL_SET_SYSPARAM,
    CYDER_SYSPARAMS_PAGE_MODAL_CHANGE_VALUE,
    CYDER_SYSPARAMS_SAVE_VALUE_SAVING,
} from '../constants/actionTypes';

import { CYDER_API_ROOT, DJARVIS_API_ROOT, CYDER_COMMON_API_ROOT } from '../../config';

import withQuery from 'with-query';

/**
 * Security action creators
 *
 * Author: Fernando
 */

/**
 * Login function
 *
 * @param {*} fields
 * @param {*} callback
 */
export function cyderSysparamGetAction(id) {
    // Redux Thunk will inject dispatch here:
    return (dispatch, getState) => {
        // Reducers may handle this to set a flag like isFetching
        dispatch({ type: CYDER_SYSPARAMS_GET, id });

        // Perform the actual API call
        const url = withQuery(CYDER_COMMON_API_ROOT + '/sysparam', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: 'findall',
        });
        const options = {
            headers: {
                'x-api-key': getState().config.apiKeyCyder,
                'Content-Type': 'application/json',
                Authorization: getState().cyderProfileReducer.profile.authorizationToken,
            },
        };
        return fetch(url, options)
            .then(response => response.json())
            .then(
                response => {
                    // Reducers may handle this to show the data and reset isFetching
                    dispatch({ type: CYDER_SYSPARAMS_GET_SUCCESS, id, response });
                    // if we return stuff here, we can use it as a promise
                    return response;
                },
                error => {
                    // TODO
                    // Reducers may handle this to reset isFetching
                    // dispatch({ type: 'GET_USER_FAILURE', id, error })
                    // Rethrow so returned Promise is rejected
                    throw error;
                },
            )
            .catch(error => {
                //TODO
                console.log(error);
                throw error;
            });
    };
}

export function cyderSysparamSaveAction(data, callback) {
    return function(dispatch, getState) {
        const setSavingStateAction = {
            type: CYDER_SYSPARAMS_SAVE_VALUE_SAVING,
        };
        dispatch(setSavingStateAction);
        const url = withQuery(CYDER_COMMON_API_ROOT + '/sysparam', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: 'update',
        });
        const options = {
            headers: {
                'x-api-key': getState().config.apiKeyCyder,
                'Content-Type': 'application/json',
                Authorization: getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify(data),
            method: 'POST',
        };
        fetch(url, options)
            .then(res => res.json())
            .then(res => {
                const action = {
                    type: CYDER_SYSPARAMS_SAVE_VALUE_SUCCESS,
                    sysparams: res,
                };
                if (callback) {
                    callback().then(() => dispatch(action));
                } else {
                    dispatch(action);
                }
            })
            .catch(
                //TODO: http error handling
                res => {},
            );
    };
}

export function cyderSysparamEditModalChangeValueAction(newValue) {
    return {
        type: CYDER_SYSPARAMS_PAGE_MODAL_CHANGE_VALUE,
        newValue,
    };
}
export function cyderSysparamEditModalToggle() {
    return {
        type: CYDER_SYSPARAMS_PAGE_MODAL_TOGGLE,
    };
}
export function cyderSysparamEditModalSetSysparam(code, label, newValue) {
    return {
        type: CYDER_SYSPARAMS_PAGE_MODAL_SET_SYSPARAM,
        code,
        label,
        newValue,
    };
}
