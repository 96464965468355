import StoredLayout from '../../../../layouts/StoredLayout'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// import history from '../../history'
import history from '../../../../history'
import {
    Input,
    // Table,
    Button,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    // FormFeedback,
    InputGroup,
    InputGroupAddon,
} from "reactstrap";
import Switch from "react-switch";
import {
    translate,
    // Trans
} from 'react-i18next';
import GoogleMapReact from 'google-map-react';
import SearchBox from '../../../../cyderComponents/map/SearchBox';
import LoadingSpinner from '../../../../cyderComponents/loadingSpinner/LoadingSpinner';
import DialogModal from '../../../../cyderComponents/modals/DialogModal';
import {
    travelExpenseSetStartLocation,
    travelExpenseSetDestinationLocation,
    travelExpenseRemoveMarkers,
    travelExpenseSetCurrentDirectionsResult,
    expenseNewMileageClaimChangeField,
    expenseNewMileageClaimSaveMileage,
    expenseNewMileageClaimSetFields,
    expenseNewMileageClaimForceLoadingState,
    expenseNewMileageClaimToggleModal,
    expenseNewMileageClaimDeleteSavedReceipt,
} from '../../../../actions/expenses/cyderExpenseNewMileageClaimAction'
import {
    expenseSharedGetMileageCategories,
    expenseSharedGetProjects,
    expenseSharedGetLocations,
    expenseSharedGetPaymentModes,
    expenseSharedLoadedData,
} from '../../../../actions/expenses/cyderExpenseClaimSharedAction'
import CyderDatePicker from '../../../../cyderComponents/forms/CyderDatePicker';
import { setJumbotronTitle } from '../../../../actions/pagedata'
import qs from 'query-string';


class ExpenseMileage extends React.Component {
    componentDidMount() {
        // TODO: load on demand stuff list of items from db from common lib
        this.props.setStartLoc(null, null);
        this.props.setDestinationLoc(null, null);
        const theqs = this.props.location ? qs.parse(this.props.location.search) : {};
        this.setState({
            theqs,
        });
        this.props.getRelevantStuff(theqs.receiptid, theqs.index).then((mileageInfo) => {
            if (mileageInfo) {
                const projectValue = this.props.projects.findIndex((item) => (item.code === mileageInfo.project));
                const categoryValue = this.props.mileageCategories.findIndex((item) => (item.code === mileageInfo.category));
                const paymentModeValue = this.props.paymentModes.findIndex((item) => (item.code === mileageInfo.paymentmode));

                const categoryObject = this.props.mileageCategories[categoryValue];
                const currentRate = parseFloat(categoryObject.rate);
                const currentRateUnit = categoryObject.rateunit;
                document.getElementById('project').value = projectValue;
                document.getElementById('category').value = categoryValue;
                document.getElementById('paymentmode').value = paymentModeValue;
                document.getElementById('remarks').value = mileageInfo.remarks;
                let rt = {};
                if (mileageInfo.roundTrip !== undefined && mileageInfo.roundTrip !== null) {
                    rt.roundTrip = mileageInfo.roundTrip;
                }
                this.setState({
                    ...rt,
                    startLocText: mileageInfo.from,
                    destinationLocText: mileageInfo.to,
                    project: projectValue,
                    rate: currentRate,
                    rateUnit: currentRateUnit,
                    paymentMode: paymentModeValue,
                    distance: mileageInfo.distance,
                });
            }


            /*
                amount: claimableAmount,
                from: startLocName,
                fromlat: startLat,
                fromlng: startLng,
                to: destinationLocName,
                tolat: destinationLat,
                tolng: destinationLng,
            }
            */
            if (this.state.map && this.state.maps) {
                this.placeMarkersOnMap();
            }
        });
        this.props.setJumbotronTitle(this.props.headerTitle);
    }
    constructor(props) {
        super(props);
        this.state = {
            distance: null,
            theqs: null,
            recalculateLocation: false,
            project: null,
            paymentMode: null,
            rate: 0,
            rateunit: "km",
            travelMode: "car",
            roundTrip: false,
            showLocations: true,
            locMarkers: [],
            startLocText: "",
            destinationLocText: "",
            pinningStartLoc: false,
            pinningDestinationLoc: false,
            pinnedStartLoc: false,
            pinnedDestinationLoc: false,
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleToggleModal = this.handleToggleModal.bind(this);
        this.toggleAction = this.toggleAction.bind(this);
        this.setStateAdapter = this.setStateAdapter.bind(this);
        this.handleRecalculateLocationSwitchChange = this.handleRecalculateLocationSwitchChange.bind(this);
        this.pinStartLoc = this.pinStartLoc.bind(this);
        this.pinDestinationLoc = this.pinDestinationLoc.bind(this);
        this.setDestinationLocMarker = this.setDestinationLocMarker.bind(this);
        this.setStartLocMarker = this.setStartLocMarker.bind(this);
        this.handleDestinationLocTextChange = this.handleDestinationLocTextChange.bind(this);
        this.handleStartLocTextChange = this.handleStartLocTextChange.bind(this);
        this.placeMarkersOnMap = this.placeMarkersOnMap.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleMileageCategoryChange = this.handleMileageCategoryChange.bind(this);
        this.handleProjectChange = this.handleProjectChange.bind(this);
        this.handlePaymentModeChange = this.handlePaymentModeChange.bind(this);
    }
    calcDistance() {
        if (this.props.travelLocStart === null || this.props.travelLocDestination === null ) {
            console.log('need start and destination location');
            return;
        }
        console.log('calculating distance');
        if (this.props.travelLocStartMarker !== null && !this.state.pinnedStartLoc) {
            // remove marker if exists on map
            this.props.travelLocStartMarker.setMap();
            this.props.removeLocMarkers("start");
        }
        if (this.props.travelLocDestinationMarker !== null && !this.state.pinnedDestinationLoc) {
            // remove marker if exists on map
            this.props.travelLocDestinationMarker.setMap();
            this.props.removeLocMarkers("destination");
        }
        if (this.state.directionsDisplay) {
            // remove previous result if exists
            this.state.directionsDisplay.setMap(null);
            this.state.directionsDisplay.setPanel(null);
        }
        let map = this.state.map;
        let maps = this.state.maps;
        var directionsDisplay = new maps.DirectionsRenderer({draggable: false});
        const startLat = this.state.pinnedStartLoc ? this.props.travelLocStartMarker.position.lat() : this.props.travelLocStart.geometry.location.lat();
        const startLng = this.state.pinnedStartLoc ? this.props.travelLocStartMarker.position.lng() : this.props.travelLocStart.geometry.location.lng();
        const destLat = this.state.pinnedDestinationLoc ? this.props.travelLocDestinationMarker.position.lat() : this.props.travelLocDestination.geometry.location.lat();
        const destLng = this.state.pinnedDestinationLoc ? this.props.travelLocDestinationMarker.position.lng() : this.props.travelLocDestination.geometry.location.lng();
        var start = new maps.LatLng(startLat, startLng);
        var end = new maps.LatLng(destLat, destLng);
        var request = {
            origin: start,
            destination: end,
            travelMode: 'DRIVING'
        };
        let scdr = this.props.setCurrentDirectionsResult.bind(this);
        var directionsService = new maps.DirectionsService();
        this.setState({
            directionsDisplay,
        });
        let setStateAdapter = this.setStateAdapter;
        directionsService.route(request, function(result, status) {
            let distance;
            if (result.routes.length === 0) {
                distance = 0;
            } else {
                result.routes[0].legs.forEach((leg) => {
                    distance =+ leg.distance.value;
                });
            }
            setStateAdapter({
                distance,
            });
            if (status === 'OK') {
                // directionsDisplay.setPanel(document.getElementById('directionsPanel'))
                directionsDisplay.setDirections(result);
                directionsDisplay.setMap(map);
                scdr(result);
            }
        });
    }
    setStateAdapter(obj) {
        this.setState(obj);
    }
    setMap() {
        this.state.directionsDisplay.setMap(null);
    }
    setStartLocMarker(loc) {
        this.setState({startLocText: loc.formatted_address});
        if (this.props.travelLocStartMarker !== null) {
            // remove previous marker if there is one
            this.props.travelLocStartMarker.setMap();
        }
        var position = {
            lat: loc.geometry.location.lat(),
            lng: loc.geometry.location.lng(),
        };
        var marker = new this.state.maps.Marker({
            position,
            map: this.state.map,
            title: loc.name,
        });
        marker.setMap(this.state.map);
        this.props.setStartLoc(loc, marker).then(() => {
            if (this.props.directionsResult || (this.props.travelLocStart && this.props.travelLocDestination)) {
                this.calcDistance();
            }
        })
    }
    setStartLocMarkerFromLocation(marker) {
        marker.setMap(this.state.map);
        this.props.setStartLoc({}, marker).then(() => {
            if (this.props.directionsResult || (this.props.travelLocStart && this.props.travelLocDestination)) {
                this.calcDistance();
            }
        })
    }
    setDestinationLocMarkerFromLocation(marker) {
        marker.setMap(this.state.map);
        this.props.setDestinationLoc({}, marker).then(() => {
            if (this.props.directionsResult || (this.props.travelLocStart && this.props.travelLocDestination)) {
                this.calcDistance();
            }
        })
    }
    setDestinationLocMarker(loc) {
        this.setState({destinationLocText: loc.formatted_address});
        if (this.props.travelLocDestinationMarker !== null) {
            // remove previous marker if there is one
            this.props.travelLocDestinationMarker.setMap();
        }
        var position = {
            lat: loc.geometry.location.lat(),
            lng: loc.geometry.location.lng(),
        };
        var marker = new this.state.maps.Marker({
            position,
            map: this.state.map,
            title: loc.name,
        });
        marker.setMap(this.state.map);
        this.props.setDestinationLoc(loc, marker).then(() => {
            if (this.props.directionsResult || (this.props.travelLocStart && this.props.travelLocDestination)) {
                this.calcDistance();
            }
        })
    }
    initGoogleMaps(map, maps) {
        this.setState({
            map,
            maps,
        });
        if (this.props.locations.length > 0) {
            this.placeMarkersOnMap();
        }
    }
    placeMarkersOnMap() {
        this.props.locations.forEach((loc) => {
            const position = {
                lat: loc.lat,
                lng: loc.lng,
            }
            let marker = new this.state.maps.Marker({
                position,
                map: this.state.map,
                title: loc.name,
            });
            marker.setMap(this.state.map);
            const markerListener = (marker) => {
                if (this.state.pinningStartLoc) {
                    this.setState({startLocText: marker.title, pinningStartLoc: false, pinnedStartLoc: true});
                    this.setStartLocMarkerFromLocation(marker);
                } else if (this.state.pinningDestinationLoc) {
                    this.setState({destinationLocText: marker.title, pinningDestinationLoc: false, pinnedDestinationLoc: true});
                    this.setDestinationLocMarkerFromLocation(marker);
                }
            };
            marker.addListener('click', () => {markerListener(marker)});
            this.state.locMarkers.push(marker);
        });
    }
    handleMileageCategoryChange(event) {
        var currentRate = this.props.mileageCategories[event.target.value];
        if (!currentRate) return;
        this.setState({
            rate: parseFloat(currentRate.rate),
            rateUnit: currentRate.rateunit,
        });
    }
    handleProjectChange(event) {
        this.setState({project: event.target.value});
    }
    handlePaymentModeChange(event) {
        this.setState({paymentMode: event.target.value});
    }
    handleCheckbox() {
        this.setState({roundTrip: !this.state.roundTrip});
    }
    handleRecalculateLocationSwitchChange(checked) {
        this.setState({recalculateLocation: checked});
    }
    handleSwitchChange(checked) {
        if (checked) {
            this.state.locMarkers.forEach((loc) => {
                loc.setMap(this.state.map);
            });
        } else {
            this.state.locMarkers.forEach((loc) => {
                loc.setMap(null);
            });
        }
        this.setState({showLocations: checked});
    }
    pinStartLoc() {
        if (this.state.pinningDestinationLoc) {
            this.setState({pinningDestinationLoc: false});
        }
        this.setState({pinningStartLoc: !this.state.pinningStartLoc});
    }
    pinDestinationLoc() {
        if (this.state.pinningStartLoc) {
            this.setState({pinningStartLoc: false});
        }
        this.setState({pinningDestinationLoc: !this.state.pinningDestinationLoc});
    }
    handleDestinationLocTextChange(event) {
        this.setState({destinationLocText: event.target.value});
    }
    handleStartLocTextChange(event) {
        this.setState({startLocText: event.target.value});
    }
    handleDateChangeWithEvent(momentOrStringVal, id, isTime) {
        const momentFormat = isTime ? 'HH:mm' : 'DD/MM/YYYY';
        const synthEvent = {
            target: {
                id,
                value: momentOrStringVal.format ? momentOrStringVal.format(momentFormat) : momentOrStringVal,
            }
        }
        this.props.handleChange(synthEvent);
    }
    handleSave() {
        const supplementaryFields = {};
        if (this.props.mileageFields.fromlat) {
            // if there is fromlat, there will be fromlng, tolat, tolng as well
            // this means that data was previously set by a saved mileage expense item
            supplementaryFields.fromlat = this.props.mileageFields.fromlat;
            supplementaryFields.fromlng = this.props.mileageFields.fromlng;
            supplementaryFields.tolat = this.props.mileageFields.tolat;
            supplementaryFields.tolng = this.props.mileageFields.tolng;
        } else {
            supplementaryFields.fromlat = this.state.pinnedStartLoc ? this.props.travelLocStartMarker.position.lat() : this.props.travelLocStart.geometry.location.lat();
            supplementaryFields.fromlng = this.state.pinnedStartLoc ? this.props.travelLocStartMarker.position.lng() : this.props.travelLocStart.geometry.location.lng();
            supplementaryFields.tolat = this.state.pinnedDestinationLoc ? this.props.travelLocDestinationMarker.position.lat() : this.props.travelLocDestination.geometry.location.lat();
            supplementaryFields.tolng = this.state.pinnedDestinationLoc ? this.props.travelLocDestinationMarker.position.lng() : this.props.travelLocDestination.geometry.location.lng();
        }

        const startLocName = this.state.startLocText || this.props.travelLocStartMarker.title;
        const destinationLocName = this.state.destinationLocText || this.props.travelLocDestinationMarker.title;


        let claimableAmount = Math.round((this.state.distance / 1000 * this.state.rate) * 100); // store as cents
        if (this.state.roundTrip) {
            claimableAmount = claimableAmount * 2;
        }
        const data = Object.assign(supplementaryFields, {
            expensedate: this.props.mileageFields.expensedate,
            traveltimestart: document.getElementById('traveltimestart').value,
            traveltimeend: document.getElementById('traveltimeend').value,
            project: this.props.projects[document.getElementById('project').value].code,
            category: this.props.mileageCategories[document.getElementById('category').value].code,
            paymentmode: this.props.paymentModes[document.getElementById('paymentmode').value].code,
            remarks: document.getElementById('remarks').value,
            roundTrip: this.state.roundTrip,
            amount: claimableAmount,
            from: startLocName,
            to: destinationLocName,
            distance: this.state.distance,
        });
        // console.log(this.props.mileageFields);
        // console.log(data);
        this.props.handleSave(data);
    }
    okToSave() {
        const setLocation = this.state.distance === null ? false : true;
        const setProject = this.state.project === null ? false : true;
        const setPaymentMode =  this.state.paymentMode === null ? false : true;
        const setTravelMode =  this.state.rate === 0 ? false : true;

        // console.log("setProject: " , setProject);
        // console.log("setPaymentMode: " , setPaymentMode);
        // console.log("setTravelMode: " , setTravelMode);
        return !(setLocation && setProject && setPaymentMode && setTravelMode);
    }
    toggleAction() {
        this.handleToggleModal();
        if (this.props.modalAction === "refresh") {
            this.props.getGroupDetails(this.props.match.params.receiptid);
        } else if (this.props.modalAction === "redirect") {
            history.push("/expense/myreceipts");
        }
    }
    calcCurrentPositiveButtonText() {
        if (this.props.modalAction === "save") {
            return "Save";
        } else if (this.props.modalAction === "delete") {
            return "Delete";
        } else if (this.props.modalAction === "redirect") {
            return "Ok";
        } else if (this.props.modalAction === "close") {
            return "Ok";
        } else {
            return "Ok"
        }
    }
    calcCurrentPositiveButtonAction() {
        if (this.props.modalAction === "save") {
            return this.handleSave;
        } else if (this.props.modalAction === "delete") {
            return this.props.handleDelete;
        } else if (this.props.modalAction === "close") {
            return this.handleToggleModal;
        } else if (this.props.modalAction === "redirect") {
            return () => {
                history.push("/expense/myreceipts");
                if (this.props.refreshList) {
                    this.props.refreshList();
                }
                this.handleToggleModal();
            };
        } else {
            return this.handleToggleModal;
        }
    }
    handleToggleModal(modalAction) {
        this.props.toggleModal(modalAction);
    }
    render() {
        const hasQs = this.state.theqs !== null && Object.keys(this.state.theqs).length !== 0;
        // TODO: transltae
        // for translate later
        const style = {
            height: "100%",
            width: "100%",
            minHeight: "400px",
        }
        let distanceText = (this.state.distance / 1000) + " km ";
        if (this.state.roundTrip) {
            distanceText = distanceText + "one way";
        }
        let claimableAmount = (this.state.distance / 1000 * this.state.rate);
        if (this.state.roundTrip) {
            claimableAmount = claimableAmount * 2;
        }
        const claimableAmountText = this.state.rate === 0 ? "Please select Travel Mode" : "S$" + claimableAmount.toFixed(2);
        const calcDetails = 
            <div>
                Current Rate: S${this.state.rate.toFixed(2)}/ {this.state.rateunit}
                <br/>
                <br/>
                {this.state.distance ? <div>Your Trip: <h5><b>{distanceText}</b></h5></div> : null}
                {this.state.distance ? <div>Your Claimable Amount: <h5><b>{claimableAmountText}</b></h5></div> : null}
            </div>

        /*
        const travelLocMarkerStart = 
            <Hoverable 
                title={this.props.travelLocStart.title}
                lat={this.props.travelLocStart.lat}
                lng={this.props.travelLocStart.lng}
            />
        const travelLocMarkerDestination = 
            <Hoverable 
                title={this.props.travelLocDestination.title}
                lat={this.props.travelLocDestination.lat}
                lng={this.props.travelLocDestination.lng}
            />
        */
        return (
            <div>
                {this.props.loading ? <LoadingSpinner/> : (
                    <Container key={0}>
                        <Row className="mb-2">
                            <Col xs={12} sm={3}>
                                <Form>
                                    <Label>
                                        Travel Date
                                    </Label>
                                    <FormGroup row>
                                        <Col className="pr-0 mr-0" sm={12}>
                                            <CyderDatePicker
                                                value={this.props.mileageFields.expensedate}
                                                placeholder="DD/MM/YYYY"
                                                onChange={(event) => this.handleDateChangeWithEvent(event, 'expensedate')}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <Label>
                                        From
                                    </Label>
                                    <FormGroup>
                                        <InputGroup>
                                            <SearchBox
                                                setLoc={(loc) => this.setStartLocMarker(loc)}
                                                inputProps={{
                                                    className: "form-control",
                                                    placeholder: "Starting Location",
                                                    onChange: this.handleStartLocTextChange,
                                                    value: this.state.startLocText
                                                }}
                                            />
                                            <InputGroupAddon addonType="append">
                                                <Button
                                                    onClick={this.pinStartLoc}
                                                    color={this.state.pinningStartLoc ? "primary" : "secondary"}
                                                >
                                                    <i className="align-middle material-icons">
                                                        my_location
                                                    </i>
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>
                                            Project
                                        </Label>
                                        <Input
                                            id="project"
                                            onChange={(event) => this.handleProjectChange(event)}
                                            type="select"
                                        >
                                            <option value={null}>Select Project</option>
                                            {this.props.projects.map((each, i) => {
                                                return (
                                                    <option key={i} value={i}>{each.name}</option>
                                                )
                                            })}
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>
                                            Travel Mode
                                        </Label>
                                        <Input
                                            id="category"
                                            onChange={(event) => this.handleMileageCategoryChange(event)}
                                            type="select"
                                        >
                                            <option value={null}>Select Travel Mode</option>
                                            {this.props.mileageCategories.map((each, i) => {
                                                return (
                                                    <option key={i} value={i}>{each.name}</option>
                                                )
                                            })}
                                        </Input>
                                    </FormGroup>
                                </Form>
                                {calcDetails}
                                <Button
                                    disabled={this.okToSave() || this.props.mileageFields.status !== "Saved" }
                                    onClick={() => this.handleToggleModal("save")}
                                >
                                    Save
                                </Button>
                                <Link to='/expense/myreceipts/new'>
                                    <Button
                                        className="ml-2"
                                        color="danger"
                                    >
                                        Cancel
                                    </Button>
                                </Link>
                            </Col>
                            <Col xs={12} sm={3}>
                                <Form>
                                    <Label>
                                        Travel Time
                                    </Label>
                                    <FormGroup row>
                                        <Col sm={6}>
                                            <CyderDatePicker
                                                id="traveltimestart"
                                                value={this.props.mileageFields.traveltimestart}
                                                onChange={(event) => this.handleDateChangeWithEvent(event, 'traveltimestart', true)}
                                                dateFormat={false}
                                                placeholder="HH:mm"
                                                timeFormat="HH:mm"
                                            />
                                        </Col>
                                        <Col sm={6}>
                                            <CyderDatePicker
                                                id="traveltimeend"
                                                value={this.props.mileageFields.traveltimeend}
                                                onChange={(event) => this.handleDateChangeWithEvent(event, 'traveltimeend', true)}
                                                dateFormat={false}
                                                placeholder="HH:mm"
                                                timeFormat="HH:mm"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <Label>
                                        To
                                    </Label>
                                    <FormGroup>
                                        <InputGroup>
                                            <SearchBox
                                                setLoc={(loc) => this.setDestinationLocMarker(loc)}
                                                inputProps={{
                                                    className: "form-control",
                                                    placeholder: "Destination Location",
                                                    onChange: this.handleDestinationLocTextChange,
                                                    value: this.state.destinationLocText
                                                }}
                                            />
                                            <InputGroupAddon addonType="append">
                                                <Button
                                                    onClick={this.pinDestinationLoc}
                                                    color={this.state.pinningDestinationLoc ? "primary" : "secondary"}
                                                >
                                                    <i className="align-middle material-icons">
                                                        my_location
                                                    </i>
                                                </Button>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>
                                            Payment Mode
                                        </Label>
                                        <Input
                                            id="paymentmode"
                                            onChange={(event) => this.handlePaymentModeChange(event)}
                                            type="select"
                                        >
                                            <option value={null}>Select Payment Mode</option>
                                            {this.props.paymentModes.map((each, i) => {
                                                return (
                                                    <option key={i} value={i}>{each.name}</option>
                                                )
                                            })}
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>
                                            Purpose
                                        </Label>
                                        <Input
                                            type="textarea"
                                            rows="5"
                                            id="remarks"
                                        />
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label>
                                            <Input
                                                id="roundTrip"
                                                checked={this.state.roundTrip}
                                                type="checkbox"
                                                onChange={this.handleCheckbox}
                                            />{' '}
                                            Round Trip
                                        </Label>
                                    </FormGroup>
                                </Form>
                            </Col>

                            <Col className="px-0 pl-sm-2 pr-sm-0" xs={12} sm={6}>
                                <div>
                                    {hasQs ? (
                                        <FormGroup className="mt-2" row>
                                            <Label sm={10}>Recalculate Location</Label>
                                            <div className="float-right">
                                                <FormGroup>
                                                    <Label>
                                                        <Switch
                                                            onChange={this.handleRecalculateLocationSwitchChange}
                                                            checked={this.state.recalculateLocation}
                                                            uncheckedIcon={null}
                                                            id="tfa"
                                                        />
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        </FormGroup>
                                    ) : null }
                                </div>
                                <div
                                    className={!hasQs || this.state.recalculateLocation ? null : "d-none"}
                                    style={style}
                                >
                                    <GoogleMapReact
                                        resetBoundsOnResize={true}
                                        onGoogleApiLoaded={({map, maps}) => this.initGoogleMaps(map, maps)}
                                        yesIWantToUseGoogleMapApiInternals
                                        bootstrapURLKeys={{
                                            key: "AIzaSyBr4vviN07QGsh6OtH0mvRd9RRWL9YtSWo",
                                        }}
                                        center={{lat: 1.3521, lng: 103.8198}}
                                        defaultZoom={11}
                                    >
                                    </GoogleMapReact>
                                    <FormGroup className="mt-2" row>
                                        <Label sm={10}>Show Locations</Label>
                                        <div className="float-right">
                                            <FormGroup>
                                                <Label>
                                                    <Switch
                                                        onChange={this.handleSwitchChange}
                                                        checked={this.state.showLocations}
                                                        uncheckedIcon={null}
                                                        id="tfa"
                                                    />
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                    <div>
                                        {hasQs && this.props.location.pathname !==  "/expense/myreceipts/new/mileage" ? (
                                            <Button
                                                className="float-right"
                                                color="danger"
                                                disabled={!this.props.allLoaded}
                                                onClick={() => this.handleToggleModal("delete")}
                                            >
                                                Delete
                                            </Button>
                                        ) : null}
                                    </div>
                                </div>
                                <div
                                    className={!hasQs || this.state.recalculateLocation ? "d-none" : null}
                                    style={style}
                                >
                                    {hasQs && this.props.location.pathname !==  "/expense/myreceipts/new/mileage" ? (
                                        <Button
                                            className="float-right"
                                            color="danger"
                                            disabled={!this.props.allLoaded || this.props.mileageFields.status !== "Saved"}
                                            onClick={() => this.handleToggleModal("delete")}
                                        >
                                            Delete
                                        </Button>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                        <DialogModal
                            key="modal"
                            modal={this.props.modalOpen}
                            toggle={this.toggleAction}
                            customHeader={this.props.modalHeader || "Confirmation"}
                            customModalBody={this.props.saving ? <LoadingSpinner/> : this.props.modalMessage}
                            positiveButtonAction={this.calcCurrentPositiveButtonAction()}
                            positiveButtonText={this.calcCurrentPositiveButtonText()}
                            onlyOneButton={this.props.saved}
                            buttonDisabler={this.props.saving}
                        />
                    </Container>
                )}
            </div>
        )
    }
}

/*
const Hoverable = ({title}) => {
    return <div className="hint hint--html hint--info hint--top" >
        <div>{title ? title : "TITLE"}</div>
    </div>
}
*/

const mapStateToProps = (state) => {
    return Object.assign({
        ...state.cyderExpenseNewMileageClaimReducer,
        ...state.cyderExpenseSharedReducer,
    }, {
        loading: state.cyderExpenseNewMileageClaimReducer.loading
    }
    );
}
const mapDispatchToProps = dispatch => {
    return {
        toggleModal: (modalAction) => {
            dispatch(expenseNewMileageClaimToggleModal(modalAction ? modalAction: null));
        },
        handleChange: (event) => {
            const key = event.target.id;
            const value = event.target.value;
            // console.log(approve.value(value, rules));
            dispatch(expenseNewMileageClaimChangeField(key, value));
        },
        handleDelete: () => {
            dispatch(expenseNewMileageClaimDeleteSavedReceipt());
        },
        handleSave: (data) => dispatch(expenseNewMileageClaimSaveMileage(data)),
        setStartLoc: (loc, marker) => dispatch(travelExpenseSetStartLocation(loc, marker)),
        setDestinationLoc: (loc, marker) => dispatch(travelExpenseSetDestinationLocation(loc, marker)),
        setJumbotronTitle: (title) => dispatch(setJumbotronTitle(title || 'New Mileage Claim')),
        removeLocMarkers: (startOrDestination) => dispatch(travelExpenseRemoveMarkers(startOrDestination)),
        setCurrentDirectionsResult: (result) => dispatch(travelExpenseSetCurrentDirectionsResult(result)),
        getRelevantStuff: (receiptid, index) => {
            dispatch(expenseNewMileageClaimForceLoadingState(true));
            const items = [
                dispatch(expenseSharedGetMileageCategories()),
                dispatch(expenseSharedGetLocations()),
                dispatch(expenseSharedGetProjects()),
                dispatch(expenseSharedGetPaymentModes()),
                // dispatch(expenseSharedGetExpenseCategories()),
            ]
            return Promise.all(items).then(() => {
                // will use receipt info from myReceipts list if exists on client
                if (receiptid && index) {
                    return dispatch(expenseNewMileageClaimSetFields(receiptid, index))
                }
            }).then((res) => {
                dispatch(expenseNewMileageClaimForceLoadingState(false));
                dispatch(expenseSharedLoadedData());
                return res;
            });
        },
    }
}
// translate() is if we want to use HOC to perform t()
export default translate()(connect(mapStateToProps, mapDispatchToProps)(StoredLayout(ExpenseMileage)))

