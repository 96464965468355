import {
    CYDER_PROFILE_GET,
    CYDER_PROFILE_SAVE_CHANGES_REQUEST,
    CYDER_PROFILE_SAVE_CHANGES_SUCCESS,
    CYDER_PROFILE_SAVE_CHANGES_FAILURE,
    CYDER_PROFILE_MODAL_TOGGLE,
    CYDER_DEV_CACHE_CURRENT_PROFILE,
    CYDER_DEV_REMOVE_CACHED_PROFILE,
    CYDER_DEV_CACHE_FAKE_PROFILE,
    CYDER_DEV_RECHECK_PROFILE,

} from "../constants/actionTypes";
import withQuery from 'with-query';

import { CYDER_API_ROOT, DJARVIS_API_ROOT } from '../../config';

export function changeFieldValueAction(key, value) {
    return {
        type: CYDER_PROFILE_GET,
        key,
        value,
    }
}

export function cyderDevCacheCurrentProfile() {
    return {
        type: CYDER_DEV_CACHE_CURRENT_PROFILE,
    }
}
export function cyderDevRemoveCachedProfile() {
    return {
        type: CYDER_DEV_REMOVE_CACHED_PROFILE,
    }
}
export function cyderDevCacheFakeProfile() {
    return {
        type: CYDER_DEV_CACHE_FAKE_PROFILE,
    }
}
export function cyderDevRecheckProfile() {
    return {
        type: CYDER_DEV_RECHECK_PROFILE,
    }
}

export function profileSaveAction() {
    return function (dispatch, getState) {
        const setSavingStateAction = {
            type: CYDER_PROFILE_SAVE_CHANGES_REQUEST
        }
        let body = getState().cyderProfileReducer.profile;
        if (body.firstname_lower) delete(body.firstname_lower);
        if (body.lastname_lower) delete(body.lastname_lower);
        if (body.username_lower) delete(body.username_lower);
        dispatch(setSavingStateAction);
        const url = withQuery(CYDER_API_ROOT+'/sec_v1/users', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: "update",
        });
        const options = {
            headers: {
                "x-api-key": getState().config.apiKey,
                "Content-Type": "application/json",
                "Authorization":  getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify(body),
            method: "POST",
        };
        return fetch(url, options).then((response) => {
            if (!response.ok) {
                response.json().then((error) => {
                    dispatch({ type: CYDER_PROFILE_SAVE_CHANGES_FAILURE, error });
                    throw error;
                })
            } else {
                response.json().then((response) => {
                    dispatch({ type: CYDER_PROFILE_SAVE_CHANGES_SUCCESS, response });
                    return response;
                })
            }
        }).catch((error) => {
            console.log('thrown');
            dispatch({ type: CYDER_PROFILE_SAVE_CHANGES_FAILURE, error });
            return error;
        })
    }
}
export function cyderProfileModalToggle(reset) {
    return {
        type: CYDER_PROFILE_MODAL_TOGGLE,
        reset,
    }
}
