import React from 'react';
import PrivateRoute from '../../cyderComponents/common/PrivateRoute'
import AccountsSearchPage from '../accounts/AccountsSearchPage'
import AccountsDetailPage from '../accounts/AccountsDetailPage'
import MyReceiptsPage from '../expenses/receipts/MyReceiptsPage'
import ExpenseNewReceiptPage from '../expenses/receipts/new/ExpenseNewReceiptPage'
import { Switch } from 'react-router'

const CloudAdminPages = () => (
    <Switch>
        <PrivateRoute exact path="/accounts" component={AccountsSearchPage} />
        <PrivateRoute exact path="/accounts/details/:accountid" component={AccountsDetailPage} />
        <PrivateRoute exact path="/autorec/edit" component={ExpenseNewReceiptPage} />
        <PrivateRoute exact path="/autorec" component={MyReceiptsPage} />
    </Switch>
)

export default CloudAdminPages
