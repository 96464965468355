// defaults
var cyderFakeUserData = {
    userData: {
        "idno": "S7962708I",
        "officenumber": "+658888888",
        "mobilenumber": "+6597772291",
        "userstatus": "Active",
        "companyname": "Cyder SG",
        "email": "asdf1@asdf.com",
        "email2": "asdf2@asdf.com",
        "gender": "Male",
        "lastname": "df",
        "password": "B109F3BBBC244EB82441917ED06D618B9008DD09B3BEFD1B5E07394C706A8BB980B1D7785E5976EC049B46DF5F1326AF5A2EA6D103FD07C95385FFAB0CACBC86",
        "dateofbirth": "2017/01/21",
        "homeaddress": "Singapore",
        "firstname": "as",
        "username": "asdf1@asdf.com",
        "appId": "CYDERINTRANET",
        "companyaddress": "Bluehill",
        "homenumber": "+6599999999",
        "idtype": "NRIC",
        "authorizationToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZG5vIjoiUzc5NjI3MDhJIiwib2ZmaWNlbnVtYmVyIjoiKzY1ODg4ODg4OCIsIm1vYmlsZW51bWJlciI6Iis2NTk3NzcyMjkxIiwic3RhdHVzIjoiQWN0aXZlIiwiY29tcGFueW5hbWUiOiJDeWRlciBTRyIsImVtYWlsIjoiZmVybmFuZG9AY3lkZXIuY29tLnNnIiwiZ2VuZGVyIjoiTWFsZSIsImxhc3RuYW1lIjoiS2FybmFnaSIsInBhc3N3b3JkIjoiQjEwOUYzQkJCQzI0NEVCODI0NDE5MTdFRDA2RDYxOEI5MDA4REQwOUIzQkVGRDFCNUUwNzM5NEM3MDZBOEJCOTgwQjFENzc4NUU1OTc2RUMwNDlCNDZERjVGMTMyNkFGNUEyRUE2RDEwM0ZEMDdDOTUzODVGRkFCMENBQ0JDODYiLCJkYXRlb2ZiaXJ0aCI6IjIwMTcvMDEvMjEiLCJob21lYWRkcmVzcyI6IlNpbmdhcG9yZSIsImZpcnN0bmFtZSI6IkZlcm5hbmRvIiwidXNlcm5hbWUiOiJmZXJuYW5kb0BjeWRlci5jb20uc2ciLCJhcHBJZCI6IkNZREVSSU5UUkFORVQiLCJjb21wYW55YWRkcmVzcyI6IlJlZGhpbGwiLCJob21lbnVtYmVyIjoiKzY1OTk5OTk5OTkiLCJpZCI6IjQ1M2M1MWFjLWUzMTYtNGNiNC1hNTk4LTcwNjZhNmIwMzdlNiIsImlkdHlwZSI6Ik5SSUMiLCJpYXQiOjE1MTUwMzg1ODR9.XUgEWfcRx_Zl-OgjkYGTc2XLfR_qq2Eh4z7m1wCQZ6Y"
    },
}

export function cyderFakeUserReducer(state = cyderFakeUserData) {
    return state;
}
