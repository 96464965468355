import {
    CYDER_EXPENSE_MYRECEIPTS_TOGGLE_CHECKBOX,
    CYDER_EXPENSE_MYRECEIPTS_TOGGLE_CHECKBOX_ALL,
    CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_REQUEST,
    CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_SUCCESS,
    CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_FAILURE,
    CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_REQUEST,
    CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_SUCCESS,
    CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_FAILURE,
    CYDER_EXPENSE_MYRECEIPTS_MODAL_TOGGLE,
    CYDER_EXPENSE_MYRECEIPTS_COPY_RECEIPT,
} from "../constants/actionTypes";
import withQuery from 'with-query';
import { CYDER_API_ROOT, DJARVIS_API_ROOT } from '../../config';

export function myReceiptsGetReceipts(status) {
    return (dispatch, getState) => {
        const url = withQuery(DJARVIS_API_ROOT+'/expense_v1/my', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: "getautoreceipts",
        });
        const body = {
            status,
            // userid: "fkarnagi@gmail.com",
            userid: getState().cyderProfileReducer.profile.username,
        }
        const options = {
            method: "POST",
            headers: {
                "x-api-key": getState().config.apiKey,
                "Content-Type": "application/json",
                "Authorization":  getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify(body),
        };
        dispatch({type: CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_REQUEST});

        return fetch(url, options).then((response) => {
            if (!response.ok) {
                return response.json().then((response) => {
                    dispatch({type: CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_FAILURE, response});
                })
            } else {
                return response.json().then((response) => {
                    dispatch({type: CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_SUCCESS, response});
                })
            }
        }).catch((error) => {
            dispatch({type: CYDER_EXPENSE_MYRECEIPTS_GET_RECEIPTS_FAILURE, error});
        });
    }
}
export function myReceiptsDeleteReceipt() {
    return (dispatch, getState) => {
        const url = withQuery(DJARVIS_API_ROOT+'/expense_v1/my', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: "deletereceipt",
        });
        const body = {
            receiptid: getState().cyderExpenseMyReceiptsReducer.receiptIdForDeletion,
            userid: getState().cyderProfileReducer.profile.username,
        }
        const options = {
            method: "POST",
            headers: {
                "x-api-key": getState().config.apiKey,
                "Content-Type": "application/json",
                "Authorization":  getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify(body),
        };

        dispatch({type: CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_REQUEST});

        return fetch(url, options).then((response) => {
            if (!response.ok) {
                return response.json().then((error) => {
                    dispatch({type: CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_FAILURE, error});
                })
            } else {
                return response.json().then((response) => {
                    dispatch({type: CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_SUCCESS, response});
                })
            }
        }).catch((error) => {
            dispatch({type: CYDER_EXPENSE_MYRECEIPTS_DELETE_RECEIPT_FAILURE, error});
        });
    }
}
export function myReceiptsCopyReceipt(index) {
    return {
        type: CYDER_EXPENSE_MYRECEIPTS_COPY_RECEIPT,
        index,
    }
}
export function toggleCheckboxes(id) {
    return {
        type: CYDER_EXPENSE_MYRECEIPTS_TOGGLE_CHECKBOX,
        id,
    }
}
export function toggleCheckboxesAll() {
    return {
        type: CYDER_EXPENSE_MYRECEIPTS_TOGGLE_CHECKBOX_ALL,
    }
}
export function expenseMyReceiptsToggleModal(modalAction, data) {
    return {
        type: CYDER_EXPENSE_MYRECEIPTS_MODAL_TOGGLE,
        modalAction,
        data,
    }
}
