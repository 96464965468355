import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { config } from './config';
import { colors, backgroundColors } from './colors';
import { navigation } from './navigation';
import { pagedata } from './pagedata';
import { reducer as notificationsReducer } from 'reapop';
import { connectRouter } from 'connected-react-router';
import history from '../history';

// Cyder customisation
import { cyderLoginReducer } from './security/cyderLoginReducer';
import { cyderChangePasswordReducer } from './security/cyderChangePasswordReducer';
import { cyderForgotPasswordReducer } from './security/cyderForgotPasswordReducer';
import { cyderLogoutReducer } from './security/cyderLogoutReducer';
import { cyderExpenseReducer } from './expenses/cyderExpenseReducer';
import { cyderSysparamReducer } from './settings/cyderSysparamReducer';
import { cyderAccountsSearchReducer } from './accounts/cyderAccountsSearchReducer';
import { cyderAccountsDetailReducer } from './accounts/cyderAccountsDetailReducer';
import { cyderUsersSearchReducer } from './users/cyderUsersSearchReducer';
import { cyderProfileReducer } from './profile/cyderProfileReducer';
import { cyderFakeUserReducer } from './common/cyderFakeUserReducer';
import { usermenu } from './usermenu';

import { cyderExpenseMyReceiptsReducer } from './expenses/cyderExpenseMyReceiptsReducer';
import { cyderExpenseNewReceiptClaimReducer } from './expenses/cyderExpenseNewReceiptClaimReducer';
import { cyderExpenseSharedReducer } from './expenses/cyderExpenseSharedReducer';

const rootReducer = combineReducers({
    router: connectRouter(history),
    routing: routerReducer,
    config,
    colors,
    backgroundColors,
    navigation,
    cyderLoginReducer,
    cyderChangePasswordReducer,
    cyderForgotPasswordReducer,
    cyderLogoutReducer,
    cyderExpenseReducer,
    cyderSysparamReducer,
    cyderAccountsSearchReducer,
    cyderAccountsDetailReducer,
    cyderUsersSearchReducer,
    cyderProfileReducer,
    cyderFakeUserReducer,
    cyderExpenseMyReceiptsReducer,
    cyderExpenseNewReceiptClaimReducer,
    cyderExpenseSharedReducer,
    usermenu,
    notifications: notificationsReducer(),
    pagedata: pagedata,
});
export default rootReducer;
