import React, {Component} from 'react'
import {connect} from 'react-redux'
// import {ConnectedRouter} from 'react-router-redux'
import history from '../../history'
// import NotificationsSystem from 'reapop'

import Widget from '../../elements/DashboardWidget'
import HomeTableWidget from '../../table-widgets/HomeTableWidget'
import AccountsSearchPage from '../../pages/accounts/AccountsSearchPage'

class HomePage extends Component {
  render = () => {  
    return ( 
        <AccountsSearchPage/>
    )
      {/*
      <div>
        <div className="row">
          <div className="col-12">
            <Widget
              title="What's Happening"
              description="Look-up for what is happening, get the most up-to-date information, chill-out">
              <HomeTableWidget />
            </Widget>
          </div>
        </div>
      </div>
    ) 
    */}
  }
}


const mapStateToProps = (state, ownProps) => {
  return {}
}
const mapDispatchToProps = dispatch => {
  return {
    navigateTo: (url) => {
      // This function ONLY does the binding. Validation should be done in the reducer
      history.push(url);
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
