import EmptyView1 from '../../layouts/EmptyView1'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    Alert,
    Button,
    Row,
    Col,
    Card,
    CardText,
} from 'reactstrap'
import {
    cyderChangePasswordPageSetMessageAction,
    cyderChangePasswordPageClearMessageAction,
    cyderChangePasswordAction,
    cyderChangePasswordNoUserAction,
    cyderGetResetPasswordInfoAction,
} from '../../actions/security/cyderSecurityAction'
// import { cyderNavigationAction } from '../../actions/security/cyderNavigationAction'
import CyderForm from '../CyderForm'
import CyderInputGroup from '../CyderInputGroup'
import history from '../../history'
import sha512 from 'js-sha512';

class ChangePassword extends React.Component {
    componentDidMount() {
        if (this.props.match.params.token) {
            this.props.getResetPasswordInfo(this.props.match.params.token);
        }
    }
    render() {
        const style = this.props.message ? null : {display:"none"};
        return (
            <CyderForm
                title="Change Password"
                description={this.props.profile.username ? this.props.profile.username :
                    "Please enter and confirm your password"}>
                {this.props.user ?
                    <Row className="mb-2">
                        <Col sm="12">
                            <Card body>
                                <CardText className="text-center">{this.props.user.username}</CardText>
                            </Card>
                        </Col>
                    </Row>
                    : null
                }
                <div className="form-group">
                    <Alert style={style} color={this.props.success ? "success" : "danger"}>
                        {this.props.message ? this.props.message : null}
                    </Alert>
                    <CyderInputGroup field={this.props.fields[0]} />
                    <CyderInputGroup field={this.props.fields[1]} />
                </div>
                <Button
                    hidden={this.props.success}
                    disabled={this.props.requesting}
                    className="btn btn-primary btn-rounded btn-outline"
                    type="submit"
                    onClick={() => this.validateThenSubmit()}
                >
                    Submit
                </Button>
                <Link className="float-right" to="/">
                    Log in
                </Link>
            </CyderForm>
        )
    }

    validateThenSubmit() {
        this.props.clearErrorMessage();

        const password = document.getElementById(this.props.fields[0].id).value;
        const confirmpassword = document.getElementById(this.props.fields[1].id).value;

        if (password === "" || confirmpassword === "") {
            this.props.setMessage("Please enter both fields");
            return false;
        }
        if (password !== confirmpassword) {
            this.props.setMessage("Passwords do not match");
            return false;
        }
        this.props.changePasswordSubmit(password, this.props.match.params.token ? this.props.match.params.token : null);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {...state.cyderChangePasswordReducer,
        profile: state.cyderProfileReducer.profile,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clearErrorMessage: () => {
            dispatch(cyderChangePasswordPageClearMessageAction());
        },
        setMessage: (message) => {
            dispatch(cyderChangePasswordPageSetMessageAction(message));
        },
        changePasswordSubmit: (password, forgotpasswordtoken) => {

            const hashedpw = sha512(password);
            var navigateHome = () => {
                var navigate = '/home';
                history.replace(navigate);
            }
            var navigateLogin = () => {
                var navigate = '/';
                history.replace(navigate);
            }
            let data = {
                password: hashedpw,
            }
            if (forgotpasswordtoken) {
                data.forgotpasswordtoken = forgotpasswordtoken;
                dispatch(cyderChangePasswordNoUserAction(data, navigateHome, navigateLogin));
            } else {
                dispatch(cyderChangePasswordAction(data, navigateHome, navigateLogin));
            }
        },
        getResetPasswordInfo: (forgotpasswordtoken) => {
            dispatch(cyderGetResetPasswordInfoAction(forgotpasswordtoken));
        },
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EmptyView1(ChangePassword))
