/**
 * Menu
 * 
 * Author: Fernando
 */

import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { translate } from 'react-i18next';
import {setConfig} from '../../actions/config'
class Menu extends React.Component {
    constructor(props) {
        super(props)
        this.toggle = this.toggle.bind(this)
        this.state = {
            isOpen: false
        }
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
    render() {
        const { t } = this.props;
        let items = this.props.items
        if (items.items === undefined || items.items.length === 0) {
            if (items.show) {
                return (
                    <li>
                        <Link
                            onClick={this.props.closeSideMenu}
                            to={items.url}
                            className="btn btn-default btn-flat btn-sidebar btn-sidebar-1">
                            <i className="material-icons">{items.icon}</i>
                            <span className="title">{t("sidemenu:" + items.title)}</span>
                            {items.badge && (
                                <span className={`ml-auto ${items.badge.className}`}>
                                    {items.badge.title}
                                </span>
                            )}
                        </Link>
                    </li>
                )
            } else {
                return (
                    <li></li>
                )
            }
        } else {
            if (items.show) {
                return (
                    <li>
                        <a
                            className={
                                this.state.isOpen
                                    ? 'btn btn-default btn-flat btn-sidebar btn-sidebar-1 has-children is-open'
                                    : 'btn btn-default btn-flat btn-sidebar btn-sidebar-1 has-children'
                            }
                            onClick={this.toggle}>
                            <i className="material-icons">{items.icon}</i>
                            <span className="title">{t("sidemenu:" + items.title)}</span>
                            {items.badge && (
                                <span className={`ml-auto ${items.badge.className}`}>
                                    {items.badge.title}
                                </span>
                            )}
                        </a>
                        <ul className="list-unstyled">
                            {items.items.map((item, k) => {
                                if (item.show) {
                                    return (
                                        <li key={k}>
                                            <Link
                                                onClick={this.props.closeSideMenu}
                                                to={item.url}
                                                className="btn btn-default btn-flat btn-sidebar btn-sidebar-2">
                                                <i className="material-icons">{item.icon}</i>
                                                <span className="title">{t("sidemenu:" + item.title)}</span>
                                            </Link>
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li key={k}></li>
                                    )
                                }
                            }
                            )}
                        </ul>
                    </li>
                )
            } else {
                return (
                    <li></li>
                )
            }
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        closeSideMenu: () => dispatch(setConfig("collapsed", false))
    }
}
export default translate()(connect(null, mapDispatchToProps)(Menu))
