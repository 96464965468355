/**
 * Cyder Login Reducer
 * 
 * Author: Fernando
 * 
 */
import {
    // CYDER_LOGIN_REQUEST,
    CYDER_CHANGE_PASSWORD_REQUEST,
    CYDER_CHANGE_PASSWORD_SUCCESS,
    CYDER_CHANGE_PASSWORD_FAILURE,
    CYDER_CHANGE_PASSWORD_PAGE_SET_MESSAGE,
    CYDER_CHANGE_PASSWORD_GET_INFO_REQUEST,
    CYDER_CHANGE_PASSWORD_GET_INFO_SUCCESS,
    CYDER_CHANGE_PASSWORD_GET_INFO_FAILURE,
} from '../../actions/constants/actionTypes'

var cyderChangePasswordIntialState = {
    fields: [
        {
            id: 'password',
            value: '',
            name: 'password',
            icon: 'lock_outline',
            type: 'password',
            errors: [],
            rules: {
                title: 'Password',
                required: true
            }
        },
        {
            id: 'confirmpassword',
            value: '',
            name: 'password',
            icon: 'lock_outline',
            type: 'password',
            errors: [],
            rules: {
                title: 'Confirm Password',
                required: true
            }
        }
    ],
    success: false,
    userData: {},
    requesting: false,
    loginError: false,
    message: null,
    user: null,
}

/**
 * Login reducer
 * 
 * @param {*} state 
 * @param {*} action 
 */
export function cyderChangePasswordReducer(state = cyderChangePasswordIntialState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
    case CYDER_CHANGE_PASSWORD_REQUEST:
        newState.requesting = true;
        return newState;
    case CYDER_CHANGE_PASSWORD_SUCCESS:
        newState.requesting = false;
        newState.success = true;
        newState.message = "Password Changed Successfully";
        return newState;
    case CYDER_CHANGE_PASSWORD_FAILURE:
        newState.requesting = false;
        newState.message = action.message;
        return newState;
    case CYDER_CHANGE_PASSWORD_PAGE_SET_MESSAGE:
        newState.message = action.message;
        return newState;
    case CYDER_CHANGE_PASSWORD_GET_INFO_REQUEST:
        newState.requesting = true;
        return newState;
    case CYDER_CHANGE_PASSWORD_GET_INFO_SUCCESS:
        newState.requesting = false;
        newState.user = action.response;
        return newState;
    case CYDER_CHANGE_PASSWORD_GET_INFO_FAILURE:
        newState.requesting = false;
        newState.message = action.message;
        return newState;
    default:
        return state;
    }
}
