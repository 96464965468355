/**
 * List of URLs
 * 
 * Author: Fernando
 */

const urls = [
  {
    title: 'Administration',
    show: true,
    items: [
      {
        url: '/accounts/',
        icon: 'recent_actors',
        title: 'Accounts',
        show: true,
        items: []
      },
      {
        url: '/autorec/',
        icon: 'receipt',
        title: 'Auto Receipt',
        show: true,
        items: []
      },
    ]
  },
]
export function navigation(state = Array.from(urls), action) {
  switch (action.type) {
    case 'SET_NAVIGATION':
      return Object.assign({}, state)
    default:
      return state
  }
}
