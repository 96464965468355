/**
 * Cyder Logout Reducer
 * 
 * Author: Fernando
 * 
 */
import { CYDER_LOGOUT } from '../../actions/constants/actionTypes' 

/**
 * Logout reducer
 * 
 * @param {*} state 
 * @param {*} action 
 */
export function cyderLogoutReducer(state = {}, action) {
  switch (action.type) {
    case CYDER_LOGOUT:
      console.log("Perform user logout with fields: ", action.fields);   
      let fields = action.fields;
      // let valid = true;
      fields[0].value = '';
      fields[1].value = '';

      // Clone state as another object
      return Object.assign({}, state);
    default:
      return state
  }
}