import React from 'react';
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';

class SearchBox extends React.Component {
    render() {
        return <input placeholder={this.props.placeholder} ref="input" {...this.props.inputProps} type="text"/>;
    }
    print(s) {
        console.log(s);
    }
    onPlacesChanged() {
        const places = this.searchBox.getPlaces();
        this.props.setLoc(places[0]);
    }
    componentDidMount() {
        var input = ReactDOM.findDOMNode(this.refs.input);
        this.searchBox = new window.google.maps.places.SearchBox(input);
        this.searchBox.addListener('places_changed', () => this.onPlacesChanged(this.searchBox));
    }
    componentWillUnmount() {
        // https://developers.google.com/maps/documentation/javascript/events#removing
        window.google.maps.event.clearInstanceListeners(this.searchBox);
    }
}

const mapStateToProps = (state) => {
    return {
    }
}
const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox)
