import React from 'react'
import { connect } from 'react-redux'
// import history from '../../history'
import {
    // Input,
    // Table,
    Button,
    // Container,
    // Row,
    // Col,
    // Form,
    // FormGroup,
    // Label,
    // FormFeedback,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";


class DialogModal extends React.Component {
    closeModalAfterPositiveAction() {

    }
    render() {
        const positiveButtonAction = this.props.positiveButtonAction ? this.props.positiveButtonAction : this.props.toggle;
        const positiveButtonText = this.props.positiveButtonText ? this.props.positiveButtonText : "Ok";

        const negativeButtonAction = this.props.negativeButtonAction ? this.props.negativeButtonAction : this.props.toggle;
        const negativeButtonText = this.props.negativeButtonText ? this.props.negativeButtonText : "Cancel";

        return (
            <Modal size={this.props.size || "md"} isOpen={this.props.modal} toggle={this.props.toggle} className={this.props.className}>
                <ModalHeader toggle={this.props.toggle}>{this.props.customHeader}</ModalHeader>
                <ModalBody>
                    {this.props.customModalBody}
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.props.buttonDisabler} color="primary" onClick={positiveButtonAction}>{positiveButtonText}</Button>{' '}
                    {!this.props.onlyOneButton ?
                        <Button disabled={this.props.buttonDisabler} color="secondary" onClick={negativeButtonAction}>{negativeButtonText}</Button> :
                        null
                    }
                </ModalFooter>
            </Modal>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        ownProps
    }
}
const mapDispatchToProps = () => {
    return {
    }
}

// translate() is if we want to use HOC to perform t()
export default (connect(mapStateToProps, mapDispatchToProps)(DialogModal))
