import React from 'react'
import Logo from '../logo' 
import ToggleLayout1 from './ToggleLayout1'
import ToggleLayout2 from './ToggleLayout2' 
// import DropdownGrid from './DropdownGrid' 
import DropdownUser from './DropdownUser'
// import DropdownFlags from './DropdownFlags'
import DevNav from '../../cyderComponents/common/DevNav'
import '../../css/elements/navbar-1.css'

const Navbar1 = ({persistor}) => (
  <nav className="navbar navbar-1 d-flex justify-content-around align-items-center flex-nowrap">
    <Logo />
    <ToggleLayout1 />
    <ToggleLayout2 />
    <DevNav persistor={persistor}/>
    {/*
    <ul className="nav nav-inline nav-inline-1">
      <li className="nav-item nav-item-dropdown">
        <a className="nav-link">
          <i className="material-icons">fullscreen</i>
        </a>
        <DropdownGrid />
      </li>
    </ul>
    */}
    <div className="separator" />
    <ul className="nav nav-inline nav-inline-2">
      <li className="nav-item nav-item-dropdown">
        <a className="nav-link nav-link-avatar">
          <span className="badge badge-sm badge-rounded badge-warning">1</span>
          <img
            src="/assets/faces/m7.png"
            className="rounded-circle"
            alt="avatar"
          />
        </a>
        <DropdownUser />
      </li>
      <li style={{width:'0px'}} className="nav-item nav-item-dropdown">
        <a style={{width:'0px'}} className="nav-link">
          <span style={{width:'0px'}} className="flag flag-icon-background flag-icon flag-icon-gb" />
        </a>
        {/* <DropdownFlags /> */}
      </li>
    </ul>
  </nav>
)

export default Navbar1
