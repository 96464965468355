import {
    CYDER_SYSPARAMS_GET,
    CYDER_SYSPARAMS_GET_SUCCESS,
    CYDER_SYSPARAMS_SAVE_VALUE,
    CYDER_SYSPARAMS_SAVE_VALUE_SUCCESS,

    CYDER_SYSPARAMS_PAGE_MODAL_OPEN,
    CYDER_SYSPARAMS_PAGE_MODAL_CLOSE,
    CYDER_SYSPARAMS_PAGE_LOADING,
    CYDER_SYSPARAMS_PAGE_MODAL_TOGGLE,
    CYDER_SYSPARAMS_PAGE_MODAL_SET_SYSPARAM,
    CYDER_SYSPARAMS_PAGE_MODAL_CHANGE_VALUE,
    CYDER_SYSPARAMS_SAVE_VALUE_SAVING,
} from '../../actions/constants/actionTypes'

// defaults
var cyderSysparams = {
    editModalOpen: false,
    editModalSysparamVal: null,
    editModalSysparamCode: null,
    editModalSysparamLabel: null,
    savingSysparam: false,
    loading: false,
    sysparams: []
}

export function cyderSysparamReducer(state = cyderSysparams, action) {
    let newState = Object.assign({}, state);
    // need to clone every layer. for now, only sysparams array will
    // have issues. if sysparams array is modified and we want to
    // see dynamic changes, we have to clone
    // the sysparam array itself with:
    // newState.sysparams = state.sysparams.concat();
    switch (action.type) {
        case CYDER_SYSPARAMS_PAGE_MODAL_SET_SYSPARAM:
            newState.editModalSysparamVal = action.newValue;
            newState.editModalSysparamCode = action.code;
            newState.editModalSysparamLabel = action.label;
            return newState;
        case CYDER_SYSPARAMS_PAGE_MODAL_TOGGLE:
            newState.editModalOpen = !state.editModalOpen;
            return newState;
        case CYDER_SYSPARAMS_PAGE_MODAL_OPEN:
            newState.editModalOpen = true;
            return newState;
        case CYDER_SYSPARAMS_PAGE_MODAL_CLOSE:
            newState.editModalOpen = false;
            return newState;
        case CYDER_SYSPARAMS_PAGE_LOADING:
            newState.loading = true;
            return newState;
        case CYDER_SYSPARAMS_SAVE_VALUE:
            // TODO
            return state;
        case CYDER_SYSPARAMS_SAVE_VALUE_SUCCESS:
            // TODO
            return state;
        case CYDER_SYSPARAMS_PAGE_MODAL_CHANGE_VALUE:
            newState.editModalSysparamVal = action.newValue
            return newState;
        case CYDER_SYSPARAMS_GET:
            newState.loading = true;
            return newState;
        case CYDER_SYSPARAMS_GET_SUCCESS:
            newState.savingSysparam = false;
            newState.loading = false;
            newState.sysparams = action.response;
            return newState;
        case CYDER_SYSPARAMS_SAVE_VALUE_SAVING:
            newState.savingSysparam = true;
            return newState;
        default:
            return state;
    }
}