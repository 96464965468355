import {
    CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_REQUEST,
    CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_SUCCESS,
    CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_FAILURE,
    CYDER_ACCOUNTS_SEARCH_SUCCESS,
    CYDER_ACCOUNTS_SEARCH_REQUEST,
    CYDER_ACCOUNTS_SEARCH_FAILURE,
    CYDER_ACCOUNTS_DETAIL_APPROVE_SUCCESS,
    CYDER_ACCOUNTS_SEARCH_SET_FORCE_REFRESH_FLAG,
} from "../actionTypes";
import withQuery from 'with-query';

import { CYDER_API_ROOT, DJARVIS_API_ROOT } from '../../config';

/**
 * Security action creators
 * 
 * Author: Fernando
 */

/**
 * Login function
 * 
 * @param {*} fields 
 * @param {*} callback 
 */
export function cyderAccountsSearchAction(body) {
    return (dispatch, getState) => {
        dispatch({
            type: CYDER_ACCOUNTS_SEARCH_REQUEST,
            prevSearchBody: body,
        })
        dispatch({ type: CYDER_ACCOUNTS_DETAIL_APPROVE_SUCCESS, val: false })

        const url = withQuery(CYDER_API_ROOT + '/account_v1/account', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: "searchbysystem",
        });
        const options = {
            body: JSON.stringify(body),
            method: "POST",
            headers: {
                "x-api-key": getState().config.apiKeyCyder,
                "Content-Type": "application/json",
                "Authorization":  getState().cyderProfileReducer.profile.authorizationToken,
            }
        };
        return fetch(url, options).then((response) => {
            if (!response.ok) {
                response.json().then((error) => {
                    dispatch({ type: CYDER_ACCOUNTS_SEARCH_FAILURE, error })
                    throw error;
                })
            } else {
                response.json().then((response) => {
                    dispatch({ type: CYDER_ACCOUNTS_SEARCH_SUCCESS, response })
                    dispatch({ type: CYDER_ACCOUNTS_SEARCH_SET_FORCE_REFRESH_FLAG, val: true })
                    return response;
                })
            }
        }).catch((error) => {
            dispatch({ type: CYDER_ACCOUNTS_SEARCH_FAILURE, error })
            return error;
        });
    }
}
export function cyderAccountsSearchGetSystemList() {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_REQUEST })

        const url = withQuery(CYDER_API_ROOT + '/account_v1/account', {
            appid: "cloudadmin",
            action: "searchsystem",
        });
        const options = {
            method: "GET",
            headers: {
                "x-api-key": getState().config.apiKeyCyder,
                "Authorization":  getState().cyderProfileReducer.profile.authorizationToken,
            }
        };
        return fetch(url, options).then((response) => {
            if (!response.ok) {
                response.json().then((error) => {
                    dispatch({ type: CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_FAILURE, error })
                    throw error;
                })
            } else {
                response.json().then((response) => {
                    dispatch({ type: CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_SUCCESS, response })
                    return response;
                })
            }
        }).catch((error) => {
            dispatch({ type: CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_FAILURE, error })
            return error;
        });
    }
}

