/**
 * Common Date Picker
 * 
 * Author: Fernando
 */

import React from 'react'
import Datetime from 'react-datetime'
import '../../css/forms/react-datetime.css'

const CyderDatePicker = ({
    id,
    onChange,
    value,
    valid,
    placeholder,
    dateFormat,
    timeFormat,
}) => {
    let className = "form-control"
    if (valid === true) {
        className = className + " is-valid";
    } else if (valid === false) {
        className = className + " is-invalid";
    }
    return (
        <Datetime
            value={value}
            inputProps={{id, className:className, placeholder: placeholder}}
            dateFormat={dateFormat !== undefined ? dateFormat : "DD/MM/YYYY"}
            timeFormat={timeFormat !== undefined ? timeFormat : false}
            closeOnSelect={true}
            input={true}
            viewMode={'days'}
            onChange={onChange}
        />
    )}

export default CyderDatePicker
