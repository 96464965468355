import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router';
import history from './history';
import NotificationsSystem from 'reapop';
import theme from './notifications/reapop/reapop-theme-bootstrap';

// global css
import 'bootstrap/dist/css/bootstrap.css';
import './css/main.css';
import './css/forms/default-forms.css';
import 'card-react/lib/card.css';
// cyder custom
import './css/custom.css';

// react-bootstrap table
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import 'react-table/react-table.css';
import './css/react-table-custom.css';

//structural elements
import LeftSidebar from './elements/left-sidebar';
import Navbar1 from './elements/navbar-1';
import TopNavigation1 from './elements/top-navigation-1';
import Jumbotron from './elements/jumbotron';
import Backdrops from './elements/backdrops';

// css stuff
import './css/icons/flags.css';
import './css/icons/font-awesome.css';
import './css/icons/ionicons.css';
import './css/icons/material-design-icons.css';
import './css/icons/simple-line-icons.css';
import './css/icons/weather-icons.css';

import './css/ui-elements/alerts.css';
import './css/ui-elements/modals.css';

import './css/ui-elements/badges.css';
import './css/ui-elements/breadcrumbs.css';
import './css/ui-elements/buttons.css';
import './css/ui-elements/images.css';
import './css/ui-elements/lists.css';
import './css/ui-elements/pagination.css';
import './css/ui-elements/progress-bars.css';
import './css/ui-elements/tabs.css';
import './css/ui-elements/typography.css';
import './css/ui-elements/tooltips.css';

import Routes from './Routes';

class App extends Component {
    render() {
        let { layout, background, navbar, topNavigation, logo, leftSidebar, collapsed } = this.props.config;
        let isEmptyView = layout === 'empty-view-1' ? true : false;
        return (
            <ConnectedRouter history={history}>
                <div
                    data-layout={layout}
                    data-background={background}
                    data-navbar={navbar}
                    data-top-navigation={topNavigation}
                    data-logo={logo}
                    data-left-sidebar={leftSidebar}
                    data-collapsed={collapsed}
                >
                    <NotificationsSystem theme={theme} />
                    <Backdrops />
                    <Navbar1 persistor={this.props.persistor} />
                    <TopNavigation1 />
                    <Switch>
                        <Route
                            render={props => <RouteRest isEmptyView={isEmptyView} jumbotronText={this.props.jumbotronText} {...props} />}
                        />
                    </Switch>
                </div>
            </ConnectedRouter>
        );
    }
}

const RouteRest = props => {
    const { isEmptyView, jumbotronText } = props;
    return (
        <div className={isEmptyView ? '' : 'container-fluid'}>
            <div className={isEmptyView ? '' : 'row'}>
                <LeftSidebar />
                <div className="col main">
                    <Jumbotron jumbotronText={jumbotronText} />
                    <Routes />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        config: state.config,
        jumbotronText: state.pagedata.jumbotronText,
    };
};
export default connect(mapStateToProps)(App);
