import React from 'react'
import { Link } from 'react-router-dom'
import '../../css/elements/logo.css'
import { translate } from 'react-i18next';

const Logo = (props) => {
    const { t } = props;
    return (
        <Link
            to="/home"
            className="logo d-flex justify-content-start align-items-center flex-nowrap">
            <i className="fa fa-code" />
            <span className="title">{t("sidemenu:Cyder Cloud Admin")}</span>
        </Link>
    )
}

export default translate()(Logo)
