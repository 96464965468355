import {
    CYDER_ACCOUNTS_DETAIL_REQUEST,
    CYDER_ACCOUNTS_DETAIL_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_FAILURE,
    CYDER_ACCOUNTS_DETAIL_CHANGE_FIELD,
    CYDER_ACCOUNTS_DETAIL_MODAL_TOGGLE,
    CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_REQUEST,
    CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_FAILURE,
    CYDER_ACCOUNTS_DETAIL_APPROVE_REQUEST,
    CYDER_ACCOUNTS_DETAIL_APPROVE_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_APPROVE_FAILURE,
    CYDER_ACCOUNTS_DETAIL_REJECT_REQUEST,
    CYDER_ACCOUNTS_DETAIL_REJECT_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_REJECT_FAILURE,
    CYDER_ACCOUNTS_DETAIL_DELETE_REQUEST,
    CYDER_ACCOUNTS_DETAIL_DELETE_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_DELETE_FAILURE,
    CYDER_ACCOUNTS_DETAIL_RESET_PASSWORD_REQUEST,
    CYDER_ACCOUNTS_DETAIL_RESET_PASSWORD_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_RESET_PASSWORD_FAILURE,
    CYDER_ACCOUNTS_DETAIL_ARCHIVE_REQUEST,
    CYDER_ACCOUNTS_DETAIL_ARCHIVE_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_ARCHIVE_FAILURE,
    CYDER_ACCOUNTS_DETAIL_SUSPEND_REQUEST,
    CYDER_ACCOUNTS_DETAIL_SUSPEND_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_SUSPEND_FAILURE,
    CYDER_ACCOUNTS_DETAIL_ACTIVATE_REQUEST,
    CYDER_ACCOUNTS_DETAIL_ACTIVATE_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_ACTIVATE_FAILURE,
    CYDER_ACCOUNTS_DETAIL_CHANGE_CC_REQUEST,
    CYDER_ACCOUNTS_DETAIL_CHANGE_CC_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_CHANGE_CC_FAILURE,
    CYDER_ACCOUNTS_DETAIL_REMOVE_CC_REQUEST,
    CYDER_ACCOUNTS_DETAIL_REMOVE_CC_SUCCESS,
    CYDER_ACCOUNTS_DETAIL_REMOVE_CC_FAILURE,
} from '../../actions/actionTypes' 
import cyderlib from '../../js/cyderlib'

// defaults
var initialState = {
    loading: false,
    saving: false,
    saved: false,
    errorMessage: null,
    account: {},
    validation: {},
    changesMade: false,
    saveButtonPressed: false,
    modalOpen: false,
    modalMessage: "Are you sure you want to update this account?",
    customModalBody: false,
    refreshSearch: false,
}

const generateRequestNewState = () => {
    let newState = {}
    newState.saving = true;
    return newState;
}
const generateSuccessNewState = (action, defaultMsg) => {
    let newState = {}
    newState.saving = false;
    newState.modalMessage = action.response.message? action.response.message : "Account successfully " + defaultMsg;
    newState.saved = true;
    newState.modalAction = "refresh";
    return newState;
}
const generateFailureNewState = (action) => {
    let newState = {}
    const errPrefix = "Failed to suspend account: ";
    const errMsg = action.error.errdescription? action.error.errdescription : "An error occured";
    newState.modalMessage = errPrefix + errMsg;
    newState.saving = false;
    newState.saved = true;
    newState.modalAction = "close";
    return newState;
}
export function cyderAccountsDetailReducer(state = initialState, action) {
    let newState = Object.assign({}, state);
    let errPrefix, errMsg;
    switch (action.type) {
        case CYDER_ACCOUNTS_DETAIL_REQUEST:
            newState.loading = true;
            newState.errorMessage = null;
            newState.saved = false;
            return newState;
        case CYDER_ACCOUNTS_DETAIL_SUCCESS:
            newState.loading = false;
            newState.account = action.response;
            return newState;
        case CYDER_ACCOUNTS_DETAIL_FAILURE:
            newState.loading = false;
            newState.errorMessage = action.error.message;
            return newState;
        case CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_REQUEST:
            newState.saving = true;
            return newState;
        case CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_SUCCESS:
            newState.modalMessage = "Account successfully updated";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_ACCOUNTS_DETAIL_SAVE_CHANGES_FAILURE:
            newState.modalMessage = "Failed to update account : " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_ACCOUNTS_DETAIL_APPROVE_REQUEST:
            newState.saving = true;
            return newState;
        case CYDER_ACCOUNTS_DETAIL_APPROVE_SUCCESS:
            newState.refreshSearch = true;
            newState.modalMessage = "Account successfully approved";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_ACCOUNTS_DETAIL_APPROVE_FAILURE:
            newState.modalMessage = "Failed to approve account : " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_ACCOUNTS_DETAIL_REJECT_REQUEST:
            newState.refreshSearch = true;
            newState.saving = true;
            return newState;
        case CYDER_ACCOUNTS_DETAIL_REJECT_SUCCESS:
            newState.modalMessage = "Account successfully rejected";
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "redirect";
            return newState;
        case CYDER_ACCOUNTS_DETAIL_REJECT_FAILURE:
            newState.modalMessage = "Failed to reject account : " + action.error.message;
            newState.saving = false;
            newState.saved = true;
            newState.modalAction = "close";
            return newState;
        case CYDER_ACCOUNTS_DETAIL_DELETE_REQUEST:
            return Object.assign(state, generateRequestNewState());
        case CYDER_ACCOUNTS_DETAIL_DELETE_SUCCESS:
            return Object.assign(state, generateSuccessNewState(action, "deleted"));
        case CYDER_ACCOUNTS_DETAIL_DELETE_FAILURE:
            return Object.assign(state, generateFailureNewState(action));
        case CYDER_ACCOUNTS_DETAIL_SUSPEND_REQUEST:
            return Object.assign(state, generateRequestNewState());
        case CYDER_ACCOUNTS_DETAIL_SUSPEND_SUCCESS:
            return Object.assign(state, generateSuccessNewState(action, "suspended"));
        case CYDER_ACCOUNTS_DETAIL_SUSPEND_FAILURE:
            return Object.assign(state, generateFailureNewState(action));
        case CYDER_ACCOUNTS_DETAIL_ACTIVATE_REQUEST:
            return Object.assign(state, generateRequestNewState());
        case CYDER_ACCOUNTS_DETAIL_ACTIVATE_SUCCESS:
            return Object.assign(state, generateSuccessNewState(action, "activate"));
        case CYDER_ACCOUNTS_DETAIL_ACTIVATE_FAILURE:
            return Object.assign(state, generateFailureNewState(action));
        case CYDER_ACCOUNTS_DETAIL_ARCHIVE_REQUEST:
            return Object.assign(state, generateRequestNewState());
        case CYDER_ACCOUNTS_DETAIL_ARCHIVE_SUCCESS:
            return Object.assign(state, generateSuccessNewState(action, "archived"));
        case CYDER_ACCOUNTS_DETAIL_ARCHIVE_FAILURE:
            return Object.assign(state, generateFailureNewState(action));
        case CYDER_ACCOUNTS_DETAIL_CHANGE_FIELD:
            newState.account = Object.assign({}, state.account);
            newState.validation = Object.assign({}, state.validation);
            newState.account[action.key] = action.value;
            newState.validation[action.key] = cyderlib.validate(action.key, action.value);
            newState.changesMade = true;
            return newState;
        case CYDER_ACCOUNTS_DETAIL_MODAL_TOGGLE:
            newState.modalOpen = !state.modalOpen;
            newState.modalAction = action.modalAction;
            newState.customModalBody = false;
            if (action.modalAction === "update") {
                newState.modalMessage = "Are you sure you want to update this account?";
                newState.saved = false;
            } else if (action.modalAction === "approve") {
                newState.modalMessage = "Are you sure you want to approve this account?";
                newState.saved = false;
            } else if (action.modalAction === "reject") {
                newState.modalMessage = "Are you sure you want to reject this account?";
                newState.saved = false;
            } else if (action.modalAction === "delete") {
                newState.modalMessage = "Are you sure you want to delete this account?";
                newState.saved = false;
            } else if (action.modalAction === "resetpassword") {
                newState.modalMessage = "Are you sure you want to reset the password for the main user registered to this account?";
                newState.saved = false;
            } else if (action.modalAction === "archive") {
                newState.modalMessage = "Are you sure you want to archive this account?";
                newState.saved = false;
            } else if (action.modalAction === "suspend") {
                newState.modalMessage = "Are you sure you want to suspend this account?";
                newState.saved = false;
            } else if (action.modalAction === "activate") {
                newState.modalMessage = "Are you sure you want to activate this account?";
                newState.saved = false;
            } else if (action.modalAction === "changeccopen") {
                newState.modalMessage = "";
                newState.customModalBody = true;
                newState.saved = false;
            } else if (action.modalAction === "changeccconfirm") {
                newState.modalOpen = state.modalOpen; // retain state
                newState.modalMessage = "Are you sure you want to replace the current card with this new card?";
                newState.saved = false;
            } else if (action.modalAction === "removecc") {
                newState.modalMessage = "Are you sure you want to remove the current card?";
                newState.saved = false;
            }
            return newState;
        default:
            return state;
    }
}

