/**
 * Cyder Login Reducer
 * 
 * Author: Fernando
 * 
 */
import {
    CYDER_LOGIN_REQUEST,
    CYDER_LOGIN_SUCCESS,
    CYDER_LOGIN_FAILURE,
    CYDER_LOGIN_PAGE_SET_ERROR_MESSAGE,
    CYDER_LOGIN_PAGE_CLEAR_ERROR_MESSAGE,
    CYDER_LOGIN_PAGE_RESET_STATE,
    CYDER_LOGOUT_SUCCESS_REMOVE_LOGGED_IN_FLAG,
} from '../../actions/constants/actionTypes'

var cyderLoginReducerInitialStage = {
    fields: [
        {
            id: 'email',
            value: '',
            name: 'email',
            icon: 'account_circle',
            type: 'text',
            errors: [],
            rules: {
                title: 'Your email',
                required: true
            }
        },
        {
            id: 'password',
            value: '',
            name: 'password',
            icon: 'lock_outline',
            type: 'password',
            errors: [],
            rules: {
                title: 'Your password',
                required: true
            }
        }
    ],
    loggedIn: false,
    userData: {},
    requesting: false,
    loginError: false,
    errorMessage: null,
}

/**
 * Login reducer
 * 
 * @param {*} state 
 * @param {*} action 
 */
export function cyderLoginReducer(state = cyderLoginReducerInitialStage, action) {
    switch (action.type) {
        case CYDER_LOGIN_PAGE_SET_ERROR_MESSAGE:
            state.loginError = true;
            state.errorMessage = action.errorMessage
            return Object.assign({}, state);
        case CYDER_LOGIN_PAGE_CLEAR_ERROR_MESSAGE:
            state.loginError = false;
            state.errorMessage = null;
            return Object.assign({}, state);
        case CYDER_LOGIN_REQUEST:
            state.requesting = true;
            state.loginError = false;
            return Object.assign({}, state);
        case CYDER_LOGIN_SUCCESS:
            // TODO: save auth token,etc
            state.loggedIn = true;
            state.userData = action.userData;
            state.requesting = false;
            return Object.assign({}, state);
        case CYDER_LOGIN_FAILURE:
            state.requesting = false;
            state.loginError = true;
            state.errorMessage = "Error: " + action.res.errdescription;
            return Object.assign({}, state);
        case CYDER_LOGIN_PAGE_RESET_STATE:
            return cyderLoginReducerInitialStage;
        case CYDER_LOGOUT_SUCCESS_REMOVE_LOGGED_IN_FLAG:
            const newState = Object.assign({ loggedIn: false }, state);
            return newState;
        default:
            return state;
    }
}
