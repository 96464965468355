import {
    CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_REQUEST,
    CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_SUCCESS,
    CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_FAILURE,
    CYDER_EXPENSE_SHARED_GET_PROJECTS_REQUEST,
    CYDER_EXPENSE_SHARED_GET_PROJECTS_SUCCESS,
    CYDER_EXPENSE_SHARED_GET_PROJECTS_FAILURE,
    CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_REQUEST,
    CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_SUCCESS,
    CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_FAILURE,
    CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_REQUEST,
    CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_SUCCESS,
    CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_FAILURE,
    CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_REQUEST,
    CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_SUCCESS,
    CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_FAILURE,
    CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_REQUEST,
    CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_SUCCESS,
    CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_FAILURE,
    CYDER_EXPENSE_SHARED_GET_LOCATIONS_REQUEST,
    CYDER_EXPENSE_SHARED_GET_LOCATIONS_SUCCESS,
    CYDER_EXPENSE_SHARED_GET_LOCATIONS_FAILURE,
    CYDER_EXPENSE_SHARED_GET_RELEVANT_ITEMS_SUCCESS,
} from '../constants/actionTypes';
import withQuery from 'with-query';

import { CYDER_API_ROOT, DJARVIS_API_ROOT, DJARVIS_EXPENSE_API_ROOT } from '../../config';

export function expenseSharedGetMileageCategories() {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_REQUEST });
        const url = withQuery(DJARVIS_EXPENSE_API_ROOT + '/mileagecategory', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: 'findall',
        });
        const options = {
            method: 'GET',
            headers: {
                'x-api-key': getState().config.apiKey,
                'Content-Type': 'application/json',
                Authorization: getState().cyderProfileReducer.profile.authorizationToken,
            },
        };
        dispatch({ type: CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_REQUEST });
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_FAILURE, error });
                        return error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_SUCCESS, response });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_EXPENSE_SHARED_GET_MILEAGE_CATEGORIES_FAILURE, error });
                return error;
            });
    };
}
export function expenseSharedGetProjects(appId) {
    return (dispatch, getState) => {
        const url = withQuery(DJARVIS_EXPENSE_API_ROOT + '/project', {
            appid: appId || getState().cyderProfileReducer.profile.appId,
            action: 'findall',
        });
        const options = {
            method: 'GET',
            headers: {
                'x-api-key': getState().config.apiKey,
                'Content-Type': 'application/json',
                Authorization: getState().cyderProfileReducer.profile.authorizationToken,
            },
        };
        dispatch({ type: CYDER_EXPENSE_SHARED_GET_PROJECTS_REQUEST });
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: CYDER_EXPENSE_SHARED_GET_PROJECTS_FAILURE, error });
                        return error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: CYDER_EXPENSE_SHARED_GET_PROJECTS_SUCCESS, response });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_EXPENSE_SHARED_GET_PROJECTS_FAILURE, error });
                return error;
            });
    };
}
export function expenseSharedGetPaymentModes(appId) {
    return (dispatch, getState) => {
        const url = withQuery(DJARVIS_EXPENSE_API_ROOT + '/paymentmode', {
            appid: appId || getState().cyderProfileReducer.profile.appId,
            action: 'findall',
        });
        const options = {
            method: 'GET',
            headers: {
                'x-api-key': getState().config.apiKey,
                'Content-Type': 'application/json',
                Authorization: getState().cyderProfileReducer.profile.authorizationToken,
            },
        };
        dispatch({ type: CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_REQUEST });
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_FAILURE, error });
                        return error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_SUCCESS, response });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_EXPENSE_SHARED_GET_PAYMENT_MODES_FAILURE, error });
                return error;
            });
    };
}
export function expenseSharedGetDashboardItems() {
    return (dispatch, getState) => {
        const url = withQuery(DJARVIS_API_ROOT + '/expense_v1/receipt', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: 'getdashboard',
            userid: getState().cyderProfileReducer.profile.username,
        });
        const options = {
            method: 'GET',
            headers: {
                'x-api-key': getState().config.apiKey,
                'Content-Type': 'application/json',
                Authorization: getState().cyderProfileReducer.profile.authorizationToken,
            },
        };
        dispatch({ type: CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_REQUEST });
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_FAILURE, error });
                        return error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_SUCCESS, response });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_EXPENSE_SHARED_GET_DASHBOARD_ITEMS_FAILURE, error });
                return error;
            });
    };
}
export function expenseSharedGetSettingsItems() {
    return (dispatch, getState) => {
        const url = withQuery(DJARVIS_API_ROOT + '/expense_v1/receipt', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: 'getsettingscount',
            userid: getState().cyderProfileReducer.profile.username,
        });
        const options = {
            method: 'GET',
            headers: {
                'x-api-key': getState().config.apiKey,
                'Content-Type': 'application/json',
                Authorization: getState().cyderProfileReducer.profile.authorizationToken,
            },
        };
        dispatch({ type: CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_REQUEST });
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_FAILURE, error });
                        return error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_SUCCESS, response });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_EXPENSE_SHARED_GET_SETTINGS_COUNT_FAILURE, error });
                return error;
            });
    };
}
export function expenseSharedGetExpenseCategories(appId) {
    return (dispatch, getState) => {
        const url = withQuery(DJARVIS_EXPENSE_API_ROOT + '/expensecategory', {
            appid: appId || getState().cyderProfileReducer.profile.appId,
            action: 'findall',
        });
        const options = {
            method: 'GET',
            headers: {
                'x-api-key': getState().config.apiKey,
                'Content-Type': 'application/json',
                Authorization: getState().cyderProfileReducer.profile.authorizationToken,
            },
        };
        dispatch({ type: CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_REQUEST });
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_FAILURE, error });
                        return error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_SUCCESS, response });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_EXPENSE_SHARED_GET_EXPENSE_CATEGORIES_FAILURE, error });
                return error;
            });
    };
}
export function expenseSharedLoadedData() {
    return dispatch => {
        dispatch({ type: CYDER_EXPENSE_SHARED_GET_RELEVANT_ITEMS_SUCCESS });
    };
}
export function expenseSharedGetLocations() {
    return (dispatch, getState) => {
        const url = withQuery(DJARVIS_EXPENSE_API_ROOT + '/location', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: 'findall',
        });
        const options = {
            method: 'GET',
            headers: {
                'x-api-key': getState().config.apiKey,
                'Content-Type': 'application/json',
                Authorization: getState().cyderProfileReducer.profile.authorizationToken,
            },
        };
        dispatch({ type: CYDER_EXPENSE_SHARED_GET_LOCATIONS_REQUEST });
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: CYDER_EXPENSE_SHARED_GET_LOCATIONS_FAILURE, error });
                        return error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: CYDER_EXPENSE_SHARED_GET_LOCATIONS_SUCCESS, response });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_EXPENSE_SHARED_GET_LOCATIONS_FAILURE, error });
                return error;
            });
    };
}
