import StoredLayout from '../../../../layouts/StoredLayout';
import Switch from 'react-switch';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CyderDatePicker from '../../../../cyderComponents/forms/CyderDatePicker';
import qs from 'query-string';
import Dropzone from 'react-dropzone';
import history from '../../../../history';
import moment from 'moment';
// import WorkflowHistoryTable from '../../../../cyderComponents/common/WorkflowHistoryTable';
import controlledInputLib from '../../../../js/controlledInputLib';
import {
    Input,
    // Table,
    Button,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    // FormFeedback,
    InputGroup,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    InputGroupButtonDropdown,
    ListGroup,
    ListGroupItem,
    Badge,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Table,
} from 'reactstrap';
import {
    translate,
    // Trans
} from 'react-i18next';

import {
    expenseNewReceiptClaimToggleModal,
    expenseNewReceiptClaimChangeField,
    expenseNewReceiptClaimSaveReceipt,
    expenseNewReceiptClaimSetFields,
    expenseNewReceiptActualSetFields,
    expenseNewReceiptClaimGetImage,
    expenseNewReceiptClaimDownloadImage,
    expenseNewReceiptClaimForceLoadingState,
    expenseNewReceiptClaimDeleteSavedReceipt,
    expenseNewReceiptClaimResetFields,
    // expenseNewReceiptClaimGetHistory,
} from '../../../../actions/expenses/cyderExpenseNewReceiptClaimAction.js';
import {
    expenseSharedGetMileageCategories,
    expenseSharedGetProjects,
    expenseSharedGetLocations,
    expenseSharedGetExpenseCategories,
    expenseSharedGetPaymentModes,
    expenseSharedLoadedData,
} from '../../../../actions/expenses/cyderExpenseClaimSharedAction';
import LoadingSpinner from '../../../../cyderComponents/loadingSpinner/LoadingSpinner';
import DialogModal from '../../../../cyderComponents/modals/DialogModal';
import { setJumbotronTitle } from '../../../../actions/pagedata';

const chooseNewExpenseTabUrl = '/expense/myreceipts/new';

class ExpenseNewReceiptPage extends React.Component {
    constructor(props) {
        super(props);
        this.downloadImage = this.downloadImage.bind(this);
        this.state = {
            b64img: null,
            imagebtoa: '',
            expenseDate: null,
            dropdownOpen: false,
            selectedCurrency: 'SGD',
        };
        this.onDrop = this.onDrop.bind(this);
        this.toggleDropDown = this.toggleDropDown.bind(this);
        this.handleCurrencyDropdown = this.handleCurrencyDropdown.bind(this);
        this.handleToggleModal = this.handleToggleModal.bind(this);
        this.toggleAction = this.toggleAction.bind(this);
    }
    componentDidMount() {
        const routeProps = {
            match: this.props.match,
            location: this.props.location,
            history: this.props.history,
        };

        const theqs = this.props.location ? qs.parse(this.props.location.search) : {};
        this.props.getRelevantStuff(theqs.receiptid, theqs.index).then(res => {
            this.setState({
                b64img: res.Body,
                contentType: res.ContentType,
            });
            if (this.props.location && this.props.location.search && this.props.location.pathname === '/expense/myreceipts/new/receipt') {
                this.props.setJumbotronTitle('New Receipt (copying from existing)');
            } else if (this.props.location && this.props.location.search && this.props.location.pathname === '/autorec/edit') {
                if (this.props.receiptFields.status === 'Saved') {
                    this.props.setJumbotronTitle('Edit Receipt');
                } else {
                    this.props.setJumbotronTitle('View Receipt');
                }
            } else {
                this.props.setJumbotronTitle();
                this.props.resetFields();
            }
        });
    }
    handleExpenseDateChange(momentOrStringVal) {
        const synthEvent = {
            target: {
                id: 'expensedate',
                value: momentOrStringVal.format ? momentOrStringVal.format('DD/MM/YYYY') : momentOrStringVal,
            },
        };
        this.props.handleChange(synthEvent);
    }
    saveOnDialogButtonClick() {
        let data = Object.assign({}, this.props.fields);
        data.users = this.props.users.concat();
        data.appId = this.props.appId;
        this.props.addGroup(data);
    }
    onDrop(imageFiles) {
        imageFiles.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                const fileAsBinaryString = reader.result;
                this.setState({
                    b64img: null,
                    imagebtoa: btoa(fileAsBinaryString),
                    imageFiles: imageFiles,
                });
                // do whatever you want with the file content
            };
            // reader.onabort = () => console.log('file reading was aborted');
            // reader.onerror = () => km, log('file reading has failed');

            reader.readAsBinaryString(file);
        });
    }
    toggleDropDown() {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    }
    handleCurrencyDropdown(event) {
        this.setState({ selectedCurrency: event.target.value });
    }
    toggleAction() {
        this.handleToggleModal();
        if (this.props.modalAction === 'refresh') {
            this.props.getGroupDetails(this.props.match.params.receiptid);
        } else if (this.props.modalAction === 'redirect') {
            history.push('/autorec');
        }
    }
    calcCurrentPositiveButtonText() {
        if (this.props.modalAction === 'save') {
            return 'Save';
        } else if (this.props.modalAction === 'delete') {
            return 'Delete';
        } else if (this.props.modalAction === 'redirect') {
            return 'Ok';
        } else if (this.props.modalAction === 'close') {
            return 'Ok';
        } else {
            return 'Ok';
        }
    }
    calcCurrentPositiveButtonAction() {
        if (this.props.modalAction === 'save') {
            return () => {
                let fileInfo = {};
                if (this.state.imageFiles) {
                    fileInfo.attachment = this.state.imagebtoa;
                    fileInfo.contentType = this.state.imageFiles[0].type;
                    fileInfo.filename = this.state.imageFiles[0].name;
                }
                let selectedDefaultFixes = {
                    category: document.getElementById('category').value,
                    paymentmode: document.getElementById('paymentmode').value,
                    project: document.getElementById('project').value,
                    currency: this.state.selectedCurrency,
                    ...fileInfo,
                };
                const prj = selectedDefaultFixes.project;
                if (prj === '' || prj === undefined || prj === null) {
                    delete selectedDefaultFixes.project;
                }
                this.props.handleSave(selectedDefaultFixes);
            };
        } else if (this.props.modalAction === 'delete') {
            return this.props.handleDelete;
        } else if (this.props.modalAction === 'close') {
            return this.handleToggleModal;
        } else if (this.props.modalAction === 'redirect') {
            return () => {
                history.push('/autorec');
                if (this.props.refreshList) {
                    this.props.refreshList();
                }
                this.handleToggleModal();
            };
        } else {
            return this.handleToggleModal;
        }
    }
    handleToggleModal(modalAction) {
        this.props.toggleModal(modalAction);
    }
    downloadImage(url) {
        if (window.chrome && window.chrome.app && url.length > 2 * 1024 * 1024) {
            //catch
        } else {
            this.downloadImageOrig(url);
        }

        // let uriContent = URL.createObjectURL(new Blob([content], { type: 'text/plain' }));
        // let link = document.createElement('a');
        // link.setAttribute('href', uriContent);
        // link.setAttribute('download', filename);
        // let event = new MouseEvent('click');
        // link.dispatchEvent(event);
    }
    downloadImageOrig(url) {
        const parsedUrl = url.replace(/^data:image\/[^;]+/, 'data:application/octet-stream');
        // console.log("parsedUrl: ", parsedUrl)
        let fileName = 'image';
        // console.log("this.state.contentType: ", this.state.contentType)
        switch (this.state.contentType) {
            case 'image/jpeg':
                fileName = fileName + '.jpg';
                break;
            case 'image/png':
                fileName = fileName + '.png';
                break;
            default:
                break;
        }

        let link = document.createElement('a');
        link.href = parsedUrl;
        link.download = fileName;
        link.click();
    }
    render() {
        const dropzoneStyle = {
            width: '100%',
            height: '100%',
            maxHeight: '500px',
            border: '2px dotted gray',
        };
        const imageStyle = {
            maxWidth: '100%',
            width: '100%',
            maxHeight: '450px',
            objectFit: 'contain',
        };
        const imageUrl = this.state.b64img
            ? 'data:' + this.state.contentType + ';base64, ' + this.state.b64img
            : this.state.imageFiles
            ? this.state.imageFiles[0].preview
            : null;
        return (
            <Container key={0}>
                {this.props.location && this.props.location.search ? (
                    <Row>
                        <Col className="d-flex" xs={12}>
                            <div className="ml-auto">
                                <Link to="/autorec">
                                    <Button>Back to list</Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                ) : null}
                {this.props.loading ? (
                    <LoadingSpinner />
                ) : (
                    <Row className="mb-2">
                        <Col xs={12}>
                            <Link to="/autorec">
                                <i className="align-middle material-icons material-icons-3x">chevron_left</i>
                            </Link>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form>
                                <Row>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label for="expenseDate">Expense Date</Label>
                                            <CyderDatePicker
                                                onChange={event => this.handleExpenseDateChange(event)}
                                                value={this.props.receiptFields.expensedate}
                                                placeholder="DD/MM/YYYY"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6}>
                                        <FormGroup>
                                            <Label for="expenseAmount">Amount</Label>
                                            <InputGroup>
                                                <Input
                                                    onChange={this.props.handleChange}
                                                    value={this.props.receiptFields.amount}
                                                    placeholder="Amount"
                                                    id="amount"
                                                />
                                                <InputGroupButtonDropdown
                                                    addonType="append"
                                                    isOpen={this.state.dropdownOpen}
                                                    toggle={this.toggleDropDown}
                                                >
                                                    <DropdownToggle caret>{this.state.selectedCurrency || ''}</DropdownToggle>
                                                    <DropdownMenu onClick={this.handleCurrencyDropdown}>
                                                        <DropdownItem value="SGD">SGD</DropdownItem>
                                                        <DropdownItem disabled value="USD">
                                                            USD
                                                        </DropdownItem>
                                                        <DropdownItem disabled value="MYR">
                                                            MYR
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </InputGroupButtonDropdown>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label for="exampleSelect">Project</Label>
                                    <Input
                                        onChange={this.props.handleChange}
                                        value={this.props.receiptFields.project || ''}
                                        type="select"
                                        id="project"
                                    >
                                        <option value={null}>None</option>
                                        {this.props.projects.map((each, i) => {
                                            return (
                                                <option key={i} value={each.code}>
                                                    {each.name}
                                                </option>
                                            );
                                        })}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="exampleSelect">Payment Mode</Label>
                                    <Input
                                        onChange={this.props.handleChange}
                                        value={this.props.receiptFields.paymentmode || ''}
                                        type="select"
                                        id="paymentmode"
                                    >
                                        <option value={null}>Select Payment Mode</option>
                                        {this.props.paymentModes.map((each, i) => {
                                            return (
                                                <option key={i} value={each.code}>
                                                    {each.name}
                                                </option>
                                            );
                                        })}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="remarks">Remarks</Label>
                                    <Input
                                        onChange={this.props.handleChange}
                                        value={this.props.receiptFields.remarks}
                                        type="textarea"
                                        id="remarks"
                                        placeholder="Please enter purpose"
                                    />
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Form>
                                <FormGroup>
                                    <Label>Receipt No.</Label>
                                    <Input
                                        onChange={this.props.handleChange}
                                        value={this.props.receiptFields.receiptno}
                                        type="text"
                                        placeholder="Receipt No."
                                        id="receiptno"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="exampleSelect">Expense Category</Label>
                                    <Input
                                        value={this.props.receiptFields.category || ''}
                                        onChange={this.props.handleChange}
                                        type="select"
                                        id="category"
                                    >
                                        <option value={null}>Select Expense Category</option>
                                        {this.props.expenseCategories.map((each, i) => {
                                            return (
                                                <option key={i} value={each.code}>
                                                    {each.name}
                                                </option>
                                            );
                                        })}
                                    </Input>
                                </FormGroup>
                                <FormGroup className="d-flex" row>
                                    <Label className="p-1 align-middle">Reimbursable</Label>
                                    <Col className="ml-auto">
                                        <FormGroup>
                                            <Label>
                                                <Switch
                                                    onChange={checked =>
                                                        this.props.handleChange(
                                                            controlledInputLib.generateSwitchSynthEvent(checked, 'reimbursable'),
                                                        )
                                                    }
                                                    checked={this.props.receiptFields.reimbursable}
                                                    uncheckedIcon={null}
                                                    id="reimbursable"
                                                />
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </FormGroup>
                                <Dropzone style={dropzoneStyle} onDrop={this.onDrop}>
                                    <Row>
                                        <Col>
                                            <Button onClick={e => e.preventDefault()} color="primary" block>
                                                Click here or drop image to upload
                                            </Button>
                                        </Col>
                                    </Row>
                                    <img alt="" style={imageStyle} src={imageUrl} />
                                </Dropzone>
                                {this.state.b64img ? (
                                    <Row className="mt-2">
                                        <Col className="d-flex">
                                            {window.chrome && window.chrome.app && imageUrl.length > 2 * 1024 * 1024 ? (
                                                <span style={{ fontSize: '16px' }}>
                                                    Image is too big. Please right click on the image and save, or use Safari / Firefox to
                                                    download
                                                </span>
                                            ) : (
                                                <Button onClick={() => this.downloadImage(imageUrl)} className="ml-auto">
                                                    <i className="align-middle material-icons">file_download</i> Download Image
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                ) : null}
                            </Form>
                        </Col>
                        {this.props.location ? (
                            <Col xs={12}>Last Saved: {moment(this.props.receiptFields.updateddt).format('DD/MM/YYYY HH:mm')}</Col>
                        ) : null}
                    </Row>
                )}
                <Row>
                    <Col xs={12}>
                        <Button
                            disabled={
                                !this.props.allLoaded ||
                                this.props.receiptFields.status === 'Submitted' ||
                                this.props.receiptFields.status === 'Approved' ||
                                this.props.receiptFields.status === 'Returned'
                            }
                            onClick={() => this.handleToggleModal('save')}
                        >
                            Save
                        </Button>
                        {/*
                        <Button
                            disabled={!this.state.imageFiles}
                            className="ml-2"
                        >
                            Save Draft
                        </Button>
                        */}
                    </Col>
                </Row>
                {/*
                {!this.props.loading && this.props.workflowHistory && this.props.workflowHistory.length > 0 ? (
                    <WorkflowHistoryTable
                        workflowHistory={this.props.workflowHistory}
                    />
                ) : null}
                */}
                <DialogModal
                    key="modal"
                    modal={this.props.modalOpen}
                    toggle={this.toggleAction}
                    customHeader={this.props.modalHeader || 'Confirmation'}
                    customModalBody={this.props.saving ? <LoadingSpinner /> : this.props.modalMessage}
                    positiveButtonAction={this.calcCurrentPositiveButtonAction()}
                    positiveButtonText={this.calcCurrentPositiveButtonText()}
                    onlyOneButton={this.props.saved}
                    buttonDisabler={this.props.saving}
                />
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return Object.assign(
        {
            receipts: state.cyderExpenseMyReceiptsReducer.receipts,
            ...state.cyderExpenseNewReceiptClaimReducer,
            ...state.cyderExpenseSharedReducer,
        },
        {
            loading: state.cyderExpenseNewReceiptClaimReducer.loading,
        },
    );
};
const mapDispatchToProps = dispatch => {
    return {
        toggleModal: modalAction => {
            dispatch(expenseNewReceiptClaimToggleModal(modalAction ? modalAction : null));
        },
        handleChange: event => {
            const key = event.target.id;
            const value = event.target.value;
            dispatch(expenseNewReceiptClaimChangeField(key, value));
        },
        handleSave: selectedDefaultFixes => {
            dispatch(expenseNewReceiptClaimSaveReceipt(selectedDefaultFixes));
        },
        handleDelete: () => {
            dispatch(expenseNewReceiptClaimDeleteSavedReceipt());
        },
        resetFields: () => {
            dispatch(expenseNewReceiptClaimResetFields());
        },
        getRelevantStuff: (receiptid, index) => {
            dispatch(expenseNewReceiptClaimForceLoadingState(true));
            return Promise.all([])
                .then(() => {
                    // will use receipt info from myReceipts list if exists on client
                    if (receiptid && index) {
                        const items2 = [
                            dispatch(expenseNewReceiptClaimGetImage(receiptid)),
                            dispatch(expenseNewReceiptClaimSetFields(receiptid, index)).then(res => {
                                const items = [
                                    dispatch(expenseSharedGetProjects(res.appId)),
                                    dispatch(expenseSharedGetExpenseCategories(res.appId)),
                                    dispatch(expenseSharedGetPaymentModes(res.appId)),
                                ];
                                Promise.all(items).then(() => {
                                    dispatch(expenseNewReceiptActualSetFields(res));
                                });
                            }),
                            // dispatch(expenseNewReceiptClaimGetHistory(receiptid)),
                        ];
                        return Promise.all(items2).then(res => {
                            if (res[0].length > 0) {
                                return dispatch(expenseNewReceiptClaimDownloadImage(res[0][0].url)).then(res => {
                                    return res;
                                });
                            } else {
                                return Promise.resolve({ message: 'no attachment' });
                            }
                        });
                    } else {
                        return Promise.resolve({ message: 'new receipt' });
                    }
                })
                .then(res => {
                    dispatch(expenseNewReceiptClaimForceLoadingState(false));
                    dispatch(expenseSharedLoadedData());
                    return res;
                });
        },
        setJumbotronTitle: title => {
            dispatch(setJumbotronTitle(title || 'New Receipt'));
        },
    };
};
// translate() is if we want to use HOC to perform t()
export default translate()(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(StoredLayout(ExpenseNewReceiptPage)),
);
