export default {
    generateSwitchSynthEvent(checked, id) {
        const synthEvent = {
            target: {
                id,
                value: checked,
            }
        }
        return synthEvent;
    },
    generateCheckboxSynthEvent(e, truevalue, falsevalue) {
        const returnTrueValue = truevalue !== undefined ? truevalue : true;
        const returnFalseValue = falsevalue !== undefined ? falsevalue : false;
        const synthEvent = {
            target: {
                id: e.target.id,
                value: e.target.checked === true ? returnTrueValue : returnFalseValue,
            }
        }
        return synthEvent;
    },
    generateDateTimeSynthEvent(momentOrStringVal, id, momentFormat) {
        const synthEvent = {
            target: {
                id,
                value: momentOrStringVal.format ? momentOrStringVal.format(momentFormat) : momentOrStringVal,
            }
        }
        return synthEvent;
    }
};
