import React from 'react'

const style = {
    textAlign: "center",
    width: "100%",
}
const LoadingSpinner = () =>
    <div style={style}>
        <i className="fa fa-3x fa-spin fa-spinner" />
    </div>

export default LoadingSpinner
