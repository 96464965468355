import { CYDER_API_KEY, DJARVIS_API_KEY } from '../config';

export function config(
    state = {
        apiKey: DJARVIS_API_KEY,
        apiKeyCyder: CYDER_API_KEY,
        appId: "CYDERINTRANET",
        layout: 'default-sidebar-1',
        background: 'light',
        navbar: 'light',
        topNavigation: 'light',
        logo: 'dark',
        leftSidebar: 'dark',
        leftSidebarIcons: 'dark',
        rightSidebar: false,
        collapsed: false
    },
    action
) {
    switch (action.type) {
        case 'SET_CONFIG':
            return Object.assign({}, state, {
                ...action.config
            })
        default:
            return state
    }
}
