import {
    CYDER_EXPENSE_NEW_MILEAGE_LOCATION_START_SET,
    CYDER_EXPENSE_NEW_MILEAGE_LOCATION_DESTINATION_SET,
    CYDER_EXPENSE_NEW_MILEAGE_LOCATION_REMOVE_MARKERS,
    CYDER_EXPENSE_NEW_MILEAGE_DIRECTIONS_SET_CURRENT_RESULT,
    CYDER_EXPENSE_NEW_MILEAGE_CHANGE_FIELD,
    CYDER_EXPENSE_NEW_MILEAGE_SAVE_REQUEST,
    CYDER_EXPENSE_NEW_MILEAGE_SAVE_SUCCESS,
    CYDER_EXPENSE_NEW_MILEAGE_SAVE_FAILURE,
    CYDER_EXPENSE_NEW_MILEAGE_SET_FIELDS,
    CYDER_EXPENSE_NEW_MILEAGE_GET_SAVED_RECEIPT_REQUEST,
    CYDER_EXPENSE_NEW_MILEAGE_GET_SAVED_RECEIPT_SUCCESS,
    CYDER_EXPENSE_NEW_MILEAGE_GET_SAVED_RECEIPT_FAILURE,
    CYDER_EXPENSE_NEW_MILEAGE_FORCE_LOADING_STATE,
    CYDER_EXPENSE_NEW_MILEAGE_MODAL_TOGGLE,
    CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_REQUEST,
    CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_SUCCESS,
    CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_FAILURE,
} from "../constants/actionTypes";
import withQuery from 'with-query';
import cyderlib from '../../js/cyderlib';

import { CYDER_API_ROOT, DJARVIS_API_ROOT } from '../../config';

export function travelExpenseSetStartLocation(place, marker) {
    return (dispatch) => {
        dispatch({
            type: CYDER_EXPENSE_NEW_MILEAGE_LOCATION_START_SET,
            place,
            marker,
        })
        return Promise.resolve();
    }
}
export function travelExpenseSetDestinationLocation(place, marker) {
    return (dispatch) => {
        dispatch({
            type: CYDER_EXPENSE_NEW_MILEAGE_LOCATION_DESTINATION_SET,
            place,
            marker,
        })
        return Promise.resolve();
    }
}
export function travelExpenseRemoveMarkers(startOrDestination) {
    return {
        type: CYDER_EXPENSE_NEW_MILEAGE_LOCATION_REMOVE_MARKERS,
        startOrDestination,
    }
}
export function travelExpenseSetCurrentDirectionsResult(directionsResult) {
    return {
        type: CYDER_EXPENSE_NEW_MILEAGE_DIRECTIONS_SET_CURRENT_RESULT,
        directionsResult,
    }
}
export function expenseNewMileageClaimChangeField(key, value) {
    return {
        type: CYDER_EXPENSE_NEW_MILEAGE_CHANGE_FIELD,
        key,
        value,
    }
}
export function expenseNewMileageClaimForceLoadingState(loading) {
    return {
        type: CYDER_EXPENSE_NEW_MILEAGE_FORCE_LOADING_STATE,
        loading,
    }
}
export function expenseNewMileageClaimToggleModal(modalAction, data) {
    return {
        type: CYDER_EXPENSE_NEW_MILEAGE_MODAL_TOGGLE,
        modalAction,
        data,
    }
}
export function expenseNewMileageClaimSaveMileage(data) {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_EXPENSE_NEW_MILEAGE_SAVE_REQUEST })
        const url = withQuery(DJARVIS_API_ROOT+'/expense_v1/mileage', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: "addmileage",
        });

        // category: document.getElementById('category').value,
        // paymentmode: document.getElementById('paymentmode').value,
        // project: document.getElementById('project').value,
        // currency: this.state.selectedCurrency,
        // const 
        const actualData = Object.assign({}, data);
        if (getState().cyderExpenseNewMileageClaimReducer.mileageFields.receiptId) {
            actualData.receiptId = getState().cyderExpenseNewMileageClaimReducer.mileageFields.receiptId;
        }
        actualData.userid = getState().cyderProfileReducer.profile.username;
        const options = {
            headers: {
                "x-api-key": getState().config.apiKey,
                "Content-Type": "application/json",
                "Authorization":  getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify(actualData),
            method: "POST",
        };

        return fetch(url, options).then((response) => {
            if (!response.ok) {
                return response.json().then((error) => {
                    dispatch({ type: CYDER_EXPENSE_NEW_MILEAGE_SAVE_FAILURE, response })
                    throw error;
                })
            } else {
                return response.json().then((response) => {
                    dispatch({ type: CYDER_EXPENSE_NEW_MILEAGE_SAVE_SUCCESS, response })
                    return response;
                })
            }
        }).catch((error) => {
            dispatch({ type: CYDER_EXPENSE_NEW_MILEAGE_SAVE_FAILURE, error })
            return error;
        });
    }
}
export function expenseNewMileageClaimSetFields(receiptid, index) {
    return (dispatch, getState) => {
        if (getState().cyderExpenseMyReceiptsReducer.receipts.length !== 0 &&
            getState().cyderExpenseMyReceiptsReducer.receipts[index] !== undefined) {
            const mileageFields = getState().cyderExpenseMyReceiptsReducer.receipts[index];
            console.log('getting receipt details from client');
            // normalize fields before dispatching
            dispatch({ type: CYDER_EXPENSE_NEW_MILEAGE_SET_FIELDS, mileageFields: cyderlib.normalizeReceiptFields(mileageFields) });
            return Promise.resolve(mileageFields);
        }

        dispatch({ type: CYDER_EXPENSE_NEW_MILEAGE_GET_SAVED_RECEIPT_REQUEST })
        const url = withQuery(DJARVIS_API_ROOT+'/expense_v1/my', {
            appid: getState().cyderProfileReducer.profile.appId,
            id: receiptid,
            action: "findreceiptbyid",
        });
        const options = {
            headers: {
                "x-api-key": getState().config.apiKey,
                "Content-Type": "application/json",
                "Authorization":  getState().cyderProfileReducer.profile.authorizationToken,
            },
            method: "POST",
        };

        return fetch(url, options).then((response) => {
            if (!response.ok) {
                return response.json().then((error) => {
                    dispatch({ type: CYDER_EXPENSE_NEW_MILEAGE_GET_SAVED_RECEIPT_FAILURE, response })
                    throw error;
                })
            } else {
                return response.json().then((response) => {
                    dispatch({ type: CYDER_EXPENSE_NEW_MILEAGE_SET_FIELDS, mileageFields: cyderlib.normalizeReceiptFields(response) });
                    dispatch({ type: CYDER_EXPENSE_NEW_MILEAGE_GET_SAVED_RECEIPT_SUCCESS, response })
                    return response;
                })
            }
        }).catch((error) => {
            dispatch({ type: CYDER_EXPENSE_NEW_MILEAGE_GET_SAVED_RECEIPT_FAILURE, error })
            return error;
        });
    }
}
export function expenseNewMileageClaimDeleteSavedReceipt() {
    return (dispatch, getState) => {
        const url = withQuery(DJARVIS_API_ROOT+'/expense_v1/my', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: "deletereceipt",
        });
        const body = {
            receiptid: getState().cyderExpenseNewMileageClaimReducer.mileageFields.receiptId,
            userid: getState().cyderProfileReducer.profile.username,
        }
        const options = {
            method: "POST",
            headers: {
                "x-api-key": getState().config.apiKey,
                "Content-Type": "application/json",
                "Authorization":  getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify(body),
        };

        dispatch({type: CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_REQUEST});

        return fetch(url, options).then((response) => {
            if (!response.ok) {
                return response.json().then((error) => {
                    dispatch({type: CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_FAILURE, error});
                })
            } else {
                return response.json().then((response) => {
                    dispatch({type: CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_SUCCESS, response});
                })
            }
        }).catch((error) => {
            dispatch({type: CYDER_EXPENSE_NEW_MILEAGE_DELETE_SAVED_RECEIPT_FAILURE, error});
        });
    }
}

