import StoredLayout from '../../layouts/StoredLayout'
import React from 'react'
import { connect } from 'react-redux'
// import history from '../../history'
import {
    Input,
    Table,
    Button,
    Container,
    Row,
    Col,
    // Form,
    FormGroup,
    // FormFeedback,
    Label,
} from "reactstrap";
import i18next from 'i18next';
import { translate, Trans } from 'react-i18next';

import {
    cyderSysparamSaveAction,
    cyderSysparamGetAction,
    cyderSysparamEditModalChangeValueAction,
    cyderSysparamEditModalToggle,
    cyderSysparamEditModalSetSysparam,
} from '../../actions/settings/cyderSysparamAction'
import DialogModal from '../../cyderComponents/modals/DialogModal';
import LoadingSpinner from '../../cyderComponents/loadingSpinner/LoadingSpinner';

// import cyderlib from '../../js/cyderlib';


class SysparamsPage extends React.Component {
    componentDidMount() {
        this.props.getSysparams(null);
    }
    setupEditModal(sysparam) { 
        this.props.setModalSysparam(sysparam.code, sysparam.label, sysparam.val);
        this.props.toggleModal();
    }
    render() {
        const { t } = this.props
        const customHeader = "Update System Setting";
        const customModalBody =
            <FormGroup>
                <Label for="exampleText">{this.props.editModalSysparamLabel}</Label>
                <Input
                    onChange={(event) => this.props.changeModalTextAreaValue(event)}
                    type="textarea"
                    name="text"
                    maxLength={500}
                    id="exampleText"
                    value={this.props.editModalSysparamVal}
                />
            </FormGroup>
        const trMap = this.props.sysparams.map((item, i) => {
            // TODO: might want validation later
            // const valid = cyderlib.validate(this.props.sysparams[i].val, 'alphanumeric');
            return (
                <tr key={i}>
                    {/* <td>{item.section}</td> */}
                    <td>{item.label}</td>
                    <td>{this.props.sysparams[i].val}</td>
                    <td>
                        <Button
                            tabIndex="-1"
                            className="mx-1"
                            onClick={() => { this.setupEditModal(this.props.sysparams[i]) }}
                        >
                            edit
                        </Button>
                    </td>
                </tr>
            )
        });
        return ([
            this.props.loading ? <LoadingSpinner key={0}/> :
                <div key={0}>
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            {/* <th><Trans>Section</Trans></th> */}
                                            <th><Trans>Label</Trans></th>
                                            <th>{t('Value')}</th>
                                            <th><Trans>Action</Trans></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {trMap}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Container>
                </div>,
            <DialogModal
                key="modal"
                modal={this.props.editModalOpen}
                toggle={this.props.toggleModal}
                customHeader={customHeader}
                customModalBody={customModalBody}
                positiveButtonAction={() => this.props.save(this.props.editModalSysparamCode, this.props.editModalSysparamVal)}
                positiveButtonText="Save"
                buttonDisabler={this.props.savingSysparam}
            />]
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        editModalOpen: state.cyderSysparamReducer.editModalOpen,
        editModalSysparamVal: state.cyderSysparamReducer.editModalSysparamVal,
        editModalSysparamCode: state.cyderSysparamReducer.editModalSysparamCode,
        editModalSysparamLabel: state.cyderSysparamReducer.editModalSysparamLabel,
        loading: state.cyderSysparamReducer.loading,
        savingSysparam: state.cyderSysparamReducer.savingSysparam,
        sysparams: state.cyderSysparamReducer.sysparams
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setModalSysparam: (code, label, val) => {
            dispatch(cyderSysparamEditModalSetSysparam(code, label, val));
        },
        toggleModal: () => {
            dispatch(cyderSysparamEditModalToggle());
        },
        save: (code, val) => {
            const body = {
                code,
                val: val.trim()
            }
            const cb = function() {
                dispatch(cyderSysparamEditModalToggle());
                dispatch(cyderSysparamGetAction(null));
            }
            dispatch(cyderSysparamSaveAction(body, cb));
        },

        changeModalTextAreaValue: (event) => {
            const newValue = event.target.value;
            dispatch(cyderSysparamEditModalChangeValueAction(newValue));
        },
        change: (someparam, index) => {
            if (i18next.language === 'en') {
                i18next.changeLanguage('ch');
            } else {
                i18next.changeLanguage('en');
            }
            let action = {
                type: "TEST",
                someparam: someparam,
                index: index
            }
            dispatch(action);
        },
        getSysparams: (someparam = null) => {
            dispatch(cyderSysparamGetAction(someparam)
            ).then(
                (res) => {
                    console.log(res)
                    console.log('this is a promise')
                }
            );
        }
    }
}
// translate() is if we want to use HOC to perform t()
export default translate()(connect(mapStateToProps, mapDispatchToProps)(StoredLayout(SysparamsPage)))
