import StoredLayout from '../../layouts/StoredLayout'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import history from '../../history'
import {
    Alert,
    Input,
    Table,
    Button,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    // FormFeedback,
} from "reactstrap";
import { translate, Trans } from 'react-i18next';

import {
    cyderAccountsSearchAction,
    cyderAccountsSearchGetSystemList,
} from '../../actions/accounts/cyderAccountsSearchAction'
import LoadingSpinner from '../../cyderComponents/loadingSpinner/LoadingSpinner';
import ReactTable from 'react-table'

// import cyderlib from '../../js/cyderlib';


class AccountsSearchPage extends React.Component {
    componentDidMount() {
        // this.props.getAllAccounts();
        this.props.getSystemList().then(() => {
            if (this.props.forceRefresh) {
                if (this.props.prevSearchBody) {
                    document.getElementById("companyname").value = this.props.prevSearchBody.companyname;
                    document.getElementById("firstname").value = this.props.prevSearchBody.firstname;
                    document.getElementById("lastname").value = this.props.prevSearchBody.lastname;
                    document.getElementById("system").value = this.props.prevSearchBody.system;
                    document.getElementById("status").value = this.props.prevSearchBody.status;
                }
                this.validateThenSearch();
            }
        });
    }
    validateThenSearch() {

        const companyname = document.getElementById("companyname").value;
        const firstname = document.getElementById("firstname").value;
        const lastname = document.getElementById("lastname").value;
        const system = document.getElementById("system").value;
        const status = document.getElementById("status").value;

        const body = {
            companyname,
            firstname,
            lastname,
            system,
            status,
        }
        this.props.searchAccounts(body);
    }
    render() {
        // const { t } = this.props;
        const columns = [
            {
                Header: 'First Name',
                accessor: 'firstname',
            }, {
                Header: 'Company Name',
                accessor: 'companyname',
            }, {
                Header: 'Email',
                accessor: 'emailaddress',
            }, {
                Header: 'Mobile No.',
                accessor: 'phonenumber',
            }, {
                Header: 'System',
                accessor: 'system',
            }, {
                Header: "",
                accessor: 'id',
                Cell: props => {
                    const url = "/accounts/details/" + props.value;
                    return (
                        <div className="float-right">
                            <Link
                                to={url}
                                tabIndex="-1"
                                className="btn btn-primary"
                            >
                                View
                            </Link>
                        </div>
                    )
                }
            }
        ]

        const systemsListMap = this.props.systemsList.map((system, i) => {
            return (
                <option key={i} value={system.code}>
                    {system.name}
                </option>
            )
        });
        return (
            <div key={0}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <Form>
                                <FormGroup>
                                    <Input
                                        placeholder="Company name"
                                        id="companyname"
                                    />
                                </FormGroup>
                                <FormGroup>

                                    <Input
                                        placeholder="First name"
                                        id="firstname"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Input
                                        placeholder="Last name"
                                        id="lastname"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Input type="select" id="system">
                                        <option value="all">
                                            All
                                        </option>
                                        {systemsListMap}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Input type="select" id="status">
                                        <option>Active</option>
                                        <option>Rejected</option>
                                        <option>Pending</option>
                                        <option>Inactive</option>
                                        <option>Suspended</option>
                                        <option>Deleted</option>
                                        <option>Trial</option>
                                    </Input>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col>
                            <Button
                                disabled={this.props.loadingAccountsList || this.props.loadingSystemsList}
                                onClick={() => this.validateThenSearch()}
                                tabIndex="-1"
                            >
                                Search
                            </Button>
                            {/*
                            <Link
                                to="/administration/accounts/add"
                                className="btn btn-success"
                                style={{float:"right"}}
                                tabIndex="-1"
                            >
                                Add New User
                            </Link>
                            */}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            {this.props.loadingAccountsList ? <LoadingSpinner key={0}/> :
                                this.props.accounts.length > 0 ?  <ReactTable
                                    sortable={true}
                                    className="-highlight mb-2"
                                    data={this.props.accounts}
                                    columns={columns}
                                    minRows={0}
                                    showPagination={true}
                                /> : this.props.errorMessage ?  <Alert color="danger">
                                    {this.props.errorMessage}
                                </Alert> : null
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        ...state.cyderAccountsSearchReducer,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        searchAccounts: (body) => {
            dispatch(cyderAccountsSearchAction(body)
            ).then(
                (res) => {
                    console.log(res)
                    console.log('this is a promise')
                }
            );
        },
        getSystemList: () => {
            return dispatch(cyderAccountsSearchGetSystemList());
        }
    }
}
// translate() is if we want to use HOC to perform t()
export default translate()(connect(mapStateToProps, mapDispatchToProps)(StoredLayout(AccountsSearchPage)))

