/**
 * React Routes
 *
 * Author: Fernando
 */

import React from 'react';
import { Switch, Route } from 'react-router';

// PrivateRoute
import PrivateRoute from './cyderComponents/common/PrivateRoute';
import CloudAdminPages from './pages/branch-specific/CloudAdminPages';

//pages
// in the future, follow the format of <module(plural)> <Purpose> Page
import Home from './pages/home';
import Profile from './pages/profile';
import Sysparams from './pages/settings/Sysparams';
import Login from './pages/login';
import ChangePassword from './pages/changepassword/ChangePassword';
import ForgotPassword from './pages/forgotpassword/ForgotPassword';
import ErrorPage from './pages/error-page';

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/changepassword" component={ChangePassword} />
            <Route exact path="/changepassword/:token" component={ChangePassword} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/administration/sysparams" component={Sysparams} />
            <CloudAdminPages />

            {/* 404 page */}
            <PrivateRoute path="/" component={ErrorPage} />
        </Switch>
    );
};

export default Routes;
