import {
    CYDER_USERS_SEARCH_REQUEST,
    CYDER_USERS_SEARCH_SUCCESS,
    CYDER_USERS_SEARCH_FAILURE,
    CYDER_USERS_SEARCH_SET_FORCE_REFRESH_FLAG,
} from '../../actions/constants/actionTypes' 

// defaults
var cyderSysparams = {
    editModalOpen: false,
    editModalSysparamVal: null,
    editModalSysparamCode: null,
    savingSysparam: false,
    loading: false,
    users: [],
    forceRefresh: false,
}

export function cyderUsersSearchReducer(state = cyderSysparams, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
    case CYDER_USERS_SEARCH_REQUEST:
        newState.loading = true;
        newState.errorMessage = null;
        return newState;
    case CYDER_USERS_SEARCH_SUCCESS:
        newState.loading = false;
        newState.users = action.response;
        return newState;
    case CYDER_USERS_SEARCH_FAILURE:
        newState.loading = false;
        newState.errorMessage = action.error.message;
        newState.users = [];
        return newState;
    case CYDER_USERS_SEARCH_SET_FORCE_REFRESH_FLAG:
        newState.forceRefresh = action.val;
        return newState;
    default:
        return state;
    }
}
