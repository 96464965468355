import {
    CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_REQUEST,
    CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_SUCCESS,
    CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_FAILURE,
    CYDER_ACCOUNTS_SEARCH_REQUEST,
    CYDER_ACCOUNTS_SEARCH_SUCCESS,
    CYDER_ACCOUNTS_SEARCH_FAILURE,
    CYDER_ACCOUNTS_SEARCH_SET_FORCE_REFRESH_FLAG,
} from '../../actions/actionTypes' 

// defaults
var cyderSysparams = {
    editModalOpen: false,
    editModalSysparamVal: null,
    editModalSysparamCode: null,
    savingSysparam: false,
    loadingSystemsList: false,
    loadingAccountsList: false,
    accounts: [],
    systemsList: [],
    forceRefresh: false,
    prevSearchBody: {},
}

export function cyderAccountsSearchReducer(state = cyderSysparams, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
    case CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_REQUEST:
        newState.loadingSystemsList = true;
        newState.errorMessage = null;
        return newState;
    case CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_SUCCESS:
        newState.loadingSystemsList = false;
        newState.systemsList = action.response;
        return newState;
    case CYDER_ACCOUNTS_SEARCH_GET_SYSTEMS_LIST_FAILURE:
        newState.loadingSystemsList = false;
        newState.errorMessage = action.error.message;
        newState.systemsList = [];
        return newState;
    case CYDER_ACCOUNTS_SEARCH_REQUEST:
        newState.loadingAccountsList = true;
        newState.errorMessage = null;
        newState.prevSearchBody = action.prevSearchBody;
        return newState;
    case CYDER_ACCOUNTS_SEARCH_SUCCESS:
        newState.loadingAccountsList = false;
        newState.accounts = action.response;
        return newState;
    case CYDER_ACCOUNTS_SEARCH_FAILURE:
        newState.loadingAccountsList = false;
        newState.errorMessage = action.error.message;
        newState.accounts = [];
        return newState;
    case CYDER_ACCOUNTS_SEARCH_SET_FORCE_REFRESH_FLAG:
        newState.forceRefresh = action.val;
        return newState;
    default:
        return state;
    }
}

