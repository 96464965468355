import EmptyView1 from '../../layouts/EmptyView1'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    Alert,
    Button,
} from 'reactstrap'
import {
    cyderForgotPasswordPageSetMessageAction,
    cyderForgotPasswordAction,
} from '../../actions/security/cyderSecurityAction'
import CyderForm from '../CyderForm'
import CyderInputGroup from '../CyderInputGroup'
import history from '../../history'

class ForgotPassword extends React.Component {
    render() {
        const style = this.props.message ? null : {display:"none"};
        return (
            <CyderForm
                title="Forgot Password"
                description={!this.props.success ? "Please enter your registered email address" : null}
            >
                <div className="form-group">
                    <Alert style={style} color={this.props.success ? "success" : "danger"}>
                        {this.props.message ? this.props.message : null}
                    </Alert>
                    {!this.props.success ? <CyderInputGroup field={this.props.fields[0]} /> : null }
                </div>
                <Button
                    hidden={this.props.success}
                    disabled={this.props.requesting}
                    className="btn btn-primary"
                    type="submit"
                    onClick={() => this.validateThenSubmit()}
                >
                    Submit
                </Button>
                <Link
                    className="float-right"
                    to={this.props.requesting ? "#" : "/"}
                >
                    Back
                </Link>
            </CyderForm>
        )
    }
    validateThenSubmit() {
        this.props.clearErrorMessage();

        const email = document.getElementById(this.props.fields[0].id).value;

        if (email === "") {
            this.props.setMessage("Please enter your email address");
            return false;
        }
        this.props.changePasswordSubmit(email);
    }
}

const mapStateToProps = (state) => {
    return {...state.cyderForgotPasswordReducer,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        clearErrorMessage: () => {
            dispatch(cyderForgotPasswordPageSetMessageAction(""));
        },
        setMessage: (message) => {
            dispatch(cyderForgotPasswordPageSetMessageAction(message));
        },
        changePasswordSubmit: (email) => {
            var navigateHome = () => {
                var navigate = '/home';
                history.replace(navigate);
            }
            var navigateLogin = () => {
                var navigate = '/';
                history.replace(navigate);
            }
            dispatch(cyderForgotPasswordAction(email, navigateHome, navigateLogin));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EmptyView1(ForgotPassword))

