import matchSorter from 'match-sorter'

const generateFilterMethod = (keys) => {
    // using match-sorter
    // it will take the content entered into the "filter"
    // and search for it in EITHER the firstName or lastName
    if (keys === undefined) {
        keys = [
            "code",
            "name",
        ]
    }
    return (filter, rows) => {
        const result = matchSorter(rows, filter.value, {
            keys,
            threshold: matchSorter.rankings.CONTAINS,
        });
        return result;
    }
}
export default {
    generateFilterMethod: generateFilterMethod,
    defaultFilterMethod(filter, row) {
        return String(row[filter.id]) === filter.value;
    },
    filterAllFunc(e) {
        const { value } = e.target;
        const filterAll = value;
        const filtered = [{ id: 'all', value: filterAll }];
        // NOTE: this completely clears any COLUMN filters
        return { filterAll, filtered };
    },
    onFilteredChange(filtered) {
        // extra check for the "filterAll"
        if (filtered.length > 1 && this.state.filterAll.length) {
            // NOTE: this removes any FILTER ALL filter
            const filterAll = '';
            return ({ filtered: filtered.filter((item) => item.id != 'all'), filterAll })
        }
        else
            return ({ filtered });
    },
    generateAllColumnFilter(keys) {
        return {
            Header: "",
            id: 'all',
            width: 0,
            sortable: false,
            Filter: () => { },
            filterMethod: generateFilterMethod(keys),
            filterAll: true,
        }
    }
};
