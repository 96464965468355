import React from 'react';
import { connect } from 'react-redux';
import {
    Redirect,
    // HashRouter,
    Route,
    // Switch,
} from 'react-router-dom';

const debugMode = true;

const renderMergedProps = (component, ...rest) => {
    const finalProps = Object.assign({}, ...rest);
    return React.createElement(component, finalProps);
};

const PrivateRoute = ({ loggedIn, component, headerTitle, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            debugMode || loggedIn ? (
                renderMergedProps(component, props, rest)
            ) : (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

const mapStateToProps = (state, ownProps) => {
    return {
        loggedIn: state.cyderLoginReducer.loggedIn,
    };
};
const mapDispatchToProps = () => {
    return {};
};
// translate() is if we want to use HOC to perform t()
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PrivateRoute);
