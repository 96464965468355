import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import { I18nextProvider } from 'react-i18next';

import i18n from './configureLocale';
import App from './App';
import { PersistGate } from 'redux-persist/es/integration/react';

const { store, persistor } = configureStore();
//store.subscribe(() => console.log('state', store.getState()));

const Component = () => (
    <Provider store={store}>
        <PersistGate loading={<div>Loading</div>} onBeforeLift={() => {}} persistor={persistor}>
            <App persistor={persistor} />
        </PersistGate>
    </Provider>
);

let render = () => {
    ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <Component />
        </I18nextProvider>,
        document.getElementById('root'),
    );
};
render();
if (module.hot) {
    module.hot.accept(Component => {
        render();
    });
}
