import React from 'react'
import '../../css/elements/jumbotron-1.css'
import {connect} from 'react-redux'

const Jumbotron1 = ({jumbotronText, view, controller}) => (
    <div className="jumbotron jumbotron-1">
        <div className="container-fluid">
            <div className="row justify-content-between">
                <div className="col">
                    <h4 className="title">{view ? view : jumbotronText}</h4>
                </div>

            </div>
        </div>
    </div>
)

const mapStateToProps = (state, ownProps) => {
    return {
    }
}
const mapDispatchToProps = dispatch => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Jumbotron1)
