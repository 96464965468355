import {
    CYDER_EXPENSE_NEW_RECEIPT_MODAL_TOGGLE,
    CYDER_EXPENSE_NEW_RECEIPT_SAVE_REQUEST,
    CYDER_EXPENSE_NEW_RECEIPT_SAVE_SUCCESS,
    CYDER_EXPENSE_NEW_RECEIPT_SAVE_FAILURE,
    CYDER_EXPENSE_NEW_RECEIPT_CHANGE_FIELD,
    CYDER_EXPENSE_NEW_RECEIPT_SET_FIELDS,
    CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_REQUEST,
    CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_SUCCESS,
    CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_FAILURE,
    // CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_HISTORY_REQUEST,
    // CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_HISTORY_SUCCESS,
    // CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_HISTORY_FAILURE,
    CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_REQUEST,
    CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_SUCCESS,
    CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_FAILURE,
    CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_REQUEST,
    CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_SUCCESS,
    CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_FAILURE,
    CYDER_EXPENSE_NEW_RECEIPT_FORCE_LOADING_STATE,
} from '../constants/actionTypes';
import withQuery from 'with-query';
import cyderlib from '../../js/cyderlib';

import { CYDER_API_ROOT, DJARVIS_API_ROOT } from '../../config';

export function expenseNewReceiptClaimSaveReceipt(sdf) {
    // Redux Thunk will inject dispatch here:
    return (dispatch, getState) => {
        dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_SAVE_REQUEST });

        const url = withQuery(DJARVIS_API_ROOT + '/expense_v1/receipt', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: 'addreceipt',
        });
        const data = Object.assign({}, getState().cyderExpenseNewReceiptClaimReducer.receiptFields);
        // fix for select defaults
        const actualData = {
            ...sdf,
            id: data.id,
            amount: data.amount * 100,
            userid: data.userId,
            expensedate: data.expensedate,
            receiptno: data.receiptno,
            receiptId: data.receiptId,
            remarks: data.remarks,
            reimbursable: data.reimbursable === undefined ? 'Y' : data.reimbursable === true ? 'Y' : 'N',
            autoRecSave: true,
        };
        const options = {
            headers: {
                'x-api-key': getState().config.apiKey,
                'Content-Type': 'application/json',
                Authorization: getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify(actualData),
            method: 'POST',
        };

        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_SAVE_FAILURE, error });
                        throw error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_SAVE_SUCCESS, response });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_SAVE_FAILURE, error });
                return error;
            });
    };
}
export function expenseNewReceiptClaimChangeField(key, value) {
    return {
        type: CYDER_EXPENSE_NEW_RECEIPT_CHANGE_FIELD,
        key,
        value,
    };
}
export function expenseNewReceiptClaimToggleModal(modalAction) {
    return {
        type: CYDER_EXPENSE_NEW_RECEIPT_MODAL_TOGGLE,
        modalAction,
    };
}
export function expenseNewReceiptClaimForceLoadingState(loading) {
    return {
        type: CYDER_EXPENSE_NEW_RECEIPT_FORCE_LOADING_STATE,
        loading,
    };
}
export function expenseNewReceiptClaimResetFields() {
    return {
        type: CYDER_EXPENSE_NEW_RECEIPT_SET_FIELDS,
        resetFields: true,
    };
}
export function expenseNewReceiptClaimGetImage(receiptid) {
    return (dispatch, getState) => {
        const url = withQuery(DJARVIS_API_ROOT + '/expense_v1/receipt', {
            action: 'getattachments',
            appid: getState().cyderProfileReducer.profile.appId,
            id: receiptid,
        });
        const options = {
            headers: {
                'x-api-key': getState().config.apiKey,
                Authorization: getState().cyderProfileReducer.profile.authorizationToken,
            },
            method: 'POST',
        };
        dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_REQUEST });
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_FAILURE, response });
                        throw error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_SUCCESS, response });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_FAILURE, error });
                return error;
            });
    };
}
/*
export function expenseNewReceiptClaimGetHistory(instanceid) {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_HISTORY_REQUEST })
        const url = withQuery(CYDER_API_ROOT+'/common_v1/workflow', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: "gettaskhistory",
            resourceid: instanceid,
        });
        const options = {
            headers: {
                "x-api-key": getState().config.apiKeyCyder,
                "Content-Type": "application/json",
                "Authorization":  getState().cyderProfileReducer.profile.authorizationToken,
            },
            method: "POST",
        };

        return fetch(url, options).then((response) => {
            if (!response.ok) {
                return response.json().then((error) => {
                    dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_HISTORY_FAILURE, response })
                    throw error;
                })
            } else {
                return response.json().then((response) => {
                    dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_HISTORY_SUCCESS, response })
                    return response;
                })
            }
        }).catch((error) => {
            dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_HISTORY_FAILURE, error })
            return error;
        });
    }
}
*/
export function expenseNewReceiptClaimDownloadImage(url) {
    return (dispatch, getState) => {
        const options = {
            headers: {
                'x-api-key': getState().config.apiKey,
                Authorization: getState().cyderProfileReducer.profile.authorizationToken,
            },
            method: 'POST',
        };
        dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_REQUEST });
        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_FAILURE, response });
                        throw error;
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_SUCCESS, response });
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_IMAGE_FAILURE, error });
                return error;
            });
    };
}
export function expenseNewReceiptClaimSetFields(receiptid, index) {
    return (dispatch, getState) => {
        if (
            index !== -1 &&
            getState().cyderExpenseMyReceiptsReducer.receipts.length !== 0 &&
            getState().cyderExpenseMyReceiptsReducer.receipts[index] !== undefined
        ) {
            const receiptFields = getState().cyderExpenseMyReceiptsReducer.receipts[index];
            // normalize fields before dispatching
            dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_SET_FIELDS, receiptFields: cyderlib.normalizeReceiptFields(receiptFields) });
            return Promise.resolve(receiptFields);
        }

        // console.log('getting receipt details from api');
        dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_REQUEST });
        const url = withQuery(DJARVIS_API_ROOT + '/expense_v1/my', {
            appid: getState().cyderProfileReducer.profile.appId,
            id: receiptid,
            action: 'findreceiptbyid',
        });
        const options = {
            headers: {
                'x-api-key': getState().config.apiKey,
                'Content-Type': 'application/json',
                Authorization: getState().cyderProfileReducer.profile.authorizationToken,
            },
            method: 'POST',
        };

        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_FAILURE, response });
                        throw error;
                    });
                } else {
                    return response.json().then(response => {
                        return response;
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_FAILURE, error });
                return error;
            });
    };
}

export function expenseNewReceiptActualSetFields(response) {
    return (dispatch, getState) => {
        dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_SET_FIELDS, receiptFields: cyderlib.normalizeReceiptFields(response) });
        dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_GET_SAVED_RECEIPT_SUCCESS, response });
    };
}
export function expenseNewReceiptClaimDeleteSavedReceipt() {
    return (dispatch, getState) => {
        const url = withQuery(DJARVIS_API_ROOT + '/expense_v1/my', {
            appid: getState().cyderProfileReducer.profile.appId,
            action: 'deletereceipt',
        });
        const body = {
            receiptid: getState().cyderExpenseNewReceiptClaimReducer.receiptFields.receiptId,
            userid: getState().cyderProfileReducer.profile.username,
        };
        const options = {
            method: 'POST',
            headers: {
                'x-api-key': getState().config.apiKey,
                'Content-Type': 'application/json',
                Authorization: getState().cyderProfileReducer.profile.authorizationToken,
            },
            body: JSON.stringify(body),
        };

        dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_REQUEST });

        return fetch(url, options)
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => {
                        dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_FAILURE, error });
                    });
                } else {
                    return response.json().then(response => {
                        dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_SUCCESS, response });
                    });
                }
            })
            .catch(error => {
                dispatch({ type: CYDER_EXPENSE_NEW_RECEIPT_DELETE_SAVED_RECEIPT_FAILURE, error });
            });
    };
}
