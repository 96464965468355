import {
    PAGE_SET_JUMBOTRON_TEXT,
} from '../actions/constants/actionTypes' 

const defaultState = {
    jumbotronText: null,
}

export function pagedata(state = defaultState, action) {
    let newState = Object.assign({}, defaultState);
    switch (action.type) {
        case PAGE_SET_JUMBOTRON_TEXT:
            newState.jumbotronText = action.jumbotronText;
            return newState
        default:
            return state
    }
}
