import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
    Container,
    Row,
    Col,
    Button,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    CardText,
    ListGroup,
    ListGroupItem,
    Form,
    FormGroup,
    Label,
    Input,
} from "reactstrap";
import CardReactFormContainer from 'card-react';
import {
    translate,
    // Trans,
} from 'react-i18next';

import {
    cyderAccountsDetailGetAccountDetailsAction,
    cyderAccountsDetailChangeFieldAction,
    cyderAccountsDetailSaveChangesAction,
    cyderAccountsDetailApproveAction,
    cyderAccountsDetailRejectAction,
    cyderAccountsDetailDeleteAction,
    cyderAccountsDetailResetPasswordAction,
    cyderAccountsDetailArchiveAction,
    cyderAccountsDetailSuspendAction,
    cyderAccountsDetailActivateAction,
    cyderAccountsDetailModalToggleAction,
    cyderAccountsDetailChangeCCAction,
    cyderAccountsDetailRemoveCCAction,
} from '../../actions/accounts/cyderAccountsDetailAction'
import { setJumbotronTitle } from '../../actions/pagedata'
import LoadingSpinner from '../../cyderComponents/loadingSpinner/LoadingSpinner';
import DialogModal from '../../cyderComponents/modals/DialogModal';
import StoredLayout from '../../layouts/StoredLayout'
// import CyderDatePicker from '../../cyderComponents/forms/CyderDatePicker';

import history from '../../history'


class AccountsDetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.handleToggleModal = this.handleToggleModal.bind(this);
        this.toggleAction = this.toggleAction.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.state = {
            dropdownOpen: false,
        }
    }
    toggleAction() {
        this.handleToggleModal();
        if (this.props.modalAction === "refresh") {
            this.props.getAccountDetails(this.props.match.params.userid);
        } else if (this.props.modalAction === "changeccopen") {
            // TODO: might want to pre fill data from API if needed
        } else if (this.props.modalAction === "redirect") {
            history.push("/accounts");
        }
    }
    componentDidMount() {
        this.props.setJumbotronTitle();
        this.props.getAccountDetails(this.props.match.params.accountid);
    }
    calcCurrentPositiveButtonText() {
        if (this.props.modalAction === "update") {
            return "Update";
        } else if (this.props.modalAction === "approve") {
            return "Approve";
        } else if (this.props.modalAction === "reject") {
            return "Reject";
        } else if (this.props.modalAction === "delete") {
            return "Delete";
        } else if (this.props.modalAction === "resetpassword") {
            return "Reset";
        } else if (this.props.modalAction === "archive") {
            return "Archive";
        } else if (this.props.modalAction === "suspend") {
            return "Suspend";
        } else if (this.props.modalAction === "activate") {
            return "Activate";
        } else if (this.props.modalAction === "changeccopen") {
            return "Replace";
        } else if (this.props.modalAction === "changeccconfirm") {
            return "Replace";
        } else if (this.props.modalAction === "close") {
            return "Ok";
        } else {
            return "Ok"
        }
    }
    calcCurrentPositiveButtonAction() {
        if (this.props.modalAction === "update") {
            return this.props.handleSave;
        } else if (this.props.modalAction === "approve") {
            return this.props.handleApprove;
        } else if (this.props.modalAction === "reject") {
            return this.props.handleReject;
        } else if (this.props.modalAction === "delete") {
            return this.props.handleDelete;
        } else if (this.props.modalAction === "resetpassword") {
            return this.props.handleResetPassword;
        } else if (this.props.modalAction === "archive") {
            return this.props.handleArchive;
        } else if (this.props.modalAction === "suspend") {
            return this.props.handleSuspend;
        } else if (this.props.modalAction === "activate") {
            return this.props.handleActivate;
        } else if (this.props.modalAction === "changeccopen") {
            return () => this.handleToggleModal('changeccconfirm');
        } else if (this.props.modalAction === "changeccconfirm") {
            return this.props.handleChangeCC;
        } else if (this.props.modalAction === "removecc") {
            return this.props.handleRemoveCC;
        } else if (this.props.modalAction === "close") {
            return this.handleToggleModal;
        } else if (this.props.modalAction === "refresh") {
            return () => {
                this.handleToggleModal();
                this.props.getAccountDetails(this.props.match.params.accountid);
            };
        } else if (this.props.modalAction === "redirect") {
            return () => {
                this.handleToggleModal();
                history.push("/accounts");
            };
        } else {
            return this.handleToggleModal;
        }
    }
    handleToggleModal(modalAction) {
        const callback = () => {
            this.props.getAccountDetails(this.props.match.params.accountid);
        }
        console.log(modalAction);
        this.props.toggleModal(modalAction, callback);
    }
    toggleDropdown() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }
    render() {
        const ccNum = this.props.account.credit_card;
        const customModalBody =
            [
                <div id="card-wrapper" key={0}/>,
                <CardReactFormContainer
                    key={1}
                    container="card-wrapper"
                    formInputsNames={
                        {
                            number: 'CCnumber', // optional — default "number"
                            expiry: 'CCexpiry',// optional — default "expiry"
                            cvc: 'CCcvc', // optional — default "cvc"
                            name: 'CCname' // optional - default "name"
                        }
                    }
                    initialValues= {
                        {
                        }
                    }
                    classes={
                        {
                            valid: 'valid-input', // optional — default 'jp-card-valid'
                            invalid: 'invalid-input' // optional — default 'jp-card-invalid'
                        }
                    }
                    formatting={true}
                >
                    <div className="my-2">
                        <Form>
                            <FormGroup row>
                                <Col sm={12}>
                                    <Input
                                        placeholder="Full
                                        name"
                                        type="text"
                                        name="CCname"
                                        id="CCname"
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col className="pr-0" xs={7}>
                                    <Input
                                        placeholder="Card
                                        number"
                                        type="text"
                                        name="CCnumber"
                                        id="CCnumber"
                                    />
                                </Col>
                                <Col className="px-0" xs={3}>
                                    <Input
                                        placeholder="MM/YY"
                                        type="text"
                                        name="CCexpiry"
                                        id="CCexpiry"
                                    />
                                </Col>
                                <Col className="pl-0" xs={2}>
                                    <Input
                                        placeholder="CVC"
                                        type="text"
                                        name="CCcvc"
                                        id="CCcvc"
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </div>
                </CardReactFormContainer>
            ]
        const canBeUpdated = this.props.account.status !== "Pending" && this.props.account.status !== "Rejected";
        const canBeDeleted = this.props.account.status !== "Deleted";
        const isActiveAccount = this.props.account.status === "Active";

        const delButton =
            <Button
                className="float-right"
                color="danger"
                onClick={() => this.handleToggleModal("delete")}
                tabIndex="-1"
            >
                Delete
            </Button>
        const approveRejectButton =
            [<Button
                key="button-approve"
                className="mr-2"
                color="success"
                onClick={() => this.handleToggleModal("approve")}
                tabIndex="-1"
            >
                Approve
            </Button>,
            <Button
                key="button-reject"
                className="mr-2"
                color="danger"
                onClick={() => this.handleToggleModal("reject")}
                tabIndex="-1"
            >
                Reject
            </Button>
            ]
        const updateButton =  
            <Button
                className="mr-2"
                color="success"
                onClick={() => this.handleToggleModal('update')}
                tabIndex="-1"
            >
                Update
            </Button>
        const buttons =  
            <Row className="mb-2">
                <Col>
                    {this.props.account.status === "Pending" ? approveRejectButton : null}
                    {canBeUpdated ? updateButton : null}
                    {canBeDeleted ? delButton : null}
                </Col>
            </Row>
        return ([
            this.props.loading ? (
                <LoadingSpinner key={0}/>
            ) : (
                <Container key={0}>
                    <Row className="mb-2">
                        <Col>
                            <Link
                                to="/accounts"
                                tabIndex="-1"
                            >
                                Back To Search
                            </Link>
                        </Col>
                        <Col>
                            <ButtonDropdown
                                isOpen={this.state.dropdownOpen}
                                toggle={this.toggleDropdown}
                                className="float-right"
                            >
                                <DropdownToggle caret>
                                    Actions
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem onClick={() => this.handleToggleModal('resetpassword')}>
                                        Reset Customer Password
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem
                                        disabled={!isActiveAccount}
                                        onClick={() => this.handleToggleModal('archive')}
                                    >
                                        Archive Customer
                                    </DropdownItem>
                                    <DropdownItem 
                                        disabled={!isActiveAccount}
                                        onClick={() => this.handleToggleModal('suspend')}
                                    >
                                        Suspend Customer
                                    </DropdownItem>
                                    <DropdownItem 
                                        disabled={isActiveAccount}
                                        onClick={() => this.handleToggleModal('activate')}
                                    >
                                        Activate Customer
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Form>
                                <FormGroup row>
                                    <Label sm={3}>Company Name</Label>
                                    <Col sm={9}>
                                        <Input
                                            disabled={!canBeUpdated}
                                            value={this.props.account.companyname}
                                            id="companyname"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Status</Label>
                                    <Col sm={9}>
                                        <Input
                                            disabled
                                            value={this.props.account.status}
                                            id="status"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>First Name</Label>
                                    <Col sm={9}>
                                        <Input
                                            disabled={!canBeUpdated}
                                            value={this.props.account.firstname}
                                            id="firstname"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Last Name</Label>
                                    <Col sm={9}>
                                        <Input
                                            disabled={!canBeUpdated}
                                            value={this.props.account.lastname}
                                            id="lastname"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Email</Label>
                                    <Col sm={9}>
                                        <Input
                                            disabled={true}
                                            value={this.props.account.emailaddress}
                                            type="email"
                                            id="emailaddress"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Phone Number</Label>
                                    <Col sm={9}>
                                        <Input
                                            disabled={!canBeUpdated}
                                            value={this.props.account.phonenumber}
                                            id="phonenumber"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Address</Label>
                                    <Col sm={9}>
                                        <Input
                                            type="textarea"
                                            rows="4"
                                            disabled={!canBeUpdated}
                                            value={this.props.account.address}
                                            id="address"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Identification Type</Label>
                                    <Col sm={9}>
                                        <Input
                                            value={this.props.account.idtype}
                                            type="select"
                                            id="idtype"
                                            onChange={(event) => this.props.handleChange(event)}
                                        >
                                            <option value="NRIC">NRIC</option>
                                            <option value="FIN">FIN</option>
                                            <option value="Passport No.">Passport No.</option>
                                        </Input>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Identification No.</Label>
                                    <Col sm={9}>
                                        <Input
                                            value={this.props.account.idno}
                                            id="idno"
                                            onChange={(event) => this.props.handleChange(event)}
                                        />
                                    </Col>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle>Subscription Information</CardTitle>
                                    <CardText>
                                        {this.props.account.subscription_start} to {this.props.account.subscription_end}
                                    </CardText>

                                    {this.props.account.package ? this.props.account.package.map ? "This account has an array of packages. Please rectify this." : (
                                        <div>
                                            <CardTitle>Package Details
                                                <div className="float-right">
                                                    <Button size="sm" color="secondary">Change</Button>
                                                    <Button className="ml-1" size="sm" color="secondary">Renew</Button>
                                                </div>
                                            </CardTitle>
                                            <CardText>{this.props.account.package.system}</CardText>
                                            <CardText>{this.props.account.package.price.amount} {this.props.account.package.price.currency} per {this.props.account.package.price.user} user(s)</CardText>
                                            <CardText>{this.props.account.package.limit ? this.props.account.package.limit.maxuser + " user limit" : null}</CardText>
                                            <CardText>Modules:</CardText>
                                            <ListGroup>
                                                {this.props.account.package.modules.map((module, i) => {
                                                    return <ListGroupItem key={i}>{module}</ListGroupItem>
                                                })}
                                            </ListGroup>
                                        </div>
                                    ) : null
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <Card>
                                <CardBody>
                                    <CardTitle>Card Information</CardTitle>
                                    <CardText>
                                        <span className={ccNum ? "text-success" : "text-danger"}>
                                            <i className="align-text-bottom material-icons">{ccNum ? "check_circle" : "cancel"}</i>
                                        </span>
                                        {ccNum ? " Card ending in " + ccNum : " No credit card info"}
                                        <span className="float-right">
                                            {ccNum ? (
                                                [
                                                    <Button
                                                        key="replace"
                                                        size="sm"
                                                        color="secondary"
                                                        onClick={() => this.handleToggleModal('changeccopen')}
                                                    >
                                                        Replace
                                                    </Button>,
                                                    <Button
                                                        key="remove"
                                                        className="ml-1"
                                                        size="sm"
                                                        color="secondary"
                                                        onClick={() => this.handleToggleModal('removecc')}
                                                    >
                                                        Remove
                                                    </Button>
                                                ]
                                            ) : (
                                                <Button
                                                    key="add"
                                                    className="ml-1"
                                                    size="sm"
                                                    color="secondary"
                                                    onClick={() => this.handleToggleModal('addcc')}
                                                >
                                                    Add
                                                </Button>
                                            ) }
                                        </span>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mb-2 mt-2">
                        <Col>
                            {buttons}
                        </Col>
                    </Row>
                </Container>
            ),
            <DialogModal
                key="modal"
                modal={this.props.modalOpen}
                toggle={this.toggleAction}
                customHeader={this.props.customModalBody ? "Credit Card Info" : "Confirmation"}
                customModalBody={this.props.saving ? <LoadingSpinner/> : (
                    this.props.customModalBody ? customModalBody : this.props.modalMessage
                )}
                positiveButtonAction={this.calcCurrentPositiveButtonAction()}
                positiveButtonText={this.calcCurrentPositiveButtonText()}
                onlyOneButton={this.props.saved}
                buttonDisabler={this.props.saving}
            />
        ]
        )
    }
}


const mapStateToProps = (state) => {
    return {
        ...state.cyderAccountsDetailReducer,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getAccountDetails: (accountid) => {
            dispatch(cyderAccountsDetailGetAccountDetailsAction(accountid)
            ).then(
                (res) => {
                    // console.log(res)
                }
            );
        },
        handleChange: (event) => {
            const key = event.target.id;
            const value = event.target.value;
            dispatch(cyderAccountsDetailChangeFieldAction(key, value));
        },
        toggleModal: (modalAction, callback) => {
            dispatch(cyderAccountsDetailModalToggleAction(modalAction ? modalAction: null, callback));
        },
        handleApprove: () => dispatch(cyderAccountsDetailApproveAction()),
        handleReject:() => dispatch(cyderAccountsDetailRejectAction()),
        handleDelete: () => dispatch(cyderAccountsDetailDeleteAction()),
        handleResetPassword: () => dispatch(cyderAccountsDetailResetPasswordAction()),
        handleArchive: () => dispatch(cyderAccountsDetailArchiveAction()),
        handleSuspend: () => dispatch(cyderAccountsDetailSuspendAction()),
        handleActivate: () => dispatch(cyderAccountsDetailActivateAction()),
        handleChangeCC: () => dispatch(cyderAccountsDetailChangeCCAction()),
        handleRemoveCC: () => dispatch(cyderAccountsDetailRemoveCCAction()),
        handleSave: () => dispatch(cyderAccountsDetailSaveChangesAction()),
        setJumbotronTitle: () => dispatch(setJumbotronTitle('Account Detail')),
    }
}
// translate() is if we want to use HOC to perform t()
export default translate()(connect(mapStateToProps, mapDispatchToProps)(StoredLayout(AccountsDetailPage)))

