/**
 * Jumbrotron boot
 * 
 * Author: Fernando
 */

import {withRouter} from 'react-router'
import {connect} from 'react-redux'
import {compose, branch, renderComponent, renderNothing} from 'recompose'
import {startCase} from 'lodash'
import Jumbotron1 from './Jumbotron1'

const layouts = ['default-sidebar-1', 'collapsed-sidebar-1', 'top-navigation-1']

const Component = compose(
  withRouter,
  connect(state => {
    let pathname = state.router.location.pathname
    let parts =
      pathname && pathname.split('/').filter(str => str.trim().length > 0)
    let controller =
      parts && parts[0] ? startCase(parts[0]).replace(/Ui /g, 'UI ') : 'Welcome'
    let view = '';

    // Updated by Fernando
    // to auto-render the main panel window
    var navigations = state.navigation;
    navigations.forEach(function(firstLevelEntry) { 
      if (firstLevelEntry.url === pathname) {
        view = firstLevelEntry.title;
      } 
      var items = firstLevelEntry.items;
      if (items != null) {
        items.forEach(function(secondLevelEntry) {
          if (secondLevelEntry.url === pathname) {
            view = secondLevelEntry.title;
          }
          var secondLevelItems = secondLevelEntry.items;
          if (secondLevelItems != null) {
            secondLevelItems.forEach(function(thirdLevelEntry) { 
              if (thirdLevelEntry.url === pathname) {
                view = thirdLevelEntry.title;
              }
            })
          }

          // special case for Home
          if (secondLevelEntry.url === '/home') {
            view = null;
          }

        })
      }
    })

    return {
      layout: state.config.layout,
      controller: controller,
      view: view
    }
  }),
  branch(
    ({layout, controller}) => {
      if (
        controller === 'Dashboards' ||
        controller === 'Demos' ||
        controller === 'Layouts'
      ) {
        return false
      }
      if (layouts.includes(layout)) return true
      return false
    },
    renderComponent(Jumbotron1),
    renderNothing
  )
)(Jumbotron1)

export default Component
